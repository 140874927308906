import React, { useState, useEffect, useContext } from "react";
import {
  collection,
  onSnapshot,
  query,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { auth, firestore } from "../firebase";
import {
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Modal,
  Divider,
} from "@mui/material";
import PropertyContext from "../contexts/PropertyContext";

const OrderRequests = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [requests, setRequests] = useState([]);
  const [employees, setEmployees] = useState([]);
  const { selectedProperty } = useContext(PropertyContext);

  useEffect(() => {
    setIsLoading(true);
    const partsRequestsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "partsRequests"
    );
    const employeesRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "Staff"
    );

    const q = query(partsRequestsRef);
    const q3 = query(employeesRef);

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const requestsArray = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((request) => request.ordered === false);
      setRequests(requestsArray);
      console.log(requestsArray);

      const uns = onSnapshot(q3, (sna) => {
        const employeeList = sna.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEmployees(employeeList);
        setIsLoading(false);
      });
    });

    return unsubscribe; // Unsubscribe from the listener when the component unmounts
  }, []);

  const markAsCompleted = async (orderId, uuid) => {
    console.log(`marked as completed: `, orderId, uuid);

    const orderRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "workOrders",
      orderId
    );
    const userRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "Staff",
      uuid
    );
    //close out order
    const updatedWODetails = {
      completed: true,
    };
    await updateDoc(orderRef, updatedWODetails);

    //add +1 to user's work orders
    const currentWOs = Number(
      employees.find((member) => member.id === uuid).workOrders
    );
    const updatedUserProfileData = {
      turns: currentWOs + 1,
    };
    await updateDoc(userRef, updatedUserProfileData);
  };
  const newDateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const columns = [
    {
      field: "text",
      headerName: "item Needed",
      width: 70,
      editable: false,
      flex: 1,
    },
    {
      field: "requestedBy",
      headerName: "Request By",
      width: 130,
      editable: false,
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {employees.find((member) => member.id === params.value).name}
        </Typography>
      ),
    },
    {
      field: "createdAt",
      headerName: "Requested on",
      width: 130,
      editable: false,
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {new Date(params.value.seconds * 1000).toDateString()}
        </Typography>
      ),
    },
    {
      field: "completed",
      headerName: "Mark Complete",
      width: 130,
      editable: true,
      renderCell: (params) => (
        <Button
          onClick={() =>
            markAsCompleted(params.id, params.row.markedCompleteBy)
          }
        >
          Approve
        </Button>
      ),
    },
  ];

  return (
    <Box p={2}>
      {isLoading ? (
        <React.Fragment>
          <Typography>Im Loading!</Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {requests.length > 0 ? (
            <React.Fragment>
              <Typography>Pending Order Requests</Typography>
              <DataGrid
                rows={requests}
                // getRowId={(row) => row.roomId}
                columns={columns}
                // onCellEditCommit={}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography>No Pending Order Requests!</Typography>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};

export default OrderRequests;
