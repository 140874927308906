import jsPDF from "jspdf";
import "jspdf-autotable";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";
import { format } from "date-fns";

const loadImageAsBase64 = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/jpeg");
      resolve({ dataURL, width: img.width, height: img.height });
    };
    img.onerror = (err) => {
      reject(err);
    };
    img.src = url;
  });
};

const calculateImageDimensions = (imgWidth, imgHeight, maxWidth, maxHeight) => {
  let width = imgWidth;
  let height = imgHeight;

  if (width > maxWidth) {
    const scaleFactor = maxWidth / width;
    width *= scaleFactor;
    height *= scaleFactor;
  }

  if (height > maxHeight) {
    const scaleFactor = maxHeight / height;
    width *= scaleFactor;
    height *= scaleFactor;
  }

  return { width, height };
};

const svgToPngBase64 = (svg) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      resolve(dataURL);
    };
    img.onerror = (err) => {
      reject(err);
    };
    img.src = `data:image/svg+xml;base64,${window.btoa(svg)}`;
  });
};

const generatePropertyIssuesPDF = async (
  selectedProperty,
  setStatusMessage
) => {
  setStatusMessage("Initializing PDF...");
  const pdf = new jsPDF();
  const pageWidth = pdf.internal.pageSize.width;
  const pageHeight = pdf.internal.pageSize.height;
  const maxWidth = pageWidth / 4; // 1/4th the page width
  const maxHeight = pageHeight / 6; // Optional: set a max height
  const margin = 14;
  const bottomMargin = 20;
  const headerHeight = 40; // space reserved for the header

  let pageCount = 1;
  let currentColumn = 0;

  const addHeader = () => {
    pdf.setFontSize(18);
    pdf.text("Property Issues Report", margin, 22);
    pdf.setFontSize(12);
    const formattedDate = format(new Date(), "MMMM do, yyyy H:mm:ss a");
    pdf.text(`Generated on: ${formattedDate}`, margin, 30);
  };

  const addFooter = () => {
    pdf.setFontSize(10);
    pdf.text(`Page ${pageCount}`, pageWidth - 20, pageHeight - 10);
  };

  const addPage = () => {
    pdf.addPage();
    pageCount++;
    addHeader();
    addFooter();
  };

  const checkPageOverflow = (yOffset, itemHeight) => {
    if (yOffset + itemHeight > pageHeight - bottomMargin) {
      addPage();
      return headerHeight;
    }
    return yOffset;
  };

  addHeader();
  addFooter();

  const issuesRef = collection(
    firestore,
    "properties",
    selectedProperty,
    "propertyIssues"
  );

  setStatusMessage("Getting Property Issues...");

  const q = query(issuesRef, where("completed", "==", false));
  const querySnapshot = await getDocs(q);

  let yOffset = headerHeight;
  const rowHeight = maxHeight + 20; // height of each row (image height + some spacing)
  let currentIssue = 1;

  for (const doc of querySnapshot.docs) {
    setStatusMessage(
      `Processing issue: ${currentIssue}/ ${querySnapshot.docs.length}`
    );
    currentIssue++;
    const issue = doc.data();

    pdf.setFontSize(14);
    pdf.setFont(undefined, "bold");
    pdf.text(`Status: ${issue.status}`, margin, yOffset);

    const reportedDate = issue.submittedAt
      ? format(issue.submittedAt.toDate(), "MMMM do, yyyy H:mm:ss a")
      : "N/A";
    const textWidth = pdf.getTextWidth(`Reported Date: ${reportedDate}`);
    pdf.text(
      `Reported Date: ${reportedDate}`,
      pageWidth - textWidth - margin,
      yOffset
    );
    yOffset += 6;

    pdf.setFontSize(12);
    pdf.setFont(undefined, "normal");
    pdf.text("Reported To:", margin, yOffset);
    if (issue.reportTo && issue.reportTo.length !== 0) {
      issue.reportTo.forEach((person) => {
        pdf.text(`- ${person}`, margin, (yOffset += 6));
      });
    } else {
      pdf.text("- N/A", margin, (yOffset += 6));
    }

    yOffset += 10;
    pdf.text("Issue Description:", margin, yOffset);
    yOffset += 6;
    pdf.text(issue.issueDetails, margin, yOffset);
    yOffset += 10;

    if (issue.files && issue.files.length > 0) {
      currentColumn = 0; // Reset column before processing files

      for (const file of issue.files) {
        const columnOffset =
          (pageWidth - maxWidth * 3) / 4 +
          currentColumn * (maxWidth + (pageWidth - maxWidth * 3) / 4);
        yOffset = checkPageOverflow(yOffset, maxHeight);

        if (
          file.url.toLowerCase().includes(".jpeg") ||
          file.url.toLowerCase().includes(".jpg") ||
          file.url.toLowerCase().includes(".gif") ||
          file.url.toLowerCase().includes(".png")
        ) {
          try {
            const { dataURL, width, height } = await loadImageAsBase64(
              file.url
            );
            const { width: scaledWidth, height: scaledHeight } =
              calculateImageDimensions(width, height, maxWidth, maxHeight);
            pdf.addImage(
              dataURL,
              "JPEG",
              columnOffset,
              yOffset,
              scaledWidth,
              scaledHeight
            );
            pdf.link(columnOffset, yOffset, scaledWidth, scaledHeight, {
              url: file.url,
            });

            currentColumn++;
            if (currentColumn >= 3) {
              currentColumn = 0;
              yOffset += rowHeight;
            }
          } catch (error) {
            console.error("Error loading image: ", error);
          }
        } else if (file.url.toLowerCase().includes(".pdf")) {
          const svgImage =
            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 64C0 28.7 28.7 0 64 0L224 0l0 128c0 17.7 14.3 32 32 32l128 0 0 144-208 0c-35.3 0-64 28.7-64 64l0 144-48 0c-35.3 0-64-28.7-64-64L0 64zm384 64l-128 0L256 0 384 128zM176 352l32 0c30.9 0 56 25.1 56 56s-25.1 56-56 56l-16 0 0 32c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-48 0-80c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24l-16 0 0 48 16 0zm96-80l32 0c26.5 0 48 21.5 48 48l0 64c0 26.5-21.5 48-48 48l-32 0c-8.8 0-16-7.2-16-16l0-128c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16l0-64c0-8.8-7.2-16-16-16l-16 0 0 96 16 0zm80-112c0-8.8 7.2-16 16-16l48 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 32 32 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-32 0 0 48c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-64 0-64z"/></svg>';
          const pngImage = await svgToPngBase64(svgImage);
          pdf.addImage(pngImage, "PNG", columnOffset, yOffset, 16, 16);
          pdf.link(columnOffset, yOffset, 16, 16, { url: file.url });
          pdf.text(
            file.name,
            columnOffset + 8,
            yOffset + 26,
            null,
            null,
            "center"
          );

          currentColumn++;
          if (currentColumn >= 3) {
            currentColumn = 0;
            yOffset += rowHeight;
          }
        } else {
          console.log(file.url);
          console.log("could not find file type for file");
        }
      }

      yOffset += rowHeight; // Ensure space for the next section
    }

    yOffset = checkPageOverflow(yOffset, 40); // Adjust for the next section's start

    // Add a line separator after each issue
    pdf.setLineWidth(0.5);
    pdf.line(margin, yOffset, pageWidth - margin, yOffset);
    yOffset += 10;
  }

  setStatusMessage("Saving PDF...");

  pdf.save(
    `Property_Issues_Report_${format(new Date(), "MMMM_do_yyyy_H_mm_ss_a")}.pdf`
  );
};

export default generatePropertyIssuesPDF;
