//this component is for testing of items before they are added to the application in full

import React, { useState } from 'react';

function TestComponent() {
    const [rooms, setRooms] = useState([
        { room: 100, itemCountLamp: 1, itemCountLock: 0, difficulty: 1030 },
        { room: 150, itemCountLamp: 0, itemCountLock: 1, difficulty: 1000 },
        { room: 159, itemCountLamp: 0, itemCountLock: 1, itemCountLapTop: 0, difficulty: 1000 },
        { room: 157, itemCountLamp: 0, itemCountLock: 0, difficulty: 1100 },
        { room: 188, itemCountLamp: 0, itemCountLock: 0, difficulty: 99999 }
    ]);

    const [searchResult, setSearchResult] = useState([]);

    const searchRooms = (itemKey) => {
        const filteredAndSortedRooms = rooms
            .filter(room => room[`itemCount${itemKey.charAt(0).toUpperCase() + itemKey.slice(1)}`] === 0)
            .sort((a, b) => b.difficulty - a.difficulty);

        setSearchResult(filteredAndSortedRooms);
    };

    const handleSearch = (e) => {
        const itemKey = e.target.value; 
        searchRooms(itemKey);
    };

    return (
        <div>
            <input type="text" onChange={handleSearch} placeholder="Search for an item (e.g., Lock)"/>
            <ul>
                {searchResult.map(room => (
                    <li key={room.room}>Room: {room.room}, Difficulty: {room.difficulty}</li>
                ))}
            </ul>
        </div>
    );
}

export default TestComponent;