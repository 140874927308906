import React, { useContext, useEffect, useRef, useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { collection, doc, getDoc, onSnapshot, query } from "firebase/firestore";
import { firestore } from "../firebase";
import { Box, Button, Typography } from "@mui/material";
import PropertyContext from "../contexts/PropertyContext";

const PdfGeneratorPage = () => {
  //console.log("loaded pdf page")
  const name = "test";
  const [rooms, setRooms] = useState([]);
  const [turnableUnits, setTurnableUnits] = useState([]);
  const [notTurnableUnits, setNotTurnableUnits] = useState([]);
  const [roomsGraded, setRoomsGraded] = useState(false); //basically loading
  const [vendorsRequired, setVendorsRequired] = useState(["1", "2"]);
  const [easiestRooms, setEasiestRooms] = useState([
    { room: "test", difficulty: 1 },
  ]);
  const [roomsWithVendors, setRoomsWithVendors] = useState([]);
  const [items, setItems] = useState([]);
  const pdfRef = useRef();

  const { selectedProperty } = useContext(PropertyContext);

  //TODO ##########
  //refactor to db v2, remove unused IsNeeded collection
  //try to pull items from /items and check against keys in array of item objects

  const gradeRoom = async (room) => {
    let housekeepingNeeds = 0;
    let maintenanceNeeds = 0;
    const sanitizedRoomNeeds = [];
    //define base housekeepingNeeds
    switch (room.selectedHousekeepingRequirements) {
      case "Refresh":
        housekeepingNeeds = housekeepingNeeds + 15;
        break;
      case "Easy":
        housekeepingNeeds = housekeepingNeeds + 45;
        break;
      case "Medium":
        housekeepingNeeds = housekeepingNeeds + 90;
        break;
      case "Heavy":
        housekeepingNeeds = housekeepingNeeds + 120;
        break;
      case "Very Heavy":
        housekeepingNeeds = housekeepingNeeds + 180;
        break;
      case "Completed":
        housekeepingNeeds = 0;
        break;
      default:
        housekeepingNeeds = housekeepingNeeds + 1000;
        break;
    }
    //define base maintenance needs (minutes)
    switch (room.selectedMaintenanceRequirements) {
      case "Easy":
        maintenanceNeeds = maintenanceNeeds + 30;
        break;
      case "Medium":
        maintenanceNeeds = maintenanceNeeds + 60;
        break;
      case "Heavy":
        maintenanceNeeds = maintenanceNeeds + 90;
        break;
      case "Very Heavy":
        maintenanceNeeds = maintenanceNeeds + 120;
        break;
      case "Completed":
        break;

      default:
        maintenanceNeeds = maintenanceNeeds + 1000;
        break;
    }

    Object.keys(room).forEach((roomKey) => {
      // Find the matching item in the items array
      const item = items.find((i) => i.itemID === roomKey);

      if (item && room[roomKey] > 0) {
        console.log(item);
        // Perform your operations here
        if (item.itemResponsibility === "Maintenance") {
          maintenanceNeeds += parseInt(item.itemDifficulty, 10); // Example operation
        } else if (item.itemResponsibility === "Housekeeping") {
          housekeepingNeeds += parseInt(item.itemDifficulty, 10);
        } else {
          console.log(
            `UNKNOWN ITEM RESPONSIBILITY FOUND: ${item.itemResponsibility}`
          );
        }

        sanitizedRoomNeeds.push({ item: item.itemName, count: room[roomKey] });
      }
    });

    const roomBreakdown = {
      room: room.displayName,
      difficulty: housekeepingNeeds + maintenanceNeeds,
      sanitizedRoomNeeds,
      housekeepingNeeds: housekeepingNeeds,
      maintenanceNeeds: maintenanceNeeds,
    };
    return roomBreakdown;

    //console.log(room)
    // const isNeededRef = doc(
    //   firestore,
    //   "properties",
    //   selectedProperty,
    //   "units",
    //   room.displayName,
    //   "isNeeded",
    //   "isNeeded"
    // );
    // const isNeededDoc = await getDoc(isNeededRef);
    // if (isNeededDoc.exists()) {
    //   const data = isNeededDoc.data();
    //   for (const key in data) {
    //     if (Object.prototype.hasOwnProperty.call(data, key)) {
    //       const value = data[key];
    //       if (value > 0) {
    //         //console.log(`${key} has a value of ${value}`)
    //         switch (key) {
    //           case "itemCountBathroomDoor":
    //             maintenanceNeeds = maintenanceNeeds + 60;
    //             sanitizedRoomNeeds.push({
    //               item: "bathroom door",
    //               count: value,
    //             });
    //             break;
    //           case "itemCountBedFrame":
    //             maintenanceNeeds = maintenanceNeeds + 45;
    //             sanitizedRoomNeeds.push({ item: "bed frame", count: value });
    //             break;
    //           case "itemCountBlinds":
    //             maintenanceNeeds = maintenanceNeeds + 20;
    //             sanitizedRoomNeeds.push({ item: "blinds", count: value });
    //             break;
    //           case "itemCountClosetRod":
    //             maintenanceNeeds = maintenanceNeeds + 30;
    //             sanitizedRoomNeeds.push({ item: "closet rod", count: value });
    //             break;
    //           case "itemCountDresser":
    //             maintenanceNeeds = maintenanceNeeds + 15;
    //             sanitizedRoomNeeds.push({ item: "dresser", count: value });
    //             break;
    //           case "itemCountDrywall":
    //             maintenanceNeeds = maintenanceNeeds + 60;
    //             sanitizedRoomNeeds.push({ item: "drywall", count: value });
    //             break;
    //           case "itemCountFrontDoor":
    //             maintenanceNeeds = maintenanceNeeds + 60;
    //             sanitizedRoomNeeds.push({ item: "front door", count: value });
    //             break;
    //           case "itemCountKitchenCabinet":
    //             maintenanceNeeds = maintenanceNeeds + 120;
    //             sanitizedRoomNeeds.push({
    //               item: "kitchen cabinet",
    //               count: value,
    //             });
    //             break;
    //           case "itemCountKitchenChair":
    //             maintenanceNeeds = maintenanceNeeds + 10;
    //             sanitizedRoomNeeds.push({ item: "chair", count: value });
    //             break;
    //           case "itemCountKitchenTable":
    //             maintenanceNeeds = maintenanceNeeds + 15;
    //             sanitizedRoomNeeds.push({ item: "table", count: value });
    //             break;
    //           case "itemCountLamp":
    //             maintenanceNeeds = maintenanceNeeds + 10;
    //             sanitizedRoomNeeds.push({ item: "lamp", count: value });
    //             break;
    //           case "itemCountLightBulb":
    //             maintenanceNeeds = maintenanceNeeds + 5;
    //             sanitizedRoomNeeds.push({ item: "light bulb", count: value });
    //             break;
    //           case "itemCountLock":
    //             maintenanceNeeds = maintenanceNeeds + 20;
    //             sanitizedRoomNeeds.push({ item: "lock", count: value });
    //             break;
    //           case "itemCountLoveseat":
    //             maintenanceNeeds = maintenanceNeeds + 30;
    //             sanitizedRoomNeeds.push({ item: "loveseat", count: value });
    //             break;
    //           case "itemCountMattress":
    //             maintenanceNeeds = maintenanceNeeds + 20;
    //             sanitizedRoomNeeds.push({ item: "mattress", count: value });
    //             break;
    //           case "itemCountMattressCover":
    //             housekeepingNeeds = housekeepingNeeds + 10;
    //             sanitizedRoomNeeds.push({
    //               item: "mattress cover",
    //               count: value,
    //             });
    //             break;
    //           case "itemCountMedicineCabinet":
    //             maintenanceNeeds = maintenanceNeeds + 30;
    //             sanitizedRoomNeeds.push({
    //               item: "medicine cabinet",
    //               count: value,
    //             });
    //             break;
    //           case "itemCountMicrowave":
    //             maintenanceNeeds = maintenanceNeeds + 45;
    //             sanitizedRoomNeeds.push({ item: "microwave", count: value });
    //             break;
    //           case "itemCountMoen":
    //             maintenanceNeeds = maintenanceNeeds + 60;
    //             sanitizedRoomNeeds.push({ item: "moen", count: value });
    //             break;
    //           case "itemCountNightStand":
    //             maintenanceNeeds = maintenanceNeeds + 10;
    //             sanitizedRoomNeeds.push({ item: "night stand", count: value });
    //             break;
    //           case "itemCountPTAC":
    //             maintenanceNeeds = maintenanceNeeds + 30;
    //             sanitizedRoomNeeds.push({ item: "AC unit", count: value });
    //             break;
    //           case "itemCountPTRAP":
    //             maintenanceNeeds = maintenanceNeeds + 20;
    //             sanitizedRoomNeeds.push({ item: "PTRAP", count: value });
    //             break;
    //           case "itemCountPaint":
    //             maintenanceNeeds = maintenanceNeeds + 45 * value;
    //             sanitizedRoomNeeds.push({ item: "paint", count: value });
    //             break;
    //           case "itemCountPuffLight":
    //             maintenanceNeeds = maintenanceNeeds + 15;
    //             sanitizedRoomNeeds.push({ item: "puff light", count: value });
    //             break;
    //           case "itemCountRefridgerator":
    //             maintenanceNeeds = maintenanceNeeds + 20;
    //             sanitizedRoomNeeds.push({
    //               item: "refridgerator",
    //               count: value,
    //             });
    //             break;
    //           case "itemCountSmokeDetector":
    //             maintenanceNeeds = maintenanceNeeds + 10;
    //             sanitizedRoomNeeds.push({
    //               item: "smoke detector",
    //               count: value,
    //             });
    //             break;
    //           case "itemCountStovetop":
    //             maintenanceNeeds = maintenanceNeeds + 7;
    //             sanitizedRoomNeeds.push({ item: "stovetop", count: value });
    //             break;
    //           case "itemCountTVMount":
    //             maintenanceNeeds = maintenanceNeeds + 15;
    //             sanitizedRoomNeeds.push({ item: "TV mount", count: value });
    //             break;
    //           case "itemCountTVRemote":
    //             maintenanceNeeds = maintenanceNeeds + 5;
    //             sanitizedRoomNeeds.push({ item: "TV Remote", count: value });
    //             break;
    //           case "itemCountTVs":
    //             maintenanceNeeds = maintenanceNeeds + 20;
    //             sanitizedRoomNeeds.push({ item: "TVs", count: value });
    //             break;
    //           case "itemCountToiletPaperHolder":
    //             maintenanceNeeds = maintenanceNeeds + 20;
    //             sanitizedRoomNeeds.push({ item: "tp holder", count: value });
    //             break;
    //           case "itemCountTowelRack":
    //             maintenanceNeeds = maintenanceNeeds + 60;
    //             sanitizedRoomNeeds.push({ item: "table", count: value });
    //             break;
    //           case "itemCountVanityCombo":
    //             maintenanceNeeds = maintenanceNeeds + 120;
    //             sanitizedRoomNeeds.push({ item: "vanity combo", count: value });
    //             break;
    //           case "itemCountVanityLightBar":
    //             maintenanceNeeds = maintenanceNeeds + 30;
    //             sanitizedRoomNeeds.push({
    //               item: "vanity light bar",
    //               count: value,
    //             });
    //             break;
    //           case "init":
    //             //sanity
    //             break;
    //           default:
    //             maintenanceNeeds = maintenanceNeeds + 60;
    //             console.log(
    //               `NO TIME FOUND FOR KEY ${key} SET DEFAULT OF 1 HOUR`
    //             );
    //             break;
    //         }
    //       }
    //     }
    //   }
    //   const roomBreakdown = {
    //     room: room.displayName,
    //     difficulty: housekeepingNeeds + maintenanceNeeds,
    //     sanitizedRoomNeeds,
    //     housekeepingNeeds: housekeepingNeeds,
    //     maintenanceNeeds: maintenanceNeeds,
    //   };
    //   return roomBreakdown;

    //   // console.log(roomBreakdown)
    // } else {
    //   console.log(`${room.displayName} has not had its needed items added yet`);
    //   const dummydata = {
    //     room: room.displayName,
    //     difficulty: 10000000,
    //   };
    //   return dummydata;
    // }
  };

  const gradeRooms = async (roomsarray) => {
    setRoomsGraded(false);

    const promises = roomsarray.map((room) => gradeRoom(room));
    const gradedRoomList = await Promise.all(promises);

    setRoomsGraded(false);

    //sort rooms based on difficulty
    gradedRoomList.sort((a, b) => a.difficulty - b.difficulty);

    if (gradedRoomList.length > 5) {
      //   setEasiestRooms(gradedRoomList.slice(0, 5)); //return the five easiest rooms
      setEasiestRooms(gradedRoomList);
      console.log(gradedRoomList);
    } else {
      setEasiestRooms(gradedRoomList);
    }
    console.log(gradedRoomList);
    setRoomsGraded(true);
  };

  const roomsTurnable = (roomsArray) => {
    const turnableRooms = [];
    const vendorNeededRooms = [];
    const vendorRequired = [];
    const vendorSanitized = [];
    const vendorNeededRoomsFull = [];
    roomsArray.forEach((room) => {
      const keysToCheck = [
        "vendorElectricalRepair",
        "vendorPestControl",
        "vendorPlumbingRepair",
        "vendorTubResurface",
        "vendorWindow",
      ];

      const isTurnable = keysToCheck.every(
        (key) => !room[key] || room[key] <= 0
      );

      if (isTurnable) {
        turnableRooms.push(room);
      } else {
        vendorNeededRooms.push(room);
        keysToCheck.forEach((key) => {
          if (room[key] > 0) {
            switch (key) {
              case "vendorElectricalRepair":
                vendorNeededRoomsFull.push({
                  vendor: "Electrician",
                  room: room.displayName,
                });
                break;
              case "vendorPestControl":
                vendorNeededRoomsFull.push({
                  vendor: "Pest Control",
                  room: room.displayName,
                });
                break;
              case "vendorPlumbingRepair":
                vendorNeededRoomsFull.push({
                  vendor: "Plumber",
                  room: room.displayName,
                });
                break;
              case "vendorTubResurface":
                vendorNeededRoomsFull.push({
                  vendor: "Tub Resurface",
                  room: room.displayName,
                });
                break;
              case "vendorWindow":
                vendorNeededRoomsFull.push({
                  vendor: "Window",
                  room: room.displayName,
                });

                break;
              default:
                console.log("vendor notfound: ", key);
                break;
            }
          }
          if (room[key] > 0 && !vendorRequired.includes(key)) {
            vendorRequired.push(key);
            switch (key) {
              case "vendorElectricalRepair":
                vendorSanitized.push("Electrician");
                break;
              case "vendorPestControl":
                vendorSanitized.push("Pest Control");
                break;
              case "vendorPlumbingRepair":
                vendorSanitized.push("Plumber");
                break;
              case "vendorTubResurface":
                vendorSanitized.push("Tub Resurface");
                break;
              case "vendorWindow":
                vendorSanitized.push("Window");
                break;
              default:
                console.log("vendor notfound: ", key);
                break;
            }
          }
        });
      }
    });
    setTurnableUnits(turnableRooms);
    setNotTurnableUnits(vendorNeededRooms);
    setVendorsRequired(vendorSanitized);
    setRoomsWithVendors(vendorNeededRoomsFull);
    gradeRooms(turnableRooms);
  };

  useEffect(() => {
    console.log("loaded");
    const roomsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "units"
    );
    const q = query(roomsRef);

    const itemsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "items"
    );
    const q2 = query(itemsRef);

    const unsubscribeFromRooms = onSnapshot(
      q,
      (snapshot) => {
        const roomsArray = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((room) => room.status === "Down");
        setRooms(roomsArray);
      },
      (error) => {
        console.log(error);
        return alert("oops, there was an error");
      }
    );

    const unsubscribeFromItems = onSnapshot(
      q2,
      (snapshot) => {
        const itemsArray = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(itemsArray);
        setItems(itemsArray);
      },
      (error) => {
        console.log(error);
        return alert("oops, there was an error");
      }
    );

    return () => {
      unsubscribeFromRooms();
      unsubscribeFromItems();
    };
  }, []);

  useEffect(() => {
    // Check if both rooms and items are available
    if (rooms.length > 0 && items.length > 0) {
      console.log("grading rooms");
      roomsTurnable(rooms);
    }
  }, [rooms, items]); // Dependencies are rooms and items

  const printDocument = async () => {
    //working snapshot version
    // const input = document.getElementById('divToPrint');
    // html2canvas(input)
    //     .then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const pdf = new jsPDF(); //check vars
    //         pdf.addImage(imgData, 'PNG', 0, 0);
    //         pdf.output('dataurlnewwindow'); // Open PDF in new window (for testing)
    //         pdf.save("download.pdf");
    //     });

    // //working html version (adds empy page at end)
    const doc = new jsPDF();
    // Assuming pdfRef.current contains the HTML you want to convert
    const originalTransform = pdfRef.current.style.transform;
    const scale = 0.3; // Adjust as needed
    pdfRef.current.style.transform = `scale(${scale})`;

    await doc.html(pdfRef.current, {
      callback: function (doc) {
        doc.save("generated-document.pdf");
        // doc.output('dataurlnewwindow'); // Open PDF in new window (for testing)
        pdfRef.current.style.transform = originalTransform;
      },
      x: -215, // this controls horizontal alignment
      y: -315, // this controls the vertical alignment of the content
      windowWidth: pdfRef.current.scrollWidth,
    });

    // const doc = new jsPDF();
    // const scale = 0.3; // Adjust as needed

    // const originalTransform = pdfRef.current.style.transform;
    // pdfRef.current.style.transform = `scale(${scale})`;

    // await doc.html(pdfRef.current, {
    //     callback: function (doc) {
    //         pdfRef.current.style.transform = originalTransform;
    //         doc.save('generated-document.pdf');
    //     },
    //     x: 10, // Adjust as needed
    //     y: 10, // Adjust as needed
    //     windowWidth: pdfRef.current.scrollWidth / scale
    // });

    // Save the PDF
    // doc.save('generated-document.pdf');
  };

  function convertMinutesToTimeString(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}:${mins.toString().padStart(2, "0")}`;
  }

  return (
    <div>
      {easiestRooms.map((room) => (
        <div>
          {room.room} : {room.difficulty} :{" "}
          {convertMinutesToTimeString(room.housekeepingNeeds)} :{" "}
          {convertMinutesToTimeString(room.maintenanceNeeds)} :{" "}
          {room.sanitizedRoomNeeds.map((item) => item.item).join(", ")}
        </div>
      ))}
    </div>
  );

  //   return (
  //     <div>
  //       <div
  //         ref={pdfRef}
  //         id="divToPrint"
  //         style={{
  //           display: "flex",
  //           flexDirection: "column",
  //           height: "100%",
  //           color: "black",
  //           width: "210mm",
  //           backgroundColor: "WHITE",
  //         }}
  //       >
  //         {/* Content you want to print as PDF */}
  //         {/* <Box padding="10px"> */}
  //         <Box
  //           width="100%"
  //           display="flex"
  //           flexDirection="row"
  //           justifyContent="space-between"
  //         >
  //           <Box>{/* place logo here */}</Box>
  //           <Box>
  //             <Typography>Room data for {selectedProperty}</Typography>
  //           </Box>
  //           <Box>
  //             <Box
  //               alignContent="center"
  //               border="1px solid black"
  //               justifyContent="center"
  //               alignItems="center"
  //             >
  //               <Typography>Report By</Typography>
  //               <Typography>{name}</Typography>
  //             </Box>
  //             <Box
  //               alignContent="center"
  //               border="1px solid black"
  //               justifyContent="center"
  //               alignItems="center"
  //             >
  //               <Typography>Date</Typography>
  //               <Typography>
  //                 {new Date(Date.now()).toLocaleString().split(",")[0]}
  //               </Typography>
  //             </Box>
  //           </Box>
  //         </Box>
  //         <Box>
  //           {/* body  */}
  //           <Box
  //             component="section"
  //             sx={{
  //               display: "flex",
  //               width: "100%",
  //               border: "1px solid black",
  //               flexDirection: "row",
  //               justifyContent: "space-between",
  //             }}
  //           >
  //             <Box
  //               component="section"
  //               sx={{
  //                 display: "flex",
  //                 border: "1px solid black",
  //                 flexDirection: "column",
  //                 justifyContent: "center",
  //                 alignItems: "center",
  //                 flexGrow: 1,
  //               }}
  //             >
  //               <Typography>Down Rooms</Typography>
  //               <Typography>{rooms.length}</Typography>
  //             </Box>
  //             <Box
  //               component="section"
  //               sx={{
  //                 display: "flex",
  //                 border: "1px solid black",
  //                 flexDirection: "column",
  //                 justifyContent: "center",
  //                 alignItems: "center",
  //                 flexGrow: 1,
  //               }}
  //             >
  //               <Typography>turnable/vendor required</Typography>
  //               <Typography>
  //                 {turnableUnits.length}/{notTurnableUnits.length}
  //               </Typography>
  //             </Box>
  //             <Box
  //               component="section"
  //               sx={{
  //                 display: "flex",
  //                 border: "1px solid black",
  //                 flexDirection: "column",
  //                 justifyContent: "center",
  //                 alignItems: "center",
  //                 flexGrow: 1,
  //                 maxWidth: "50%",
  //               }}
  //             >
  //               <Typography>Vendors Required</Typography>
  //               <Typography>{vendorsRequired.join(", ")}</Typography>
  //             </Box>
  //           </Box>
  //         </Box>
  //         <Box>
  //           <h2>Next Five Easiest Turnable Rooms</h2>
  //           <Box>
  //             {!roomsGraded ? (
  //               <Typography>grading rooms, please wait</Typography>
  //             ) : (
  //               <React.Fragment>
  //                 <Box
  //                   sx={{
  //                     display: "flex",
  //                     border: "1px solid black",
  //                     flexDirection: "row",
  //                     justifyContent: "center",
  //                     alignItems: "center",
  //                     flexGrow: 1,
  //                     justifyContent: "space-between",
  //                   }}
  //                 >
  //                   <Box width="10%">
  //                     {/* room */}
  //                     <Typography>Room</Typography>
  //                   </Box>
  //                   <Box width="15%">
  //                     {/* hk */}
  //                     <Typography>HK hrs</Typography>
  //                   </Box>
  //                   <Box width="15%">
  //                     {/* maint */}
  //                     <Typography>MT hrs</Typography>
  //                   </Box>
  //                   <Box width="60%">
  //                     {/* needs */}
  //                     <Typography>Items Required to Turn</Typography>
  //                   </Box>
  //                 </Box>
  //                 {easiestRooms.map((room, index) => (
  //                   <Box
  //                     sx={{
  //                       display: "flex",
  //                       border: "1px solid black",
  //                       flexDirection: "row",
  //                       justifyContent: "center",
  //                       alignItems: "center",
  //                       flexGrow: 1,
  //                       justifyContent: "space-between",
  //                     }}
  //                   >
  //                     <Box width="10%">
  //                       {/* room */}
  //                       <Typography>{room.room}</Typography>
  //                     </Box>
  //                     <Box width="15%">
  //                       {/* hk */}
  //                       <Typography>
  //                         {(room.housekeepingNeeds / 60).toFixed(2)} hrs
  //                       </Typography>
  //                     </Box>
  //                     <Box width="15%">
  //                       {/* maint */}
  //                       <Typography>
  //                         {(room.maintenanceNeeds / 60).toFixed(2)} hrs
  //                       </Typography>
  //                     </Box>
  //                     <Box width="60%">
  //                       {/* needs */}
  //                       <Typography>
  //                         {room.sanitizedRoomNeeds
  //                           .map((item) => (item.count, " ", item.item))
  //                           .join(", ")}
  //                       </Typography>
  //                     </Box>
  //                   </Box>
  //                 ))}
  //               </React.Fragment>
  //             )}
  //           </Box>
  //         </Box>
  //         <Box>
  //           <Typography>Rooms Down for vendor</Typography>
  //           <Box
  //             display="flex"
  //             flexDirection="row"
  //             flexGrow="1"
  //             justifyContent="space-between"
  //           >
  //             {vendorsRequired.map((vendor) => (
  //               <Box key={vendor} width={`${100 / vendorsRequired.length}%`}>
  //                 <Typography>{vendor}</Typography>
  //                 {/* {roomsWithVendors.filter(vr => vr.vendor === vendor).map((vr, index) => (
  //                                         <Typography key={index}>{vr.room}</Typography>
  //                                     ))} */}
  //                 <Typography padding="2px">
  //                   {roomsWithVendors
  //                     .filter((vr) => vr.vendor === vendor)
  //                     .map((vr) => vr.room)
  //                     .join(", ")}
  //                 </Typography>
  //               </Box>
  //             ))}
  //           </Box>
  //         </Box>

  //         {/* PDF ENDS HERE */}
  //         {/* </Box> */}
  //         {/* {rooms.map((room) => {
  //                     return(
  //                         <p>{room.displayName}</p>
  //                     )
  //                 })} */}
  //         {/* <p>There are {rooms.length} down rooms</p> */}
  //         {/* <p>{Date.now().toLocaleString()}</p> */}
  //         {/* More content */}
  //       </div>
  //       <button onClick={printDocument}>Generate PDF</button>
  //     </div>
  //   );
};

export default PdfGeneratorPage;
