import React, { useState, useEffect, useContext } from "react";
import { Tabs, Tab, Box, Typography, CircularProgress } from "@mui/material";
import { onSnapshot, collection } from "firebase/firestore";
import { firestore } from "../firebase";
import PropertyContext from "../contexts/PropertyContext";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const DispatchAssistant = () => {
  const [value, setValue] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingDifficulty, setLoadingDifficulty] = useState(true);
  const [roomsByDifficulty, setRoomsByDifficulty] = useState([]);
  const { selectedProperty, rooms, items } = useContext(PropertyContext);
  //   rooms.map((room) => console.log(room));
  //   items.map((item) => console.log(item));

  useEffect(() => {
    setLoading(true);
    const fetchEmployees = async () => {
      const employeesRef = collection(
        firestore,
        "properties",
        selectedProperty,
        "employees"
      );
      const unsubscribe = onSnapshot(employeesRef, (snapshot) => {
        const employees = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEmployees(employees);
      });
    };
    fetchEmployees();
  }, [selectedProperty]);

  useEffect(() => {
    setLoading(true);
    const today = new Date();
    const dayIndex = value === 0 ? today.getDay() : (today.getDay() + 1) % 7;
    const dayName = daysOfWeek[dayIndex];

    const filtered = employees.filter(
      (employee) => employee.schedule && employee.schedule[dayName]
    );
    console.log(filtered);
    setFilteredEmployees(filtered);
    setLoading(false);
  }, [value, employees]);

  useEffect(() => {
    function calculateDifficulties(rooms, items) {
      // Convert the items array into a dictionary for quick lookup by itemID
      const itemsDict = {};
      items.forEach((item) => {
        itemsDict[item.itemID] = item;
      });

      //   console.log(itemsDict);
      //
      // Function to calculate the difficulty for a room
      function calculateRoomDifficulty(room) {
        let housekeepingDifficulty = 0;
        let maintenanceDifficulty = 0;

        // console.log("Calculating Room Difficulty for: ", room.displayName);

        // Iterate through each key in the room object
        Object.keys(room).forEach((key) => {
          // Check if the key starts with 'itemCount' and has a non-zero value
          if (key.startsWith("itemCount") && room[key] > 0) {
            const itemID = key; //key.replace("itemCount", "itemCount").toLowerCase(); // Ensure consistency in casing
            const item = itemsDict[itemID];
            if (item) {
              //   console.log("Found Item: ", item);
              const difficulty = parseInt(item.itemDifficulty, 10);
              //   console.log(
              //     `room: ${room.displayName}, item: ${item.itemName}, difficulty: ${difficulty}, count: ${room[key]}`
              //   );
              if (item.itemResponsibility === "Housekeeping") {
                housekeepingDifficulty += difficulty * room[key];
              } else if (item.itemResponsibility === "Maintenance") {
                maintenanceDifficulty += difficulty * room[key];
              }
            }
          }
        });

        switch (room.selectedHousekeepingRequirements) {
          case "Easy":
            housekeepingDifficulty += 15;
            break;
          case "Medium":
            housekeepingDifficulty += 45;
            break;
          case "Heavy":
            housekeepingDifficulty += 75;
            break;
          default:
            break;
        }
        switch (room.selectedMaintenanceRequirements) {
          case "Easy":
            maintenanceDifficulty += 15;
            break;
          case "Medium":
            maintenanceDifficulty += 45;
            break;
          case "Heavy":
            maintenanceDifficulty += 75;
            break;
          default:
            break;
        }

        // Add the calculated difficulties to the room object
        room.HousekeepingDifficulty = housekeepingDifficulty;
        room.MaintenanceDifficulty = maintenanceDifficulty;

        if (30 > maintenanceDifficulty) {
          console.log(`check unit: ${room.displayName} for maintenance`);
          console.log(`maintenance difficulty: ${maintenanceDifficulty}`);
          console.log(room);
        }
      }

      // Iterate through each room and calculate its difficulties
      rooms.forEach((room) => {
        calculateRoomDifficulty(room);
      });

      return rooms;
    }
    // create array of all down rooms
    const downRooms = rooms.filter((room) => room.status === "Down");
    const calculatedRooms = calculateDifficulties(downRooms, items);
    setRoomsByDifficulty(calculatedRooms);
    setLoadingDifficulty(false);
    console.log(calculatedRooms);
  }, [rooms, items]);

  const handleChange = (event, newValue) => {
    setLoading(true);
    console.log(newValue);
    setValue(newValue);
  };
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    const period = +hour >= 12 ? "PM" : "AM";
    const formattedHour = +hour % 12 || 12;
    return `${formattedHour}:${minute} ${period}`;
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }
  if (loadingDifficulty) {
    return (
      <Box>
        <Typography>Calculating Room Difficulty...</Typography>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Today" />
          <Tab label="Tomorrow" />
        </Tabs>
      </Box>
      <Box mt={2} display="flex" justifyContent="center" alignItems="center">
        <Box width="80%">
          <Typography variant="h5" align="center">
            Employee Schedule
          </Typography>
          {filteredEmployees.map((employee) => (
            <Box
              key={employee.id}
              display="flex"
              justifyContent="space-between"
              my={1}
            >
              <Typography variant="body1">{employee.name}</Typography>
              <Typography variant="body2">
                {formatTime(
                  employee.schedule[
                    daysOfWeek[(new Date().getDay() + value) % 7]
                  ].startTime
                )}{" "}
                -
                {formatTime(
                  employee.schedule[
                    daysOfWeek[(new Date().getDay() + value) % 7]
                  ].endTime
                )}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DispatchAssistant;
