import {
  Button,
  MenuItem,
  Select,
  Grid,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { firestore } from "../firebase";
import PropertyContext from "../contexts/PropertyContext";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageUpload from "./ImageUpload";

function RoomsSingle({
  singleRoom,
  setSingleRoom,
  itemsByCategory,
  handleEdit,
  handleOpenModal,
  statusOptions,
  hKOptions,
  mTOptions,
  uniqueCategories,
  setSingleRoomTimeline,
}) {
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const { selectedProperty } = useContext(PropertyContext);

  const renderCategoryButton = (category, itemsByCategory) => {
    const needsCategory =
      itemsByCategory[category] &&
      singleRoom &&
      itemsByCategory[category].some(
        (item) => parseInt(singleRoom[item.itemID]) > 0
      );

    return (
      <>
        <Grid item xs={3}>
          <Typography>{category}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            sx={{ width: "95%" }}
            variant="contained"
            color={needsCategory ? "error" : "success"}
            onClick={() => handleOpenModal(singleRoom, category)}
          >
            {needsCategory ? "Yes" : "No"}
          </Button>
        </Grid>
      </>
    );
  };

  const fetchLatestImages = async () => {
    // Reference to your collection
    const imagesRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "units",
      singleRoom.displayName,
      "images"
    );

    // Create a query against the collection, ordering by upload timestamp descending
    const q = query(imagesRef, orderBy("uploadedAt", "desc"), limit(10));

    try {
      const querySnapshot = await getDocs(q);
      const images = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Assuming you have a state to hold these images
      setImages(images); // Or process the array as needed
      setLoading(false);
    } catch (error) {
      console.error("Error fetching images: ", error);
      // Handle any errors, such as showing a message to the user
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLatestImages();
  }, []);

  return (
    <div>
      <Card sx={{ minWidth: 275 }}>
        <CardHeader
          style={{ background: "#282828" }}
          title={singleRoom.displayName}
          action={
            <Button variant="contained" onClick={() => setSingleRoom()}>
              Back
            </Button>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography>Status</Typography>
            </Grid>
            <Grid item xs={3}>
              <Select
                labelId="status-select-label"
                id="status-select"
                value={singleRoom.status}
                onChange={(event) => {
                  handleEdit(
                    singleRoom.displayName,
                    "headerdata",
                    "status",
                    event.target.value
                  );
                }}
                size="small"
                sx={{ width: "100%" }}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={3}>
              <Typography>Housekeeping</Typography>
            </Grid>
            <Grid item xs={3}>
              <Select
                labelId="housekeeping-select-label"
                id="housekeeping-select"
                value={singleRoom.selectedHousekeepingRequirements}
                onChange={(event) => {
                  handleEdit(
                    singleRoom.displayName,
                    "headerdata",
                    "selectedHousekeepingRequirements",
                    event.target.value
                  );
                }}
                size="small"
                sx={{ width: "100%" }}
              >
                {hKOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={3}>
              <Typography>Maintenance</Typography>
            </Grid>
            <Grid item xs={3}>
              <Select
                labelId="maintenance-select-label"
                id="maintenance-select"
                value={singleRoom.selectedMaintenanceRequirements}
                onChange={(event) => {
                  handleEdit(
                    singleRoom.displayName,
                    "headerdata",
                    "selectedMaintenanceRequirements",
                    event.target.value
                  );
                }}
                size="small"
                sx={{ width: "100%" }}
              >
                {mTOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {uniqueCategories.map((category) =>
              renderCategoryButton(category, itemsByCategory)
            )}
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275, marginTop: "50px" }}>
        <CardHeader
          style={{ background: "#282828" }}
          title="Latest Images"
          action={
            <Button
              variant="contained"
              onClick={() => setSingleRoomTimeline(true)}
            >
              View All
            </Button>
          }
        />
        <CardContent>
          <ImageList cols={5}>
            {images.map((item) => (
              <ImageListItem key={item.id}>
                <img
                  srcSet={`${item.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
                  alt={item.name}
                  loading="lazy"
                />
                <ImageListItemBar
                  title={item.name}
                  actionIcon={
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      aria-label={`info about ${item.name}`}
                      // onClick={() => }
                      target="_blank"
                      href={item.url}
                      download={true}
                    >
                      <DownloadForOfflineIcon />
                    </IconButton>
                  }
                />
              </ImageListItem>
            ))}
          </ImageList>
          <ImageUpload room={singleRoom} />
        </CardContent>
      </Card>
    </div>
  );
}

export default RoomsSingle;
