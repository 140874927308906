import React, { useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  TextField,
  Modal,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Paper,
} from "@mui/material";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../firebase";
import PropertyContext from "../contexts/PropertyContext";

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const EmployeeSchedule = () => {
  const [employees, setEmployees] = useState([]);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employeeName, setEmployeeName] = useState("");
  const [username, setUsername] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [schedule, setSchedule] = useState({});
  const [idCounter, setIdCounter] = useState(1);

  //get property details
  const { selectedProperty } = useContext(PropertyContext);

  // useEffect to get employees
  useEffect(() => {
    const fetchEmployees = async () => {
      const employeesRef = collection(
        firestore,
        "properties",
        selectedProperty,
        "employees"
      );
      const unsubscribeToEmployees = onSnapshot(employeesRef, (snapshot) => {
        const employees = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEmployees(employees);
      });
    };
    fetchEmployees();
  }, [selectedProperty]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleEditOpen = (employee) => {
    setSelectedEmployee(employee);
    setSchedule(employee.schedule);
    setEditOpen(true);
  };
  const handleEditClose = () => setEditOpen(false);

  const handleAddEmployee = async () => {
    if (employeeName && employeeType) {
      const newEmployee = {
        name: employeeName,
        username: username || "",
        type: employeeType,
        schedule: schedule,
      };
      // Add employee to Firestore
      const employeesRef = collection(
        firestore,
        "properties",
        selectedProperty,
        "employees"
      );
      await addDoc(employeesRef, newEmployee);

      // setEmployees([...employees, newEmployee]);
      // setIdCounter(idCounter + 1);
      resetForm();
      handleClose();
    } else {
      alert("Please fill in all fields");
    }
  };
  const handleRemoveEmployee = (id) => {
    // setEmployees(employees.filter((employee) => employee.id !== id));
    // Remove employee from Firestore
    const employeeRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "employees",
      id
    );
    deleteDoc(employeeRef);
    resetForm();
    handleEditClose();
  };

  const handleEditEmployee = async () => {
    if (selectedEmployee.name && selectedEmployee.type) {
      const updatedEmployee = {
        name: selectedEmployee.name,
        username: selectedEmployee.username || "",
        type: selectedEmployee.type,
        schedule: schedule,
      };
      // Update employee in Firestore
      const employeeRef = doc(
        firestore,
        "properties",
        selectedProperty,
        "employees",
        selectedEmployee.id
      );
      await updateDoc(employeeRef, updatedEmployee);

      resetForm();
      handleEditClose();
    }
  };
  const resetForm = () => {
    setEmployeeName("");
    setUsername("");
    setEmployeeType("");
    setSchedule({});
  };

  const handleCheckboxChange = (day) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: prevSchedule[day] ? null : { startTime: "", endTime: "" },
    }));
  };

  const handleTimeChange = (day, field, value) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        [field]: value,
      },
    }));
  };

  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    const period = +hour >= 12 ? "PM" : "AM";
    const formattedHour = +hour % 12 || 12;
    return `${formattedHour}:${minute} ${period}`;
  };

  const columns = [
    { field: "name", headerName: "Employee Name", width: 200 },
    // { field: "username", headerName: "Username", width: 200 },
    { field: "type", headerName: "Employee Type", width: 150 },
    ...daysOfWeek.map((day) => ({
      field: day.toLowerCase(),
      headerName: day,
      width: 150,
      valueGetter: (params) =>
        params.row.schedule[day]?.startTime && params.row.schedule[day]?.endTime
          ? `${formatTime(params.row.schedule[day].startTime)} - ${formatTime(
              params.row.schedule[day].endTime
            )}`
          : "",
    })),
  ];

  const log = () => {
    console.log(employees);
  };

  return (
    <Box>
      <Button variant="contained" onClick={handleOpen}>
        Add Employee
      </Button>
      <Box mt={2} style={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={employees}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          onRowClick={(params) => handleEditOpen(params.row)}
        />
      </Box>
      <Button variant="contained" onClick={log}>
        Log Employees
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Paper
          sx={{
            p: 4,
            position: "absolute",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, -10%)",
            width: 600,
          }}
        >
          <h2>Add Employee</h2>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <TextField
              label="Employee Name"
              value={employeeName}
              onChange={(e) => setEmployeeName(e.target.value)}
              margin="normal"
              sx={{ mr: 1, flex: 1 }}
            />
            <TextField
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              margin="normal"
              sx={{ ml: 1, flex: 1 }}
            />
          </Box>
          <FormControl fullWidth margin="normal">
            <InputLabel>Employee Type</InputLabel>
            <Select
              value={employeeType}
              onChange={(e) => setEmployeeType(e.target.value)}
            >
              <MenuItem value="maintenance">Maintenance</MenuItem>
              <MenuItem value="housekeeping">Housekeeping</MenuItem>
              <MenuItem value="porter">Porter</MenuItem>
            </Select>
          </FormControl>
          <Box>
            {daysOfWeek.map((day) => (
              <Box key={day} display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!schedule[day]}
                      onChange={() => handleCheckboxChange(day)}
                    />
                  }
                  label={day}
                />
                {schedule[day] && (
                  <Box display="flex" ml={2}>
                    <TextField
                      label="Start Time"
                      type="time"
                      value={schedule[day].startTime}
                      onChange={(e) =>
                        handleTimeChange(day, "startTime", e.target.value)
                      }
                      margin="normal"
                    />
                    <TextField
                      label="End Time"
                      type="time"
                      value={schedule[day].endTime}
                      onChange={(e) =>
                        handleTimeChange(day, "endTime", e.target.value)
                      }
                      margin="normal"
                    />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
          <Button
            variant="contained"
            onClick={handleAddEmployee}
            sx={{ mt: 2 }}
          >
            Add Employee
          </Button>
        </Paper>
      </Modal>

      {selectedEmployee && (
        <Modal open={editOpen} onClose={handleEditClose}>
          <Paper
            sx={{
              p: 4,
              position: "absolute",
              top: "10%",
              left: "50%",
              transform: "translate(-50%, -10%)",
              width: 600,
            }}
          >
            <h2>Edit Employee</h2>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <TextField
                label="Employee Name"
                value={selectedEmployee.name}
                onChange={(e) =>
                  setSelectedEmployee({
                    ...selectedEmployee,
                    name: e.target.value,
                  })
                }
                margin="normal"
                sx={{ mr: 1, flex: 1 }}
              />
              <TextField
                label="Username"
                value={selectedEmployee.username}
                onChange={(e) =>
                  setSelectedEmployee({
                    ...selectedEmployee,
                    username: e.target.value,
                  })
                }
                margin="normal"
                sx={{ ml: 1, flex: 1 }}
              />
            </Box>
            <FormControl fullWidth margin="normal">
              <InputLabel>Employee Type</InputLabel>
              <Select
                value={selectedEmployee.type}
                onChange={(e) =>
                  setSelectedEmployee({
                    ...selectedEmployee,
                    type: e.target.value,
                  })
                }
              >
                <MenuItem value="maintenance">Maintenance</MenuItem>
                <MenuItem value="housekeeping">Housekeeping</MenuItem>
                <MenuItem value="porter">Porter</MenuItem>
              </Select>
            </FormControl>
            <Box>
              {daysOfWeek.map((day) => (
                <Box key={day} display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!schedule[day]}
                        onChange={() => handleCheckboxChange(day)}
                      />
                    }
                    label={day}
                  />
                  {schedule[day] && (
                    <Box display="flex" ml={2}>
                      <TextField
                        label="Start Time"
                        type="time"
                        value={schedule[day].startTime}
                        onChange={(e) =>
                          handleTimeChange(day, "startTime", e.target.value)
                        }
                        margin="normal"
                      />
                      <TextField
                        label="End Time"
                        type="time"
                        value={schedule[day].endTime}
                        onChange={(e) =>
                          handleTimeChange(day, "endTime", e.target.value)
                        }
                        margin="normal"
                      />
                    </Box>
                  )}
                </Box>
              ))}
            </Box>
            <Button
              variant="contained"
              onClick={handleEditEmployee}
              sx={{ mt: 2 }}
            >
              Save Changes
            </Button>
            <Button
              variant="contained"
              onClick={() => handleRemoveEmployee(selectedEmployee.id)}
              sx={{ mt: 2, ml: 2 }}
            >
              Remove Employee
            </Button>
          </Paper>
        </Modal>
      )}
    </Box>
  );
};

export default EmployeeSchedule;
