import React, { useState, useEffect, useContext } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { collection, doc, onSnapshot, query, setDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import { DataGrid } from "@mui/x-data-grid";
import PropertyContext from "../contexts/PropertyContext";

// Create modal to add item types
const AddItemTypesModal = ({ onClose, categories, setCategories }) => {
  const [itemType, setItemType] = useState("");
  const { selectedProperty } = useContext(PropertyContext);

  const handleAdd = () => {
    setCategories([...categories, itemType]);
    //add catagory to firestore
    const itemCatagoriesRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "itemCatagories"
    );
    const itemCatagoriesDoc = doc(itemCatagoriesRef, itemType);
    setDoc(itemCatagoriesDoc, { name: itemType });

    //cleanup
    setItemType("");
    onClose();
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          minWidth: "30rem", // Adjust the width as needed
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          outline: "none",
        }}
      >
        <Paper elevation={3} style={{ padding: "20px" }}>
          <TextField
            label="Item Type/Category"
            value={itemType}
            onChange={(e) => setItemType(e.target.value)}
            fullWidth
            margin="normal"
          />
          <List>
            {categories.map((category, index) => (
              <ListItem key={index}>{category}</ListItem>
            ))}
          </List>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={onClose}
              style={{ marginBottom: "20px" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAdd}
              style={{ marginBottom: "20px" }}
            >
              Add Category
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

// Create modal to add items
const AddItemsModal = ({ itemTypes, vendors, onClose, data }) => {
  const [vendor, setVendor] = useState("");
  const [itemID, setItemID] = useState("");
  const [itemDifficulty, setItemDifficulty] = useState("");
  const [itemName, setItemName] = useState("");
  const [itemType, setItemType] = useState("");
  const [itemGLCode, setItemGLCode] = useState("");
  const [itemCost, setItemCost] = useState("");
  const [itemResponsibility, setItemResponsibility] = useState(""); //housekeeping, maintenance
  const [itemSKU, setItemSKU] = useState("");
  const [loadedData, setLoadedData] = useState(false);

  const { selectedProperty } = useContext(PropertyContext);

  //   console.log(vendors);

  // Handle the item addition logic here
  const handleAdd = () => {
    // Add item to firestore or other sources
    const itemsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "items"
    );
    let itemsDoc = doc(itemsRef, `${itemName.replace(/\s/g, "")}`);

    setDoc(itemsDoc, {
      vendor: vendor,
      itemID: `itemCount${itemName.replace(/\s/g, "")}`,
      itemDifficulty: itemDifficulty,
      itemName: itemName,
      itemType: itemType,
      itemGLCode: itemGLCode,
      itemSKU: itemSKU,
      itemCost: itemCost,
      itemResponsibility: itemResponsibility,
    });

    // Cleanup
    onClose();
  };

  useEffect(() => {
    if (data === null) return;
    setLoadedData(true);
    console.log(data);
    const item = data.row;
    setVendor(item.vendor);
    setItemCost(item.itemCost);
    setItemDifficulty(item.itemDifficulty);
    setItemGLCode(item.itemGLCode);
    setItemID(item.itemID);
    setItemSKU(item.itemSKU);
    setItemName(item.itemName);
    setItemResponsibility(item.itemResponsibility);
    setItemType(item.itemType);
  }, [data]);

  return (
    <Modal open={true} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          minWidth: "30rem",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          outline: "none",
        }}
      >
        <Paper elevation={3} style={{ padding: "20px" }}>
          <FormControl fullWidth margin="normal">
            <InputLabel>Vendor</InputLabel>
            <Select
              value={vendor}
              label="Vendor"
              onChange={(e) => setVendor(e.target.value)}
            >
              {vendors.map((vendor, index) => (
                <MenuItem key={index} value={vendor}>
                  {vendor}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Item Category</InputLabel>
            <Select
              value={itemType}
              label="Item Category"
              onChange={(e) => setItemType(e.target.value)}
            >
              {itemTypes.map((type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Item Responsibility</InputLabel>
            <Select
              value={itemResponsibility}
              label="Item Responsibility"
              onChange={(e) => setItemResponsibility(e.target.value)}
            >
              <MenuItem value="Housekeeping">Housekeeping</MenuItem>
              <MenuItem value="Maintenance">Maintenance</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Item Difficulty"
            value={itemDifficulty}
            onChange={(e) => setItemDifficulty(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Item SKU"
            value={itemSKU}
            onChange={(e) => setItemSKU(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Item ID"
            value={itemID}
            onChange={(e) => setItemID(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Item Name"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Item GL Code"
            value={itemGLCode}
            onChange={(e) => setItemGLCode(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Item Cost"
            value={itemCost}
            onChange={(e) => setItemCost(e.target.value)}
            fullWidth
            margin="normal"
          />

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Button variant="contained" color="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleAdd}>
              Add Item
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

function AddItems() {
  const [loading, setLoading] = useState(true); // Assuming you have a loading state
  const [itemTypes, setItemTypes] = useState([]);
  const [vendors, setVendors] = useState([]); // Assuming you have a vendors state
  const [items, setItems] = useState([]); // Assuming you have an items state
  const [additemModal, setAddItemModal] = useState(false);
  const [addItemTypeModal, setAddItemTypeModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const { selectedProperty } = useContext(PropertyContext);

  console.log("rendered additems");

  useEffect(() => {
    // Fetch item types and vendors from firestore or other sources
    setLoading(true);
    //fetch vendors
    const vendorsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "Vendors"
    );
    const vendorsQuery = query(vendorsRef);
    const unsubscribeVendors = onSnapshot(vendorsQuery, (querySnapshot) => {
      const vendorsArray = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        vendorsArray.push(data.name);
      });
      setVendors(vendorsArray);
    });

    //fetch item types
    const itemCatagoriesRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "itemCatagories"
    );
    const itemCatagoriesQuery = query(itemCatagoriesRef);
    const unsubscribeItemCatagories = onSnapshot(
      itemCatagoriesQuery,
      (querySnapshot) => {
        const itemCatagoriesArray = [];
        querySnapshot.forEach((doc) => {
          itemCatagoriesArray.push(doc.id);
        });
        setItemTypes(itemCatagoriesArray);
      }
    );

    //fetch Items
    const itemsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "items"
    );
    const itemsQuery = query(itemsRef);
    const unsubscribeItems = onSnapshot(itemsQuery, (querySnapshot) => {
      const itemsArray = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        itemsArray.push(data);
      });
      setItems(itemsArray);
    });

    setLoading(false);
    return () => {
      unsubscribeVendors();
      unsubscribeItemCatagories();
      unsubscribeItems();
    };
  }, []);
  const collumns = [
    { field: "itemName", headerName: "Name", flex: 1, editable: false },
    { field: "itemID", headerName: "ID", flex: 1, editable: false },
    { field: "itemGLCode", headerName: "GL Code", flex: 1, editable: false },
    { field: "itemType", headerName: "Category", flex: 1, editable: false },
    {
      field: "itemResponsibility",
      headerName: "Responsibility",
      flex: 1,
      editable: false,
    },
    {
      field: "itemDifficulty",
      headerName: "Minutes Needed",
      flex: 1,
      editable: false,
    },
    { field: "vendor", headerName: "Vendor", flex: 1, editable: false },
    {
      field: "itemCost",
      headerName: "Estimated Cost",
      flex: 1,
      editable: false,
    },
    {
      field: "itemSKU",
      headerName: "SKU",
      flex: 1,
      editable: false,
    },
  ];

  return (
    <div>
      {/* if loading, show mui circular indicator, else, show div */}
      {loading ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <div style={{ maxWidth: "100%" }}>
          {/* Show your items here */}
          <Button onClick={() => setAddItemTypeModal(true)}>
            Add Category
          </Button>
          <Button onClick={() => setAddItemModal(true)}>Add Item</Button>
          {/* Map your items here */}
          <DataGrid
            rows={items}
            columns={collumns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            getRowId={(row) => row.itemID}
            onRowClick={(row) => {
              setModalData(row);
              setAddItemModal(true);
            }}
            sx={{ maxWidth: "100%" }}
          />
          {addItemTypeModal && (
            <AddItemTypesModal
              categories={itemTypes}
              setCategories={setItemTypes}
              onClose={() => setAddItemTypeModal(false)}
            />
          )}
          {additemModal && (
            <AddItemsModal
              itemTypes={itemTypes}
              vendors={vendors}
              onClose={() => {
                setAddItemModal(false);
                setModalData(null);
              }}
              data={modalData}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default AddItems;
