import React, { useState, useEffect, useContext } from "react";
import { firestore } from "../firebase";
import {
  FieldValue,
  addDoc,
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
  arrayUnion,
} from "firebase/firestore";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import DashboardWidget from "./DashboardWidget";
import { set } from "date-fns";
import PropertyContext from "../contexts/PropertyContext";

const RequestRemovalModal = ({ isOpen, onClose, callerData }) => {
  const [notes, setNotes] = useState("");
  const [selection, setSelection] = useState("");

  const selections = ["Checked In", "Requested Removal", "Other"];
  const { selectedProperty } = useContext(PropertyContext);

  const markforDeletion = () => {
    console.log("called mark for deletion");
    //get today's date as mm/dd/yyyy
    const getLocalDateString = () => {
      const today = new Date();
      const localDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      return localDate.toISOString().split("T")[0];
    };

    //save data to firestore
    const dailyCallRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "dailyCalls",
      getLocalDateString()
    );
    const newCallData = {
      time: Date.now(),
      selection: selection,
      notes: notes,
    };
    const callerDoc = doc(
      firestore,
      "properties",
      selectedProperty,
      "Calls",
      callerData.id
    );
    if (selection === "Checked In") {
      updateDoc(callerDoc, {
        checkInDate: getLocalDateString(),
        status: "checked in",
      });
    }
    if (selection === "Requested Removal") {
      updateDoc(callerDoc, {
        status: "Removed",
      });
    }
    if (selection === "Other") {
      updateDoc(callerDoc, {
        status: "marked for deletion",
        notes: notes,
      });
    }

    setDoc(
      dailyCallRef,
      {
        outboundLeadCalls: arrayUnion(newCallData),
      },
      { merge: true }
    );

    //close modal
    handleClose();
  };

  const handleClose = () => {
    //clean up and close modal
    setSelection("");
    setNotes("");
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
          maxHeight: "90%",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Select
              value={selection}
              onChange={(e) => setSelection(e.target.value)}
              fullWidth
            >
              {selections.map((selection) => (
                <MenuItem key={selection} value={selection}>
                  {selection}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              fullWidth
              multiline
              rows={6}
            />
          </Grid>
        </Grid>
        <Box
          sx={{ display: "flex", mt: "2rem", justifyContent: "space-between" }}
        >
          {/* cancel button and add caller button */}
          <Button variant="contained" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => markforDeletion()}
          >
            Request Removal
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const NonLeadCallModal = ({ isOpen, onClose }) => {
  // const [time, setTime] = useState('');
  const [selection, setSelection] = useState("");
  const [notes, setNotes] = useState("");

  const selections = [
    "Current Guest",
    "Employee",
    "Not Interested",
    "Wrong Number",
    "Other",
  ];

  const { selectedProperty } = useContext(PropertyContext);

  const addNonLeadCall = () => {
    //get today's date as mm/dd/yyyy
    const getLocalDateString = () => {
      const today = new Date();
      const localDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      return localDate.toISOString().split("T")[0];
    };

    //save data to firestore
    const dailyCallRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "dailyCalls",
      getLocalDateString()
    );
    const newCallData = {
      time: Date.now(),
      selection: selection,
      notes: notes,
    };
    setDoc(
      dailyCallRef,
      {
        nonLeadCalls: arrayUnion(newCallData),
      },
      { merge: true }
    );

    //close modal
    handleClose();
  };
  const handleClose = () => {
    //clean up and close modal
    setSelection("");
    setNotes("");
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
          maxHeight: "90%",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Select
              value={selection}
              onChange={(e) => setSelection(e.target.value)}
              fullWidth
            >
              {selections.map((selection) => (
                <MenuItem key={selection} value={selection}>
                  {selection}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              fullWidth
              multiline
              rows={6}
            />
          </Grid>
        </Grid>
        <Box
          sx={{ display: "flex", mt: "2rem", justifyContent: "space-between" }}
        >
          {/* cancel button and add caller button */}
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={addNonLeadCall}>
            Add Non-Lead Call
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
const CallModal = ({ isOpen, onClose, callerData, userInfo }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [nextCallDate, setNextCallDate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [tourDate, setTourDate] = useState("");
  const [tourTime, setTourTime] = useState("");
  const [checkInDate, setCheckInDate] = useState("");
  const [checkInTime, setCheckInTime] = useState("");
  const [pastNotes, setPastNotes] = useState("");
  const [newNotes, setNewNotes] = useState("");
  const [tourAgent, setTourAgent] = useState("");
  const [callAgent, setCallAgent] = useState("");
  const [checkInAgent, setCheckInAgent] = useState("");
  const [tourProperty, setTourProperty] = useState("");
  const [tourRoom, setTourRoom] = useState("");
  const [callStatus, setCallStatus] = useState("");
  const [callCount, setCallCount] = useState(1);

  const { selectedProperty } = useContext(PropertyContext);

  //set states to caller data if caller data is passed in
  useEffect(() => {
    if (callerData) {
      console.log(callerData);
      callerData.name ? setName(callerData.name) : setName("");
      callerData.phoneNumber
        ? setPhoneNumber(callerData.phoneNumber)
        : setPhoneNumber("");
      callerData.tourDate ? setTourDate(callerData.tourDate) : setTourDate("");
      callerData.tourTime ? setTourTime(callerData.tourTime) : setTourTime("");
      callerData.checkInDate
        ? setCheckInDate(callerData.checkInDate)
        : setCheckInDate("");
      callerData.checkInTime
        ? setCheckInTime(callerData.checkInTime)
        : setCheckInTime("");
      callerData.pastNotes
        ? setPastNotes(callerData.pastNotes)
        : setPastNotes("");
      callerData.newNotes ? setNewNotes(callerData.newNotes) : setNewNotes("");
      callerData.tourAgent
        ? setTourAgent(callerData.tourAgent)
        : setTourAgent("");
      callerData.callAgent
        ? setCallAgent(callerData.callAgent)
        : setCallAgent("");
      callerData.checkInAgent
        ? setCheckInAgent(callerData.checkInAgent)
        : setCheckInAgent("");
      callerData.tourProperty
        ? setTourProperty(callerData.tourProperty)
        : setTourProperty("");
      callerData.tourRoom ? setTourRoom(callerData.tourRoom) : setTourRoom("");
      callerData.nextCallDate
        ? setNextCallDate(callerData.nextCallDate)
        : setNextCallDate("");
      callerData.callCount
        ? setCallCount(callerData.callCount + 1)
        : setCallCount(1);
    }
  }, [callerData]);

  const handleSave = () => {
    const getLocalDateString = () => {
      const today = new Date();
      const localDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      return localDate.toISOString().split("T")[0];
    };
    const usersInitials = userInfo.fullName
      .split(" ")
      .map((n) => n[0])
      .join(".")
      .concat(".");

    //save data to firestore
    const dailyCallRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "dailyCalls",
      getLocalDateString()
    );

    if (callerData) {
      const callerDoc = doc(
        firestore,
        "properties",
        selectedProperty,
        "Calls",
        callerData.id
      );

      // logic to determine who is the agent responsible for getting tour, checkin, and call
      //if callerData.tourTime is === '' and tourTime is !== '' then update tourTime and tourAgent to current user
      if (callerData.tourDate === "" && tourDate !== "") {
        setTourAgent(userInfo.id);
      }
      //if callerData.checkInTime is === '' and checkInTime is !== '' then update checkInTime and checkInAgent to current user
      if (callerData.checkInDate === "" && checkInDate !== "") {
        setCheckInAgent(userInfo.id);
      }

      const updatedCallData = {
        name: name,
        phoneNumber: phoneNumber,
        nextCallDate: nextCallDate,
        tourDate: tourDate,
        tourTime: tourTime,
        checkInDate: checkInDate,
        checkInTime: checkInTime ? checkInTime : null,
        pastNotes: `${pastNotes}\n${
          new Date(Date.now()).toLocaleString().split(",")[0]
        } - ${newNotes} - ${usersInitials}`,
        tourAgent:
          callerData.tourAgent !== null && callerData.tourAgent !== ""
            ? callerData.tourAgent
            : (callerData.tourDate === "" || callerData.tourDate === null) &&
              tourDate !== "" &&
              tourDate !== null
            ? userInfo.id
            : null,
        checkInAgent:
          callerData.checkInAgent !== null && callerData.checkInAgent !== ""
            ? callerData.checkInAgent
            : (callerData.checkInDate === "" ||
                callerData.checkInDate === null) &&
              checkInDate !== "" &&
              checkInDate !== null
            ? userInfo.id
            : null,
        callCount: callCount,
      };
      const outboundLeadCalls = {
        time: Date.now(),
        selection: "outbound lead",
        notes: newNotes,
      };

      setDoc(
        dailyCallRef,
        {
          outboundLeadCalls: arrayUnion(outboundLeadCalls),
        },
        { merge: true }
      );

      updateDoc(callerDoc, updatedCallData);
    } else {
      const callCollection = collection(
        firestore,
        "properties",
        selectedProperty,
        "Calls"
      );
      //handle null values in new call data
      const newCallData = {
        name: name,
        originalCallDate: new Date(Date.now()).toLocaleString().split(",")[0],
        phoneNumber: phoneNumber,
        nextCallDate: nextCallDate,
        tourDate: tourDate,
        tourTime: tourTime,
        checkInDate: checkInDate,
        checkInTime: checkInTime ? checkInTime : null,
        pastNotes: `${
          new Date(Date.now()).toLocaleString().split(",")[0]
        } - ${newNotes} - ${usersInitials}`,
        callAgent: userInfo.id,
        tourAgent: tourDate ? userInfo.id : null,
        checkInAgent: checkInDate ? userInfo.id : null,
        callCount: callCount,
        status: "active",
      };
      const newLeadData = {
        time: Date.now(),
        selection: "new lead",
        notes: newNotes,
      };

      setDoc(
        dailyCallRef,
        {
          leadCalls: arrayUnion(newLeadData),
        },
        { merge: true }
      );

      addDoc(callCollection, newCallData);
    }
    //clean up and close modal
    setName("");
    setNextCallDate("");
    setPhoneNumber("");
    setTourDate("");
    setTourTime("");
    setCheckInDate("");
    setCheckInTime("");
    setPastNotes("");
    setNewNotes("");
    setTourAgent("");
    setCallAgent("");
    setCheckInAgent("");
    setTourProperty("");
    setTourRoom("");

    onClose();
  };
  const handleClose = () => {
    //clean up and close modal
    setName("");
    setNextCallDate("");
    setPhoneNumber("");
    setTourDate("");
    setTourTime("");
    setCheckInDate("");
    setCheckInTime("");
    setPastNotes("");
    setNewNotes("");
    setTourAgent("");
    setCallAgent("");
    setCheckInAgent("");
    setTourProperty("");
    setTourRoom("");
    onClose();
  };

  //////////////////////////////////////////////////////////////////////////
  ////  name: 'Jimmy John',                    phone number: '+12103284098',
  ////  tour date: '10/10/2023'                tour time: '10:00 AM'
  ////  check in date: '10/10/2023'            check in time: '10:00 AM'
  ////  past notes:                            new notes:
  ////  //dissabled text box here              //text box here
  ////  'jimmy john is looking to checkin'     //text continued here
  ////  'on 10/10/2023'                        //text continued here
  ////
  ////  tour agent: 'Greg Gleason',            call agent: 'Greg Gleason',
  ////  checkin agent: 'Greg Gleason',
  ////                                               add caller to call list
  /////////////////////////////////////////////////////////////////////////

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
    maxHeight: "90%",
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={style}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Name"
              value={name}
              variant="filled"
              onChange={(e) => setName(e.target.value)}
              fullWidth
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Next Call Date"
                fullWidth
                value={nextCallDate ? dayjs(nextCallDate) : null}
                onChange={(newValue) => {
                  if (newValue !== null) {
                    setNextCallDate(newValue.format("MM/DD/YYYY"));
                  } else {
                    setNextCallDate("");
                  }
                }}
                sx={{ mt: "2rem" }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              fullWidth
            />
            <Grid item xs={6} sx={{ mt: "2rem" }}>
              {/* if callerData then show option to remove from call list and when pressed, it should open RequestRemovalModal */}
              {callerData && (
                <Button onClick={() => setOpen(true)}>Request Removal</Button>
              )}
              <RequestRemovalModal
                isOpen={open}
                onClose={() => {
                  setOpen(false);
                  handleClose();
                }}
                callerData={callerData}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {/* use mui x date picker */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Tour Date"
                fullWidth
                value={tourDate ? dayjs(tourDate) : null}
                onChange={(newValue) => {
                  if (newValue !== null) {
                    setTourDate(newValue.format("MM/DD/YYYY"));
                  } else {
                    setTourDate("");
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Tour Time"
                value={tourTime ? dayjs(tourTime.seconds * 1000) : null}
                onChange={(value) => {
                  setTourTime(value.$d);
                  console.log(value);
                }}
                fullWidth
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={6}>
            {/* use mui x date picker */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Checkin Date"
                value={checkInDate ? dayjs(checkInDate) : null}
                fullWidth
                onChange={(newValue) => {
                  if (newValue !== null) {
                    setCheckInDate(newValue.format("MM/DD/YYYY"));
                  } else {
                    setCheckInDate("");
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            {/* use MUI time picker */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Check In Time"
                value={checkInTime ? dayjs(checkInTime.seconds * 1000) : null}
                onChange={(value) => setCheckInTime(value.$d)}
                fullWidth
              />
            </LocalizationProvider>
            {/* <TextField label='Check In Time' value={checkInTime} onChange={(e) => setCheckInTime(e.target.value)} fullWidth/> */}
          </Grid>
          <Grid item xs={6}>
            {/* if past notes is not null, display disbled past notes TextField */}
            {pastNotes !== "" && (
              <TextField
                label="Past Notes"
                multiline
                rows={6}
                value={pastNotes}
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
                fullWidth
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="New Notes"
              multiline
              rows={6}
              value={newNotes}
              onChange={(e) => setNewNotes(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Box
          sx={{ display: "flex", mt: "2rem", justifyContent: "space-between" }}
        >
          {/* cancel button and add caller button */}
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {/* if callerData is null, display add caller button, else display update caller button */}
          {callerData === null ? (
            <Button variant="contained" color="primary" onClick={handleSave}>
              Add Caller to Call List
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={handleSave}>
              Update Caller
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

const CallList = ({ userInfo }) => {
  //states
  const [calls, setCalls] = useState([]);
  const [todaysLeadCalls, setTodaysLeadCalls] = useState([]);
  const [todaysOutboundLeadCalls, setTodaysOutboundLeadCalls] = useState([]);
  const [todaysNonLeadCalls, setTodaysNonLeadCalls] = useState([]);
  const [callsLoading, setCallsLoading] = useState(true);
  const [checkinList, setCheckinList] = useState([]);
  const [tourList, setTourList] = useState([]);
  const [callTodayList, setCallTodayList] = useState([]);
  const [user, setUser] = useState(userInfo);
  const { selectedProperty } = useContext(PropertyContext);

  //handle modals
  const [callModalOpen, setCallModalOpen] = useState(false);
  const [callerData, setCallerData] = useState(null);
  const handleCallModalOpen = (callerData) => {
    setCallerData(callerData);
    setCallModalOpen(true);
  };
  const handleCallModalClose = () => {
    setCallerData(null);
    setCallModalOpen(false);
  };
  const [nonLeadCallModalOpen, setNonLeadCallModalOpen] = useState(false);
  const handleNonLeadCallModal = () =>
    setNonLeadCallModalOpen(!nonLeadCallModalOpen);

  //get list of available rooms
  //getlist of calls
  useEffect(() => {
    //set user info
    setUser(userInfo);

    //get today's date as dd/mm/yyyy
    const today = new Date(Date.now()).toLocaleString().split(",")[0];

    // get list of calls from firestore
    const callsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "Calls"
    );
    const q = query(callsRef, where("status", "==", "active"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const calls = [];
      const checkinList = [];
      const tourList = [];
      const callTodayList = [];

      querySnapshot.forEach((doc) => {
        calls.push({ ...doc.data(), id: doc.id });
        if (doc.data().checkInDate == today) {
          //add to checkin list
          checkinList.push({ ...doc.data(), id: doc.id });
          return;
        }
        if (doc.data().tourDate == today) {
          //add to tour list
          tourList.push({ ...doc.data(), id: doc.id });
          return;
        }
        if (doc.data().nextCallDate == today) {
          //add to call list
          callTodayList.push({ ...doc.data(), id: doc.id });
          return;
        }
      });

      setCalls(calls);
      setCheckinList(checkinList);
      setTourList(tourList);
      setCallTodayList(callTodayList);
    });
    const getLocalDateString = () => {
      const today = new Date();
      const localDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      return localDate.toISOString().split("T")[0];
    };

    //todays calls ref
    const dailyCallRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "dailyCalls",
      getLocalDateString()
    );
    const unsubscribe2 = onSnapshot(dailyCallRef, (doc) => {
      if (doc.exists()) {
        const todaysCalls = doc.data();
        setTodaysLeadCalls(todaysCalls.leadCalls ? todaysCalls.leadCalls : []);
        setTodaysOutboundLeadCalls(
          todaysCalls.outboundLeadCalls ? todaysCalls.outboundLeadCalls : []
        );
        setTodaysNonLeadCalls(
          todaysCalls.nonLeadCalls ? todaysCalls.nonLeadCalls : []
        );
        setCallsLoading(false);
      } else {
        console.log("no doc");
        setCallsLoading(false);
      }
    });

    //unsubscribe from calls on unmount
    return () => {
      unsubscribe();
      unsubscribe2();
    };
  }, []);

  const checkinColumns = [
    { field: "name", headerName: "Name", width: 200 },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 200,
      renderCell: (params) => {
        var cleaned = ("" + params.value).replace(/\D/g, "");
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return "(" + match[1] + ") " + match[2] + "-" + match[3];
        }
        return null;
      },
    },
    {
      field: "checkInTime",
      headerName: "Check In Time",
      width: 200,
      renderCell: (params) => {
        return params.value
          ? new Date(params.value.seconds * 1000).toLocaleTimeString()
          : "";
      },
    },
    {
      field: "pastNotes",
      headerName: "Last Note on Call",
      flex: 1,
      renderCell: (params) => {
        return params.value
          ? params.value.split("\n")[params.value.split("\n").length - 1]
          : "";
      },
    },
  ];
  const tourColumns = [
    { field: "name", headerName: "Name", width: 200 },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 200,
      renderCell: (params) => {
        var cleaned = ("" + params.value).replace(/\D/g, "");
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return "(" + match[1] + ") " + match[2] + "-" + match[3];
        }
        return null;
      },
    },
    {
      field: "tourTime",
      headerName: "Tour Time",
      width: 200,
      renderCell: (params) => {
        return params.value
          ? new Date(params.value.seconds * 1000).toLocaleTimeString()
          : "";
      },
    },
    {
      field: "pastNotes",
      headerName: "Last Note on Call",
      flex: 1,
      renderCell: (params) => {
        return params.value
          ? params.value.split("\n")[params.value.split("\n").length - 1]
          : "";
      },
    },
  ];
  const callTodayColumns = [
    { field: "name", headerName: "Name", width: 200 },
    { field: "callCount", headerName: "Times Called", width: 200 },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 200,
      renderCell: (params) => {
        var cleaned = ("" + params.value).replace(/\D/g, "");
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return "(" + match[1] + ") " + match[2] + "-" + match[3];
        }
        return null;
      },
    },
    {
      field: "pastNotes",
      headerName: "Last Note on Call",
      flex: 1,
      renderCell: (params) => {
        return params.value
          ? params.value.split("\n")[params.value.split("\n").length - 1]
          : "";
      },
    },
  ];

  return (
    <div style={{ margin: 0 }}>
      {/* display today or all calls option or requested deletion */}
      <Box
        sx={{ width: "100%", display: "flex", flexDirection: "column", mt: 0 }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 0,
          }}
        >
          <DashboardWidget
            name={"Calls Today"}
            loading={callsLoading}
            taskCount={todaysNonLeadCalls.length + todaysLeadCalls.length}
            desc="Today"
            linkTo=""
          />
          <DashboardWidget
            name={"Expected Check Ins"}
            loading={callsLoading}
            taskCount={checkinList.length}
            desc="Today"
            linkTo=""
          />
          <DashboardWidget
            name={"Expected Tours"}
            loading={callsLoading}
            taskCount={tourList.length}
            desc="Today"
            linkTo=""
          />
          <DashboardWidget
            name={"Outbound Calls"}
            loading={callsLoading}
            taskCount={todaysOutboundLeadCalls.length}
            desc="Today"
            linkTo=""
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            mt: "2rem",
            mr: "2rem",
          }}
        >
          <Button onClick={() => handleNonLeadCallModal()}>
            New Non-Lead Call
          </Button>
          <Button onClick={() => handleCallModalOpen()}>New Lead</Button>
        </Box>
      </Box>

      {/* if checkin today display */}
      {checkinList.length > 0 && (
        <div>
          <h3>Check ins Today ({checkinList.length}) </h3>
          <DataGrid
            rows={checkinList}
            columns={checkinColumns}
            pageSize={5}
            onRowClick={(row) => handleCallModalOpen(row.row)}
            disableRowSelectionOnClick
          />
        </div>
      )}
      {/* if tour today display */}
      {tourList.length > 0 && (
        <div>
          <h3>Tours Today ({tourList.length})</h3>
          <DataGrid
            rows={tourList}
            columns={tourColumns}
            pageSize={5}
            onRowClick={(row) => handleCallModalOpen(row.row)}
            disableRowSelectionOnClick
          />
        </div>
      )}
      {/* if on call list display */}
      {callTodayList.length > 0 && (
        <div>
          <h3>Calls Today ({callTodayList.length})</h3>
          <DataGrid
            rows={callTodayList}
            columns={callTodayColumns}
            pageSize={5}
            onRowClick={(row) => handleCallModalOpen(row.row)}
            disableRowSelectionOnClick
          />
        </div>
      )}
      {/* if no calls display */}
      {callTodayList.length === 0 && (
        <div>
          <h3>No Calls left for the day</h3>
        </div>
      )}
      {/* <Button onClick={() => console.log(calls)}>log calls</Button> */}

      {/* modals */}
      <CallModal
        isOpen={callModalOpen}
        onClose={handleCallModalClose}
        callerData={callerData}
        userInfo={user}
      />
      <NonLeadCallModal
        isOpen={nonLeadCallModalOpen}
        onClose={handleNonLeadCallModal}
      />
    </div>
  );
};

export default CallList;
