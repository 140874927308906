import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import DashboardWidget from "./DashboardWidget";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { firestore } from "../firebase";
import PropertyContext from "../contexts/PropertyContext";

export default function Dashboard({ property, userInfo }) {
  const [workOrdersLoading, setWorkOrdersLoading] = useState(true);
  const [taskTodayLoading, setTasksTodayLoading] = useState(true);
  const [vendorsTodayLoading, setVendorsTodayLoading] = useState(true);
  const [POsLoading, setPOsTodayLoading] = useState(true);
  const [vendorTasksLoading, setVendorTasksLoading] = useState(true);

  const [openWorkOrders, setOpenWorkOrders] = useState([]);
  const [pendingWorkOrders, setPendingWorkOrders] = useState([]);
  const [vendorsToday, setVendorsToday] = useState([]);
  const [openIssues, setOpenIssues] = useState([]);

  const [mytasks, setmytasks] = useState([]);
  const [onLoad, setOnLoad] = useState();

  const { selectedProperty } = useContext(PropertyContext);

  console.log(userInfo);
  let firstRun = 0;

  const getWorkOrders = async () => {
    const workOrdersRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "workOrders"
    );
    const q2 = query(workOrdersRef);

    const unsub = onSnapshot(q2, (snap) => {
      const openOrders = snap.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((request) => request.completed === false)
        .filter((request) => request.markedCompleted !== true);

      const pendingOrders = snap.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((request) => request.completed === false)
        .filter((request) => request.markedCompleted === true);

      setOpenWorkOrders(openOrders);
      setPendingWorkOrders(pendingOrders);
    });
    setWorkOrdersLoading(false);
  };
  const pushTasksToFirestore = async (tasks) => {
    console.log("tasks", tasks);
    const batch = writeBatch(firestore);
    // const today = new Date().setUTCHours(0, 0, 0, 0);
    const getLocalDateString = () => {
      const today = new Date();
      const localDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      return localDate.toISOString().split("T")[0];
    };
    const todaysTasksRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "modTasks",
      "dailyReports",
      getLocalDateString()
    );

    //verify there is still nothing in the firestore collection

    const queryTodaysTasks = query(todaysTasksRef);

    const todaysTasksSnapshot = await getDocs(queryTodaysTasks);

    if (todaysTasksSnapshot.empty) {
      //console.log(todaysTasksSnapshot)
      tasks.forEach((task) => {
        const docRef = doc(todaysTasksRef); // Create a new document reference
        batch.set(docRef, task); // Add the task to the batch
      });
      await batch.commit(); // Commit the batch
      ///add specific document for sanity check
      // Define the document reference
      const mySanity = {
        isComplete: true,
        init: true,
      };
      const myDocRef = doc(todaysTasksRef, "sanity");

      // Add or update the document
      await setDoc(myDocRef, mySanity);
    } else {
      console.log("write to firestore was called but documents were found!");
    }
  };

  const getTasks = async () => {
    const getLocalDateString = () => {
      const today = new Date();
      const localDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      return localDate.toISOString().split("T")[0];
    };

    const tasksRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "modTasks",
      "base",
      "allTasks"
    );
    const todaysTasksRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "modTasks",
      "dailyReports",
      getLocalDateString()
    );

    const queryTasks = query(tasksRef);
    const queryTodaysTasks = query(todaysTasksRef);

    const todaysTasksSnapshot = await getDocs(queryTodaysTasks);
    const tasksSnapshot = await getDocs(queryTasks);

    const sanityChecker = async () => {
      const sanityDocument = doc(todaysTasksRef, "sanity");
      const sanityDocCheck = await getDoc(sanityDocument);

      if (sanityDocCheck.exists()) {
        //   console.log("Document exists:", sanityDocCheck.data());
        return true; // or return the document data if needed
      } else {
        //   console.log("No such document!");
        return false;
      }
    };

    const todaysTasks = [];

    console.log("todaysTasksSnapshot", todaysTasksSnapshot);

    if (todaysTasksSnapshot.empty) {
      //sanity check to verify that documents do not exist
      sanityChecker().then((exists) => {
        if (exists) {
          console.log("sanity checker override");
        } else {
          if (!firstRun === 0) {
            return console.log("sanity checker override");
          }
          firstRun++;
          console.log("sanity checker found nothing");
        }
      });
      // disabled to save bandwidth and firestore querries
      // If today's tasks do not exist, query all tasks and process them
      // console.log(`todaysTasks empty: `, todaysTasksSnapshot)
      tasksSnapshot.forEach((doc) => {
        const task = doc.data();
        const dayOfWeekLong = new Date(getLocalDateString())
          .toLocaleString("en-US", { weekday: "long" })
          .toLowerCase();
        const dateOfMonth = new Date(getLocalDateString()).getDate();

        if (task.type === "daily") {
          todaysTasks.push(task);
        } else if (
          task.type === "weekly" &&
          task.days.includes(dayOfWeekLong)
        ) {
          todaysTasks.push(task);
        } else if (
          task.type === "monthly" &&
          task.days.includes(dateOfMonth.toString())
        ) {
          todaysTasks.push(task);
        }
      });
      // todaysTasks.push({ taskDetails: "test", type: "daily" });
      console.log(todaysTasks);
      await pushTasksToFirestore(todaysTasks);
    } else {
      // If today's tasks exist, filter out the completed tasks
      console.log(`todaysTasks: `, todaysTasksSnapshot);

      todaysTasksSnapshot.forEach((doc) => {
        const task = doc.data();
        if (!task.isComplete) {
          task.docId = doc.id;
          todaysTasks.push(task);
        }
      });
    }
    setmytasks(todaysTasks);
    setTasksTodayLoading(false);
    //console.log(todaysTasks)
  };
  const getVendors = async () => {
    const vendorRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "Vendors"
    );
    const queryVendors = query(vendorRef);
    const todaysVendors = [];

    const unsub = onSnapshot(queryVendors, (snap) => {
      snap.docs.forEach((doc) => {
        const vendor = {
          id: doc.id,
          ...doc.data(),
        };

        // Get today's day of week and date of the month
        const today = new Date();
        const dayOfWeek = today.toLocaleString("en-us", { weekday: "long" }); // 'Monday', 'Tuesday', etc.
        const dateOfMonth = today.getDate(); // 1 - 31

        if (
          vendor.type === "weekly" &&
          vendor.days &&
          vendor.days.includes(dayOfWeek)
        ) {
          todaysVendors.push(vendor);
        }

        if (
          vendor.type === "monthly" &&
          vendor.days &&
          vendor.days.includes(dateOfMonth.toString())
        ) {
          todaysVendors.push(vendor);
        }
      });
      //console.log(todaysVendors)
      setVendorsToday(todaysVendors);
      setVendorsTodayLoading(false);
    });
  };
  const getPOs = async () => {
    //not emplemented per request
  };
  const getPropertyIssues = async () => {
    const issuesRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "propertyIssues"
    );
    const queryIssues = query(issuesRef);

    const unsub = onSnapshot(queryIssues, (snap) => {
      const openIssues = snap.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((request) => request.completed === false);

      setOpenIssues(openIssues);
      setVendorTasksLoading(false);
    });
  };

  useEffect(() => {
    getWorkOrders();
    getVendors();
    getPropertyIssues();
    getTasks();
  }, []);

  const handleMarkComplete = async (task) => {
    const time = new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
    const initials = userInfo.fullName
      .split(" ")
      .map((n) => n[0])
      .join(".")
      .concat(".");
    console.log(`${initials} marked task ${task.docId} as complete at ${time}`);
    const getLocalDateString = () => {
      const today = new Date();
      const localDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      return localDate.toISOString().split("T")[0];
    };
    // const today = new Date(Date.now()).setUTCHours(0,0,0,0)

    const docRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "modTasks",
      "dailyReports",
      getLocalDateString(),
      task.docId
    );
    const updateData = {
      markedCompletedBy: initials,
      markedCompletedTime: time,
      isComplete: true,
    };
    await updateDoc(docRef, updateData);
    const newTasks = mytasks.filter((item) => item.docId !== task.docId);
    setmytasks(newTasks);
  };

  const hasMorningTask = mytasks.some((task) => task.time === "morning");
  const hasFirstThinkTask = mytasks.some((task) => task.time === "first thing");
  const hasNoonTask = mytasks.some((task) => task.time === "noon");
  const hasAfternoonTask = mytasks.some((task) => task.time === "afternoon");
  const hasEveningTask = mytasks.some((task) => task.time === "evening");
  const hasEODTask = mytasks.some((task) => task.time === "EOD");

  return (
    <Container sx={{ mt: 8 }}>
      {/* <Typography variant="h4" sx={{ mb: 4, textAlign: 'center' }}>
        Dashboard
      </Typography> */}
      {/* open work orders          tasks today         vendors due today           Open POs        Open Vendor Tasks */}
      <Box
        marginBottom="1rem"
        display="flex"
        flexDirection="row"
        width="100%"
        bgColor="background.paper"
        borderRadius="16px"
        border="1"
        borderColor="blue"
      >
        <DashboardWidget
          name={"Work Orders"}
          loading={workOrdersLoading}
          taskCount={openWorkOrders.length}
          desc="Open"
          linkTo="/workOrders"
        />
        <DashboardWidget
          name={"tasks today"}
          loading={taskTodayLoading}
          taskCount={mytasks.length}
          linkTo="/mod-tasks"
          desc="Remaining"
        />
        <DashboardWidget
          name={"Vendors today"}
          loading={vendorsTodayLoading}
          taskCount={vendorsToday.length}
          desc="Scheduled"
          linkTo="/vendors"
        />
        {/* <DashboardWidget name={"Open POs"} loading={POsLoading}/> */}
        <DashboardWidget
          name={"Property Issues"}
          loading={vendorTasksLoading}
          taskCount={openIssues.length}
          linkTo="/propertyIssues"
          desc="Open"
        />
      </Box>
      {/* <Box sx={{float: 'right'}}>
            
            <Button>
                Add New Task
            </Button>

        </Box> */}
      {/* todays tasks */}
      {/* if after six generate report button*/}
      <Box
        sx={{
          width: "100%",
          borderRadius: "16px",
          border: 1,
          borderColor: "blue",
        }}
      >
        {hasFirstThinkTask && (
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      sx={{ flex: "1 1 100%" }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      Opening Tasks
                    </Typography>
                  </TableCell>
                  <TableCell align="right">Mark Complete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mytasks
                  .filter((task) => task.time === "first thing")
                  .map((task) => (
                    <TableRow
                      key={task.taskDetails}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        height: 33,
                      }}
                    >
                      <TableCell component="th" scope="row" sx={{ pl: "5rem" }}>
                        {task.taskDetails}
                      </TableCell>
                      <TableCell align="right">
                        <Button onClick={() => handleMarkComplete(task)}>
                          Mark Complete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {hasMorningTask && (
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      sx={{ flex: "1 1 100%" }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      Morning Tasks
                    </Typography>
                  </TableCell>
                  <TableCell align="right">Mark Complete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mytasks
                  .filter((task) => task.time === "morning")
                  .map((task) => (
                    <TableRow
                      key={task.taskDetails}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        height: 33,
                      }}
                    >
                      <TableCell component="th" scope="row" sx={{ pl: "5rem" }}>
                        {task.taskDetails}
                      </TableCell>
                      <TableCell align="right">
                        <Button onClick={() => handleMarkComplete(task)}>
                          Mark Complete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {hasNoonTask && (
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      sx={{ flex: "1 1 100%" }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      Noon Tasks
                    </Typography>
                  </TableCell>
                  <TableCell align="right">Mark Complete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mytasks
                  .filter((task) => task.time === "noon")
                  .map((task) => (
                    <TableRow
                      key={task.taskDetails}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        height: 33,
                      }}
                    >
                      <TableCell component="th" scope="row" sx={{ pl: "5rem" }}>
                        {task.taskDetails}
                      </TableCell>
                      <TableCell align="right">
                        <Button onClick={() => handleMarkComplete(task)}>
                          Mark Complete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {hasAfternoonTask && (
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      sx={{ flex: "1 1 100%" }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      Afternoon Tasks
                    </Typography>
                  </TableCell>
                  <TableCell align="right">Mark Complete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mytasks
                  .filter((task) => task.time === "afternoon")
                  .map((task) => (
                    <TableRow
                      key={task.taskDetails}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        height: 33,
                      }}
                    >
                      <TableCell component="th" scope="row" sx={{ pl: "5rem" }}>
                        {task.taskDetails}
                      </TableCell>
                      <TableCell align="right">
                        <Button onClick={() => handleMarkComplete(task)}>
                          Mark Complete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {hasEveningTask && (
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      sx={{ flex: "1 1 100%" }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      Evening Tasks
                    </Typography>
                  </TableCell>
                  <TableCell align="right">Mark Complete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mytasks
                  .filter((task) => task.time === "evening")
                  .map((task) => (
                    <TableRow
                      key={task.taskDetails}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        height: 33,
                      }}
                    >
                      <TableCell component="th" scope="row" sx={{ pl: "5rem" }}>
                        {task.taskDetails}
                      </TableCell>
                      <TableCell align="right">
                        <Button onClick={() => handleMarkComplete(task)}>
                          Mark Complete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {hasEODTask && (
          <TableContainer>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography
                      sx={{ flex: "1 1 100%" }}
                      variant="h6"
                      id="tableTitle"
                      component="div"
                    >
                      End of Day Tasks
                    </Typography>
                  </TableCell>
                  <TableCell align="right">Mark Complete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mytasks
                  .filter((task) => task.time === "EOD")
                  .map((task) => (
                    <TableRow
                      key={task.taskDetails}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        height: 33,
                      }}
                    >
                      <TableCell component="th" scope="row" sx={{ pl: "5rem" }}>
                        {task.taskDetails}
                      </TableCell>
                      <TableCell align="right">
                        <Button onClick={() => handleMarkComplete(task)}>
                          Mark Complete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {mytasks.length == 0 && (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              minHeight: "10rem",
              alignItems: "center",
            }}
          >
            <Typography>
              You finished your tasks for today! look at you!
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
}
