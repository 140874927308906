import React, { useEffect, useRef, useState } from "react";
import { collection, addDoc, doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../firebase";
import { Navigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import {
  Autocomplete,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";

///This is a PUBLIC facing page that will allow a user to view a work order without logging in

function CreateNewWorkOrder({ property }) {
  const [issueDescription, setIssueDescription] = useState("");
  const [rooms, setRooms] = useState([]);
  const [room, setRoom] = useState("");
  const [permissionToEnter, setPermissionToEnter] = useState(false);
  const [receiveUpdates, setReceiveUpdates] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [workOrderId, setWorkOrderId] = useState("");
  const [redirectIn, setRedirectIn] = useState(false);
  const toast = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("submitting work order");
    if (room === "" || issueDescription === "") {
      console.log("missing fields");
      toast.current.show({
        severity: "error",
        summary: "Something went wrong!",
        detail: "please make sure you have set room number & description.",
      });
      return;
    }
    if (receiveUpdates && phoneNumber === "") {
      console.log("missing phone number");
      toast.current.show({
        severity: "error",
        summary: "Something went wrong!",
        detail: "please enter a phone number to receive updates.",
      });
      return;
    }

    const workOrderData = {
      completed: false,
      isSignedFor: false,
      markedCompleteBy: null,
      markedCompleted: false,
      markedCompletedTime: null,
      room: room,
      pteApproved: permissionToEnter,
      orderDetails: issueDescription,
      status: "pending",
      phoneNumber: phoneNumber,
      receiveUpdates: receiveUpdates,
      reportedTime: new Date(),
    };
    console.log(workOrderData);
    //add work order to firestore
    const workOrderRef = collection(
      firestore,
      "properties",
      property,
      "workOrders"
    );
    const newWorkOrder = await addDoc(workOrderRef, workOrderData);
    console.log("Work order added with ID: ", newWorkOrder.id);
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Work order submitted.",
    });

    setTimeout(() => {
      setRedirectIn(true);
      setWorkOrderId(newWorkOrder.id);
    }, 3000);
  };

  //get rooms from firestore
  useEffect(() => {
    const roomsRef = collection(firestore, "properties", property, "units");
    let units = [];
    const unsub = onSnapshot(roomsRef, (snapshot) => {
      const rooms = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // for each room push room.displayName to units array
      rooms.forEach((room) => {
        units.push(room.displayName);
      });
      setRooms(units);
    });
    return unsub;
  }, []);

  useEffect(() => {
    if (workOrderId) {
      window.location.reload();
    }
  }, [workOrderId]);

  if (workOrderId) {
    return (
      <Navigate
        to={`/workorder?property=${property}&WorkOrderID=${workOrderId}`}
      />
    );
  }

  return (
    <div>
      <div>
        <h1>Create New Work Order</h1>
        <Autocomplete
          options={rooms}
          onChange={(e, v) => setRoom(v)}
          renderInput={(params) => (
            <TextField {...params} label="Enter Room Number" />
          )}
        />
        <TextField
          label="Issue Description"
          fullWidth
          multiline
          rows={4}
          onChange={(e) => setIssueDescription(e.target.value)}
        />
        <div>
          <label>
            <input
              type="checkbox"
              onChange={(e) => setPermissionToEnter(e.target.checked)}
            />
            Permission to enter
          </label>

          <label>
            <input
              type="checkbox"
              onChange={(e) => setReceiveUpdates(e.target.checked)}
            />
            Receive updates
          </label>
        </div>
        {receiveUpdates && (
          <TextField
            label="Phone Number"
            fullWidth
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        )}
        <button onClick={handleSubmit}>Submit Work Order</button>
      </div>
      <div>
        {/* if redirectIn is true show redirecting in 5 seconds and loading spinner */}
        {redirectIn && (
          <div id="centeredRedirectNotify">
            <div>Redirecting in 3 seconds...</div>
            <CircularProgress />
          </div>
        )}
      </div>

      <div id="footer">
        <Toast ref={toast} position="bottom-right" />
      </div>
    </div>
  );
}

function PublicWorkOrders() {
  // Get URL parameters
  const urlParams = new URLSearchParams(window.location.search);
  const propertyId = urlParams.get("property");
  const orderID = urlParams.get("WorkOrderID");

  const [workOrderDetails, setWorkOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (propertyId && orderID) {
      // Get work order details
      const workOrderRef = doc(
        firestore,
        "properties",
        propertyId,
        "workOrders",
        orderID
      );
      const unsub = onSnapshot(workOrderRef, (doc) => {
        if (doc.exists()) {
          setWorkOrderDetails(doc.data());
          setLoading(false);
        } else {
          // doc.data() will be undefined in this case
          setWorkOrderDetails(null);
        }
      });
      return () => unsub();
    }
    if (propertyId && !orderID) {
      //verify property is a valid property
      console.log(propertyId);
      const propertyRef = doc(firestore, "properties", propertyId);
      const unsub = onSnapshot(propertyRef, (doc) => {
        if (!doc.exists()) {
          console.log("property does not exist");
          setRedirect(true);
        }
      });
    }
  }, [propertyId, orderID]);

  if (!propertyId) {
    return <Navigate to="/404" />;
  }

  if (redirect) {
    return <Navigate to="/404" />;
  }

  if (!orderID) {
    return <CreateNewWorkOrder property={propertyId} />;
  }

  if (loading) {
    return (
      <div>
        <div>
          <Typography variant="h4">Loading...</Typography>
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (workOrderDetails === null) {
    return <Navigate to="/404" />;
  }

  return (
    <div>
      <div>
        <h1>Work Order Details</h1>
        <Typography variant="h6">Room: {workOrderDetails.room}</Typography>
        <Typography variant="body1">
          Issue Description: {workOrderDetails.orderDetails}
        </Typography>
        <Typography variant="body1">
          Permission to enter: {workOrderDetails.pteApproved ? "Yes" : "No"}
        </Typography>
        <Typography variant="body1">
          Receive updates: {workOrderDetails.receiveUpdates ? "Yes" : "No"}
        </Typography>
        {workOrderDetails.receiveUpdates && (
          <Typography variant="body1">
            Phone Number: {workOrderDetails.phoneNumber}
          </Typography>
        )}
        <Typography variant="body1">
          Status: {workOrderDetails.status}
        </Typography>
        {workOrderDetails.notes && (
          <Typography variant="body1">
            Notes: {workOrderDetails.notes}
          </Typography>
        )}
        {workOrderDetails.completedBy && (
          <Typography variant="body1">
            Completed by: {workOrderDetails.completedBy}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default PublicWorkOrders;
