import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const BetaPopup = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Welcome to Our Beta Application"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This application is currently in a beta phase. We appreciate your
          participation in this testing phase. Please be aware that you may
          encounter some bugs. If you do, kindly report them to Greg. Your
          feedback is invaluable to us. Thank you for supporting our development
          journey!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Understand
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BetaPopup;
