import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { firestore } from "../firebase";
import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";

import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

function PublicPropertyReviews() {
  const urlParams = new URLSearchParams(window.location.search);
  const propertyId = urlParams.get("property");
  const toast = useRef(null);

  const [reviewBody, setReviewBody] = useState("");
  const [rating, setRating] = useState("");
  const emotions = [
    { icon: <SentimentVeryDissatisfiedIcon />, value: "Unhappy" },
    { icon: <SentimentNeutralIcon />, value: "Neutral" },
    { icon: <SentimentVerySatisfiedIcon />, value: "Happy" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!propertyId) {
      return toast.current.show({
        severity: "error",
        summary: "Something went wrong!",
        detail: "no property id found.",
      });
    }
    const propertyRef = doc(firestore, "properties", propertyId);
    //check if property exists
    const propertyDoc = await getDoc(propertyRef);
    if (!propertyDoc.exists()) {
      return toast.current.show({
        severity: "error",
        summary: "Something went wrong!",
        detail: "property not found.",
      });
    }

    const review = {
      body: reviewBody,
      rating: rating,
      timestamp: serverTimestamp(),
    };
    try {
      const reviewsRef = collection(
        firestore,
        "properties",
        propertyId,
        "reviews"
      );
      await addDoc(reviewsRef, review);
      setReviewBody("");
      setRating("");
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Review submitted.",
      });
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  //   return (
  //     <div>
  //       <div style={{ padding: 20 }}>
  //         <Typography variant="h4" gutterBottom>
  //           Submit a Review
  //         </Typography>
  //         <form onSubmit={handleSubmit}>
  //           {emotions.map((emotion, index) => (
  //             <IconButton
  //               key={index}
  //               onClick={() => setRating(emotion.value)}
  //               color={rating === emotion.value ? "primary" : "default"}
  //             >
  //               {emotion.icon}
  //             </IconButton>
  //           ))}
  //           {/* if emotion selected display review */}
  //           {rating && (
  //             <TextField
  //               fullWidth
  //               label="Review"
  //               multiline
  //               rows={4}
  //               value={reviewBody}
  //               onChange={(e) => setReviewBody(e.target.value)}
  //               margin="normal"
  //             />
  //           )}
  //           <Button type="submit" variant="contained" color="primary">
  //             Submit Review
  //           </Button>
  //         </form>
  //       </div>
  //       <div id="footer">
  //         <Toast ref={toast} position="bottom-right" />
  //       </div>
  //     </div>
  //   );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="70vh"
    >
      <Box width="100%" maxWidth={600} padding={2} boxSizing="border-box">
        <Typography variant="h4" gutterBottom align="center">
          Submit a Review
        </Typography>
        <form onSubmit={handleSubmit} style={{ textAlign: "center" }}>
          <div>
            {emotions.map((emotion, index) => (
              <IconButton
                key={index}
                size="large"
                onClick={() => setRating(emotion.value)}
                color={rating === emotion.value ? "primary" : "default"}
                style={{ padding: 12, margin: 5, fontSize: 200 }}
              >
                {emotion.icon}
              </IconButton>
            ))}
          </div>
          {rating && (
            <TextField
              fullWidth
              label="Review"
              multiline
              rows={4}
              value={reviewBody}
              onChange={(e) => setReviewBody(e.target.value)}
              margin="normal"
            />
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            Submit Review
          </Button>
        </form>
        <div id="footer">
          <Toast ref={toast} position="bottom-right" />
        </div>
      </Box>
    </Box>
  );
}

export default PublicPropertyReviews;
