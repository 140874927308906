import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  QuerySnapshot,
  addDoc,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../firebase";
import { DataGrid } from "@mui/x-data-grid";
import { AddCircle } from "@mui/icons-material";
import PropertyContext from "../contexts/PropertyContext";

const NewPurchaseOrderModal = () => {
  const [open, setOpen] = useState(false);
  const [vendor, setVendor] = useState("");
  const [vendors, setVendors] = useState([]);
  const [vendorItems, setVendorItems] = useState([]);
  const [addItem, setAddItem] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [activeItem, setActiveItem] = useState(null);
  const [orderItems, setOrderItems] = useState([]);
  const { selectedProperty } = useContext(PropertyContext);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    //cleanup
    setVendor("");
    setVendorItems([]);
    setAddItem(false);
    setInputValue("");
    setOrderItems([]);
    setActiveItem(null);
    setOpen(false);
  };

  // Style for the modal
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const styleActive = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const bottomBar = {
    width: "100%",
    mt: "2rem",
    display: "flex", // Ensure it's a flex container
    flexDirection: "row",
    justifyContent: "space-between",
  };

  useEffect(() => {
    //get list of vendors
    const vendorsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "Vendors"
    );
    const vendorsQuery = query(vendorsRef);

    const unsubscribeToVendors = onSnapshot(vendorsQuery, (snapshot) => {
      const vendorsArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVendors(vendorsArray);
    });
  }, []);

  useEffect(() => {
    if (vendor === "") return;

    const itemsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "items"
    );
    const itemsQuery = query(itemsRef, where("vendor", "==", vendor.name));

    const unsubscribeToItems = onSnapshot(itemsQuery, (snapshot) => {
      const itemsArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(itemsArray);
      setVendorItems(itemsArray);
    });
  }, [vendor]);
  const getLocalDateString = () => {
    const today = new Date();
    const localDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    return localDate.toISOString().split("T")[0];
  };

  const processRequestQuote = () => {
    const totalSum = orderItems.reduce((sum, item) => {
      return sum + item.itemCost * item.count;
    }, 0);

    const data = {
      isOpen: true,
      status: "Quote Requested",
      vendor: vendor.name,
      porType: vendor.porType,
      items: orderItems,
      expectedTotal: totalSum,
      date: getLocalDateString(),
    };

    const porsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "PORs"
    );
    addDoc(porsRef, data);

    generateRequestQuote();
  };

  const generateRequestQuote = () => {
    if (orderItems.length == 0) return alert("please add at least one item");
    const emailTo = vendor.contactEmail;
    const subjectLine = encodeURIComponent("Requesting Quote");
    let body = "";
    if (vendor.contactName && vendor.contactName !== "") {
      body = body + `Hello ${vendor.contactName},\n\n\n\n`;
    }

    body = body + `Please provide a quote for the following items: \n\n`;

    orderItems.forEach((item) => {
      body = body + `${item.count}   ${item.itemSKU}   ${item.itemName}`;
      if (item.count > 1) {
        body = body + `s\n`;
      } else {
        body = body + `\n`;
      }
    });

    body = encodeURIComponent(body);

    const mailto = `mailto:${emailTo}?subject=${subjectLine}&body=${body}`;
    console.log(`mailto: `, mailto);
  };

  const handleEditCommit = (newRow, oldRow) => {
    // Create a new array with the updated row
    const updatedRows = orderItems.map((row) => {
      if (row.id === newRow.id) {
        return newRow; // Use the updated row data
      }
      return row; // Keep the existing row data for other rows
    });

    setOrderItems(updatedRows); // Update the state with the new array
    return newRow; // Return the updated row as required by processRowUpdate
  };

  const orderColumns = [
    {
      field: "count",
      headerName: "Quantity",
      editable: true,
    },
    { field: "itemName", headerName: "Item", editable: true, flex: 1 },
    {
      field: "itemCost",
      headerName: "Amount",
      editable: true,
      //   renderCell: (params) => (
      //     <TextField
      //       value={params.value}
      //       onChange={(event) => {
      //         console.log("Change Detected: ", event.target.value);
      //         commitChanges(params.id, event.target.value);
      //       }}
      //       onSubmit={() => {
      //         console.log("submit detected");
      //       }}
      //       onBlur={() => console.log("blurred")}
      //     />
      //   ),
    },
    {
      field: "x",
      headerName: "Total",
      renderCell: (params) => {
        return Number(params.row.count) * Number(params.row.itemCost);
      },
    },
    // { field: "itemGLCode", headerName: "GL Code", editable: false },
  ];

  return (
    <div>
      <Box display="flex" width="100%" justifyContent="flex-end">
        <Box>
          <Button variant="contained" onClick={handleOpen}>
            New Order
          </Button>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {vendors.length > 0 ? (
          <Box sx={vendor === "" ? style : styleActive}>
            {vendor === "" ? (
              <>
                <h2 id="modal-modal-title">Please Select Vendor</h2>
                <Select
                  value={vendor}
                  label="Vendor"
                  onChange={(e) => {
                    setVendor(e.target.value);
                    console.log(e.target.value);
                  }}
                >
                  {vendors.map((vendor) => (
                    <MenuItem key={vendor.id} value={vendor}>
                      {vendor.name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : (
              <>
                <h2 id="modal-modal-title">
                  Create {vendor.porType} for {vendor.name}
                </h2>
                {vendorItems.length === 0 ? (
                  <p id="modal-modal-description">
                    No items have been added to this vendor
                  </p>
                ) : (
                  <>
                    {orderItems.length > 0 && (
                      <DataGrid
                        rows={orderItems}
                        columns={orderColumns}
                        processRowUpdate={handleEditCommit}
                        disableRowSelectionOnClick
                      />
                    )}
                    {/* <DataGrid></DataGrid> */}
                    {addItem ? (
                      <Autocomplete
                        options={vendorItems}
                        getOptionLabel={(item) => item.itemName}
                        style={{ width: 300 }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        onChange={(event, newValue) => {
                          setActiveItem(newValue);
                          //   setInputValue(newValue ? newValue.itemName : '');
                          newValue.count = Number(1);
                          setOrderItems([...orderItems, newValue]);
                          setAddItem(false);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select an item"
                            variant="outlined"
                          />
                        )}
                      />
                    ) : (
                      <IconButton
                        onClick={() => {
                          setAddItem(true);
                        }}
                      >
                        <AddCircle />
                      </IconButton>
                    )}

                    {/* {vendorItems.map((item) => <p>{item.itemName}</p>)} */}
                  </>
                )}
              </>
            )}
            <Box sx={bottomBar}>
              <Box>
                <Button variant="outlined" onClick={() => handleClose()}>
                  Cancel
                </Button>
              </Box>
              {orderItems.length > 0 && (
                <>
                  <Box>
                    {/* <Button onClick={() => console.log(orderItems)}>
                      LogMe
                    </Button> */}
                    <Button onClick={() => processRequestQuote()}>
                      Request Quote
                    </Button>
                    <Button>Save POR</Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        ) : (
          <Box sx={style}>
            <h2 id="modal-modal-title">Loading...</h2>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default NewPurchaseOrderModal;
