//test code
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { firestore } from "../firebase";
import { DataGrid } from "@mui/x-data-grid";
import CategoryModal from "./CategoryModal";
import PropertyContext from "../contexts/PropertyContext";
import { useNavigate } from "react-router-dom";
import RoomsSingle from "./RoomsSingle";
import RoomsSingleTimeline from "./RoomsSingleTimeline";
import GenerateDRListPDF from "./GenerateDRListPDF";
import { set } from "date-fns";

function Rooms2({ user }) {
  console.log("Loading rooms");
  const navigate = useNavigate();
  console.log("user: ", user.fullName);
  const { rooms, items, selectedProperty } = useContext(PropertyContext);
  const [manipulatedItems, setManipulatedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [singleRoom, setSingleRoom] = useState(null);
  const [singleRoomTimeline, setSingleRoomTimeline] = useState(false);

  const [selectionOption, setSelectionOption] = useState("");
  const [selectedOption, setSelectedOption] = useState([]);
  const [itemCategories, setItemCategories] = useState([]);
  const [roomsVendor, setRoomsVendor] = useState({});
  const [itemsByCategory, setItemsByCategory] = useState([]);
  const [singleModalOpen, setSingleModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [lastSelectedOption, setLastSelectedOption] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");

  const keysToCheck = [
    "vendorElectricalRepair",
    "vendorPestControl",
    "vendorPlumbingRepair",
    "vendorTubResurface",
    "vendorWindow",
  ];

  // Handles filtering rooms based on the selection and search term
  const handleRoomChange = (e) => {
    setLastSelectedOption(e);
    console.log(e.target.value);
    const option = e.target.value.toLowerCase();
    let filteredRooms = [];

    switch (option) {
      case "all rooms":
        filteredRooms = rooms;
        break;
      case "available rooms":
        filteredRooms = rooms.filter(
          (room) => room.status.toLowerCase() === "up"
        );
        break;
      case "down rooms":
        filteredRooms = rooms.filter(
          (room) => room.status.toLowerCase() === "down"
        );
        break;
      case "vendor needed rooms":
        filteredRooms = rooms.filter(
          (room) =>
            room.status.toLowerCase() === "down" &&
            keysToCheck.some((key) => room[key] && room[key] > 0)
        );
        break;
      case "turnable rooms":
        filteredRooms = rooms.filter(
          (room) =>
            room.status.toLowerCase() === "down" &&
            keysToCheck.every((key) => !room[key] || room[key] <= 0)
        );
        break;
      default:
        filteredRooms = rooms;
        break;
    }

    if (searchTerm && searchTerm.length >= 3) {
      filteredRooms = filteredRooms.filter((room) =>
        room.displayName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      console.log("rooms filtered by search term: ", filteredRooms);
    }

    setSelectedOption(filteredRooms);
    setSelectionOption(e.target.value);
  };

  // Handles updating room data in Firestore
  const handleEdit = async (roomId, editType, data) => {
    console.log(`Edit detected: `, roomId, editType, data);
    if (!roomId) {
      console.error("HandleEdit called with no room number");
      return;
    }

    const roomRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "units",
      roomId
    );

    switch (editType) {
      case "main":
        const updatedData = {
          vendorElectricalRepair: roomsVendor[roomId].electrical,
          vendorTubResurface: roomsVendor[roomId].tubResurface,
          vendorPlumbingRepair: roomsVendor[roomId].plumbing,
          vendorPestControl: roomsVendor[roomId].pestControl,
          vendorWindow: roomsVendor[roomId].window,
        };
        await updateDoc(roomRef, updatedData).then((e) => console.log(e));
        break;
      case "vendor":
        await updateDoc(roomRef, data).then((e) => console.log(e));
        break;
      case "headerdata":
        await updateDoc(roomRef, data).then((e) => console.log(e));
        break;
      default:
        console.error("Edit Error: no option found for: ", editType);
        break;
    }
  };

  // Groups items by their type
  const groupItemsByType = (items) => {
    console.log("groupItemsByType called");
    return items.reduce((acc, item) => {
      if (!acc[item.itemType]) {
        acc[item.itemType] = [];
      }
      acc[item.itemType].push(item);
      return acc;
    }, {});
  };

  // Opens the modal for editing a specific category
  const handleOpenModal = (currentRoom, category) => {
    setModalOpen(true);
    setSelectedItemId(currentRoom);
    setSelectedCategory(category);
  };

  // Renders category button in DataGrid
  const renderCategoryButton = (params, category, itemsByCategory) => {
    const currentRoom = rooms.find((room) => room.displayName === params.id);
    const needsCategory = itemsByCategory[category]?.some(
      (item) => parseInt(currentRoom[item.itemID]) > 0
    );

    return (
      <Button
        sx={{ width: "95%" }}
        variant="contained"
        color={needsCategory ? "error" : "success"}
        onClick={() => handleOpenModal(currentRoom, category)}
      >
        {needsCategory ? "Yes" : "No"}
      </Button>
    );
  };

  // useEffect to handle data fetching and manipulation
  useEffect(() => {
    console.log("useEffect called: Change Noticed");
    const groupedItems = groupItemsByType(items);
    const newItemType = "Vendor Needed";

    if (!groupedItems[newItemType]) {
      groupedItems[newItemType] = [];
    }

    const vendorsList = [
      { itemID: "vendorElectricalRepair", itemName: "Electrical Repair" },
      { itemID: "vendorPestControl", itemName: "Pest Control" },
      { itemID: "vendorPlumbingRepair", itemName: "Plumbing Repair" },
      { itemID: "vendorTubResurface", itemName: "Tub Resurface" },
      { itemID: "vendorWindow", itemName: "Window" },
      { itemID: "vendorSubfloor", itemName: "Subfloor Repair" },
    ];

    vendorsList.forEach((item) => {
      groupedItems[newItemType].push({
        ...item,
        itemType: newItemType,
      });
    });

    setManipulatedItems(groupedItems);
    setItemsByCategory(groupedItems);

    // Check if selectedOption is an empty array
    if (!lastSelectedOption || selectedOption.length === 0) {
      setSelectedOption(rooms);
    } else {
      handleRoomChange(lastSelectedOption);
    }

    setLoading(false);
  }, [items, rooms, searchTerm]);

  // Adds category columns dynamically to DataGrid
  const addCategoryColumns = (categories, existingColumns) => {
    console.log("addCategoryColumns called");
    return [
      ...existingColumns,
      ...categories.map((category) => ({
        field: category,
        headerName: category,
        flex: 1,
        renderCell: (params) =>
          renderCategoryButton(params, category, itemsByCategory),
      })),
    ];
  };

  const statusOptions = ["Occupied", "Down", "Up"];
  const hKOptions = [
    "Refresh",
    "Easy",
    "Medium",
    "Heavy",
    "Very Heavy",
    "Completed",
    "Please select",
  ];
  const mTOptions = [
    "Easy",
    "Medium",
    "Heavy",
    "Very Heavy",
    "Completed",
    "Please select",
  ];

  let columns = [
    {
      field: "displayName",
      headerName: "Room",
      width: 150,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            setSingleRoom(params.row);
            e.preventDefault();
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      editable: true,
      renderCell: (params) => (
        <Select
          value={params.value}
          onChange={(event) =>
            handleEdit(params.id, "headerdata", { status: event.target.value })
          }
          size="small"
          sx={{ width: "100%" }}
        >
          {statusOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: "selectedHousekeepingRequirements",
      headerName: "Housekeeping",
      width: 130,
      editable: true,
      renderCell: (params) => (
        <Select
          value={params.value}
          onChange={(event) =>
            handleEdit(params.id, "headerdata", {
              selectedHousekeepingRequirements: event.target.value,
            })
          }
          size="small"
          sx={{ width: "100%" }}
        >
          {hKOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      field: "selectedMaintenanceRequirements",
      headerName: "Maintenance",
      width: 130,
      editable: true,
      renderCell: (params) => (
        <Select
          value={params.value}
          onChange={(event) =>
            handleEdit(params.id, "headerdata", {
              selectedMaintenanceRequirements: event.target.value,
            })
          }
          size="small"
          sx={{ width: "100%" }}
        >
          {mTOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      ),
    },
  ];

  const uniqueCategories = Object.keys(itemsByCategory);
  const updatedColumns = addCategoryColumns(uniqueCategories, columns);

  const [selectedRows, setSelectedRows] = useState([]);

  // Handler for selection change
  const handleSelectionChange = (selectionModel) => {
    // console.log("Selection model: ", selectionModel);
    // setSelectedRows(selectionModel);
    let selectedRooms = [];
    selectionModel.forEach((selected) => {
      selectedRooms.push(
        selectedOption.find((room) => room.displayName === selected)
      );
    });
    setSelectedRows(selectedRooms);
    console.log(selectedRooms);
  };

  const [statusmessage, setStatusMessage] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);

  const handleGenerateDRListPDF = async (rooms, fileName) => {
    setStatusMessage("Generating DRL PDF...");
    setIsGenerating(true);
    await GenerateDRListPDF(
      selectedProperty,
      rooms,
      setStatusMessage,
      user,
      fileName
    );
    setIsGenerating(false);
  };

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {singleRoomTimeline && (
            <RoomsSingleTimeline
              singleRoom={singleRoom}
              setSingleRoomTimeline={setSingleRoomTimeline}
            />
          )}
          {singleRoom && !singleRoomTimeline && (
            <RoomsSingle
              singleRoom={singleRoom}
              setSingleRoom={setSingleRoom}
              itemsByCategory={itemsByCategory}
              handleEdit={handleEdit}
              handleOpenModal={handleOpenModal}
              statusOptions={statusOptions}
              hKOptions={hKOptions}
              mTOptions={mTOptions}
              uniqueCategories={uniqueCategories}
              setSingleRoomTimeline={setSingleRoomTimeline}
            />
          )}
          <div style={{ display: singleRoom ? "none" : "block" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="room-types-label">Room Types</InputLabel>
                <Select
                  labelId="room-types-label"
                  id="room-types-select"
                  value={selectionOption}
                  label="Room Types"
                  onChange={handleRoomChange}
                >
                  <MenuItem value="all rooms">All Rooms</MenuItem>
                  <MenuItem value="available rooms">Available Rooms</MenuItem>
                  <MenuItem value="down rooms">Down Rooms</MenuItem>
                  <MenuItem value="vendor needed rooms">
                    Vendor Needed Rooms
                  </MenuItem>
                  <MenuItem value="turnable rooms">Turnable Rooms</MenuItem>
                </Select>
                <FormHelperText>Select a room type</FormHelperText>
              </FormControl>
              <TextField
                label="Search Rooms"
                variant="outlined"
                size="small"
                sx={{ m: 1 }}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  handleRoomChange(e);
                }}
              />
              {selectedRows.length > 0 && (
                // <GenerateDRListPDF
                //   selectedProperty={selectedProperty}
                //   rooms={selectedRows}
                //   setStatusMessage={setStatusMessage}
                // />
                <Button
                  onClick={() => {
                    const fileName = prompt("Enter file name");
                    handleGenerateDRListPDF(selectedRows, fileName);
                  }}
                >
                  test generate DRL
                </Button>
              )}
            </div>
            {!loading && selectedOption.length > 0 && (
              <DataGrid
                rows={selectedOption}
                columns={updatedColumns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
                getRowId={(row) => row.displayName}
                // onSelectionModelChange={handleSelectionChange} // Update selected rows
                onRowSelectionModelChange={handleSelectionChange} // Update selected rows
              />
            )}
            {modalOpen && (
              <CategoryModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                category={selectedCategory}
                room={selectedItemId}
                itemsByCategory={itemsByCategory}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Rooms2;

// original code
// import {
//   Button,
//   CircularProgress,
//   FormControl,
//   FormHelperText,
//   InputLabel,
//   MenuItem,
//   Select,
// } from "@mui/material";
// import {
//   collection,
//   doc,
//   onSnapshot,
//   query,
//   updateDoc,
// } from "firebase/firestore";
// import React, { useContext, useEffect, useState } from "react";
// import { firestore } from "../firebase";
// import { DataGrid } from "@mui/x-data-grid";
// import CategoryModal from "./CategoryModal";
// import PropertyContext from "../contexts/PropertyContext";
// import { useNavigate } from "react-router-dom";
// import RoomsSingle from "./RoomsSingle";
// import RoomsSingleTimeline from "./RoomsSingleTimeline";

// function Rooms2() {
//   console.log("Loading rooms");
//   const navigate = useNavigate();
//   const { rooms, items, selectedProperty } = useContext(PropertyContext);
//   const [manipulatedItems, setManipulatedItems] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [singleRoom, setSingleRoom] = useState(null);
//   const [singleRoomTimeline, setSingleRoomTimeline] = useState(false);

//   const [selectionOption, setSelectionOption] = useState("");
//   const [selectedOption, setSelectedOption] = useState([]);
//   const [itemCategories, setItemCategories] = useState([]);
//   const [roomsVendor, setRoomsVendor] = useState({});
//   const [itemsByCategory, setItemsByCategory] = useState([]);
//   const [singleModalOpen, setSingleModalOpen] = useState(false);
//   const [modalOpen, setModalOpen] = useState(false);
//   const [selectedItemId, setSelectedItemId] = useState(null);
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [lastSelectedOption, setLastSelectedOption] = useState(null);

//   const keysToCheck = [
//     "vendorElectricalRepair",
//     "vendorPestControl",
//     "vendorPlumbingRepair",
//     "vendorTubResurface",
//     "vendorWindow",
//   ];

//   // Handles filtering rooms based on the selection
//   const handleRoomChange = (e) => {
//     setLastSelectedOption(e);
//     console.log(e.target.value);
//     const option = e.target.value.toLowerCase();
//     let filteredRooms = [];

//     switch (option) {
//       case "all rooms":
//         filteredRooms = rooms;
//         break;
//       case "available rooms":
//         filteredRooms = rooms.filter(
//           (room) => room.status.toLowerCase() === "up"
//         );
//         break;
//       case "down rooms":
//         filteredRooms = rooms.filter(
//           (room) => room.status.toLowerCase() === "down"
//         );
//         break;
//       case "vendor needed rooms":
//         filteredRooms = rooms.filter(
//           (room) =>
//             room.status.toLowerCase() === "down" &&
//             keysToCheck.some((key) => room[key] && room[key] > 0)
//         );
//         break;
//       case "turnable rooms":
//         filteredRooms = rooms.filter(
//           (room) =>
//             room.status.toLowerCase() === "down" &&
//             keysToCheck.every((key) => !room[key] || room[key] <= 0)
//         );
//         break;
//       default:
//         filteredRooms = rooms;
//         break;
//     }

//     setSelectedOption(filteredRooms);
//     setSelectionOption(e.target.value);
//   };

//   // Handles updating room data in Firestore
//   const handleEdit = async (roomId, editType, data) => {
//     console.log(`Edit detected: `, roomId, editType, data);
//     if (!roomId) {
//       console.error("HandleEdit called with no room number");
//       return;
//     }

//     const roomRef = doc(
//       firestore,
//       "properties",
//       selectedProperty,
//       "units",
//       roomId
//     );

//     switch (editType) {
//       case "main":
//         const updatedData = {
//           vendorElectricalRepair: roomsVendor[roomId].electrical,
//           vendorTubResurface: roomsVendor[roomId].tubResurface,
//           vendorPlumbingRepair: roomsVendor[roomId].plumbing,
//           vendorPestControl: roomsVendor[roomId].pestControl,
//           vendorWindow: roomsVendor[roomId].window,
//         };
//         await updateDoc(roomRef, updatedData).then((e) => console.log(e));
//         break;
//       case "vendor":
//         await updateDoc(roomRef, data).then((e) => console.log(e));
//         break;
//       case "headerdata":
//         await updateDoc(roomRef, data).then((e) => console.log(e));
//         break;
//       default:
//         console.error("Edit Error: no option found for: ", editType);
//         break;
//     }
//   };

//   // Groups items by their type
//   const groupItemsByType = (items) => {
//     console.log("groupItemsByType called");
//     return items.reduce((acc, item) => {
//       if (!acc[item.itemType]) {
//         acc[item.itemType] = [];
//       }
//       acc[item.itemType].push(item);
//       return acc;
//     }, {});
//   };

//   // Opens the modal for editing a specific category
//   const handleOpenModal = (currentRoom, category) => {
//     setModalOpen(true);
//     setSelectedItemId(currentRoom);
//     setSelectedCategory(category);
//   };

//   // Renders category button in DataGrid
//   const renderCategoryButton = (params, category, itemsByCategory) => {
//     // console.log("renderCategoryButton called");
//     const currentRoom = rooms.find((room) => room.displayName === params.id);
//     const needsCategory = itemsByCategory[category]?.some(
//       (item) => parseInt(currentRoom[item.itemID]) > 0
//     );

//     return (
//       <Button
//         sx={{ width: "95%" }}
//         variant="contained"
//         color={needsCategory ? "error" : "success"}
//         onClick={() => handleOpenModal(currentRoom, category)}
//       >
//         {needsCategory ? "Yes" : "No"}
//       </Button>
//     );
//   };

//   // useEffect to handle data fetching and manipulation
//   useEffect(() => {
//     console.log("useEffect called: Change Noticed");
//     const groupedItems = groupItemsByType(items);
//     const newItemType = "Vendor Needed";

//     if (!groupedItems[newItemType]) {
//       groupedItems[newItemType] = [];
//     }

//     const vendorsList = [
//       { itemID: "vendorElectricalRepair", itemName: "Electrical Repair" },
//       { itemID: "vendorPestControl", itemName: "Pest Control" },
//       { itemID: "vendorPlumbingRepair", itemName: "Plumbing Repair" },
//       { itemID: "vendorTubResurface", itemName: "Tub Resurface" },
//       { itemID: "vendorWindow", itemName: "Window" },
//       { itemID: "vendorSubfloor", itemName: "Subfloor Repair" },
//     ];

//     vendorsList.forEach((item) => {
//       groupedItems[newItemType].push({
//         ...item,
//         itemType: newItemType,
//       });
//     });

//     setManipulatedItems(groupedItems);
//     setItemsByCategory(groupedItems);

//     // Check if selectedOption is an empty array
//     if (selectedOption.length === 0) {
//       setSelectedOption(rooms);
//     } else {
//       handleRoomChange(lastSelectedOption);
//     }

//     setLoading(false);
//   }, [items, rooms]);

//   // Adds category columns dynamically to DataGrid
//   const addCategoryColumns = (categories, existingColumns) => {
//     console.log("addCategoryColumns called");
//     return [
//       ...existingColumns,
//       ...categories.map((category) => ({
//         field: category,
//         headerName: category,
//         flex: 1,
//         renderCell: (params) =>
//           renderCategoryButton(params, category, itemsByCategory),
//       })),
//     ];
//   };

//   const statusOptions = ["Occupied", "Down", "Up"];
//   const hKOptions = [
//     "Refresh",
//     "Easy",
//     "Medium",
//     "Heavy",
//     "Very Heavy",
//     "Completed",
//     "Please select",
//   ];
//   const mTOptions = [
//     "Easy",
//     "Medium",
//     "Heavy",
//     "Very Heavy",
//     "Completed",
//     "Please select",
//   ];

//   let columns = [
//     {
//       field: "displayName",
//       headerName: "Room",
//       width: 150,
//       renderCell: (params) => (
//         <div
//           style={{ cursor: "pointer" }}
//           onClick={(e) => {
//             setSingleRoom(params.row);
//             e.preventDefault();
//           }}
//         >
//           {params.value}
//         </div>
//       ),
//     },
//     {
//       field: "status",
//       headerName: "Status",
//       width: 130,
//       editable: true,
//       renderCell: (params) => (
//         <Select
//           value={params.value}
//           onChange={(event) =>
//             handleEdit(params.id, "headerdata", { status: event.target.value })
//           }
//           size="small"
//           sx={{ width: "100%" }}
//         >
//           {statusOptions.map((option) => (
//             <MenuItem key={option} value={option}>
//               {option}
//             </MenuItem>
//           ))}
//         </Select>
//       ),
//     },
//     {
//       field: "selectedHousekeepingRequirements",
//       headerName: "Housekeeping",
//       width: 130,
//       editable: true,
//       renderCell: (params) => (
//         <Select
//           value={params.value}
//           onChange={(event) =>
//             handleEdit(params.id, "headerdata", {
//               selectedHousekeepingRequirements: event.target.value,
//             })
//           }
//           size="small"
//           sx={{ width: "100%" }}
//         >
//           {hKOptions.map((option) => (
//             <MenuItem key={option} value={option}>
//               {option}
//             </MenuItem>
//           ))}
//         </Select>
//       ),
//     },
//     {
//       field: "selectedMaintenanceRequirements",
//       headerName: "Maintenance",
//       width: 130,
//       editable: true,
//       renderCell: (params) => (
//         <Select
//           value={params.value}
//           onChange={(event) =>
//             handleEdit(params.id, "headerdata", {
//               selectedMaintenanceRequirements: event.target.value,
//             })
//           }
//           size="small"
//           sx={{ width: "100%" }}
//         >
//           {mTOptions.map((option) => (
//             <MenuItem key={option} value={option}>
//               {option}
//             </MenuItem>
//           ))}
//         </Select>
//       ),
//     },
//   ];

//   const uniqueCategories = Object.keys(itemsByCategory);
//   const updatedColumns = addCategoryColumns(uniqueCategories, columns);

//   return (
//     <div>
//       {loading ? (
//         <CircularProgress />
//       ) : (
//         <>
//           {singleRoomTimeline && (
//             <RoomsSingleTimeline
//               singleRoom={singleRoom}
//               setSingleRoomTimeline={setSingleRoomTimeline}
//             />
//           )}
//           {singleRoom && !singleRoomTimeline && (
//             <RoomsSingle
//               singleRoom={singleRoom}
//               setSingleRoom={setSingleRoom}
//               itemsByCategory={itemsByCategory}
//               handleEdit={handleEdit}
//               handleOpenModal={handleOpenModal}
//               statusOptions={statusOptions}
//               hKOptions={hKOptions}
//               mTOptions={mTOptions}
//               uniqueCategories={uniqueCategories}
//               setSingleRoomTimeline={setSingleRoomTimeline}
//             />
//           )}
//           <div style={{ display: singleRoom ? "none" : "block" }}>
//             <FormControl sx={{ m: 1, minWidth: 120 }}>
//               <InputLabel id="room-types-label">Room Types</InputLabel>
//               <Select
//                 labelId="room-types-label"
//                 id="room-types-select"
//                 value={selectionOption}
//                 label="Room Types"
//                 onChange={handleRoomChange}
//               >
//                 <MenuItem value="all rooms">All Rooms</MenuItem>
//                 <MenuItem value="available rooms">Available Rooms</MenuItem>
//                 <MenuItem value="down rooms">Down Rooms</MenuItem>
//                 <MenuItem value="vendor needed rooms">
//                   Vendor Needed Rooms
//                 </MenuItem>
//                 <MenuItem value="turnable rooms">Turnable Rooms</MenuItem>
//               </Select>
//               <FormHelperText>Select a room type</FormHelperText>
//             </FormControl>
//             {!loading && selectedOption.length > 0 && (
//               <DataGrid
//                 rows={selectedOption}
//                 columns={updatedColumns}
//                 pageSize={5}
//                 rowsPerPageOptions={[5]}
//                 checkboxSelection
//                 disableSelectionOnClick
//                 getRowId={(row) => row.displayName}
//               />
//             )}
//             {modalOpen && (
//               <CategoryModal
//                 open={modalOpen}
//                 onClose={() => setModalOpen(false)}
//                 category={selectedCategory}
//                 room={selectedItemId}
//                 itemsByCategory={itemsByCategory}
//               />
//             )}
//           </div>
//         </>
//       )}
//     </div>
//   );
// }

// export default Rooms2;
