import React, { useState, useEffect, useContext } from "react";
import {
  collection,
  onSnapshot,
  query,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  addDoc,
  getDocs,
} from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { auth, firestore } from "../firebase";
import {
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Modal,
  Divider,
  List,
  ListItem,
  ListSubheader,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import AdapterDateFns from "@date-io/date-fns";
import PropertyContext from "../contexts/PropertyContext";

const Dispatch = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [requests, setRequests] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedEmployeeDispatch, setSelectedEmployeeDispatch] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedDispatchType, setSelectedDispatchType] = useState("");
  const [dispatchOption, setDispatchOption] = useState("");
  const [dispatchItemStartTime, setDispatchItemStartTime] = useState();
  const [dispatchItemEndTime, setDispatchItemEndTime] = useState();

  const dispatchItemType = [
    "Porter",
    "Room Assignment",
    "Work Orders",
    "Lunch",
    "Break",
  ];
  const dispatchPorterAreas = [1, 2, 3, 4];
  const workOrdersType = ["Assign Specific", "Check for New Orders"];
  const lunchOptions = [30, 60];

  const { selectedProperty } = useContext(PropertyContext);

  useEffect(() => {
    setIsLoading(true);
    const roomsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "units"
    );
    const employeesRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "Staff"
    );

    const q = query(roomsRef);
    const q3 = query(employeesRef);

    const roomsTurnable = (roomsArray) => {
      const turnableRooms = [];
      const vendorNeededRooms = [];
      roomsArray.forEach((room) => {
        const keysToCheck = [
          "vendorElectricalRepair",
          "vendorPestControl",
          "vendorPlumbingRepair",
          "vendorTubResurface",
          "vendorWindow",
        ];

        const isTurnable = keysToCheck.every(
          (key) => !room[key] || room[key] <= 0
        );

        if (isTurnable) {
          turnableRooms.push(room);
        } else {
          vendorNeededRooms.push(room);
        }
      });
      setRooms(turnableRooms);
    };

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const roomsArray = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((room) => room.status === "Down");
      //setRooms(roomsArray);
      roomsTurnable(roomsArray);
      //console.log(roomsArray)

      const uns = onSnapshot(q3, (sna) => {
        const employeeList = sna.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEmployees(employeeList);
        console.log(employeeList);
        setIsLoading(false);
      });
    });

    return unsubscribe; // Unsubscribe from the listener when the component unmounts
  }, []);

  const markAsCompleted = async (orderId, uuid) => {
    console.log(`marked as completed: `, orderId, uuid);

    const orderRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "workOrders",
      orderId
    );
    const userRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "Staff",
      uuid
    );
    //close out order
    const updatedWODetails = {
      completed: true,
    };
    await updateDoc(orderRef, updatedWODetails);

    //add +1 to user's work orders
    const currentWOs = Number(
      employees.find((member) => member.id === uuid).workOrders
    );
    const updatedUserProfileData = {
      turns: currentWOs + 1,
    };
    await updateDoc(userRef, updatedUserProfileData);
  };

  const handleModal = () => setOpenModal(!openModal);
  const handleAddToDispatch = async () => {
    const formatDate = Number(selectedDate).toString(); // error control
    const userDispatchDateRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "dispatches",
      formatDate,
      selectedEmployee
    );
    if (
      dispatchItemStartTime === undefined ||
      dispatchItemEndTime === undefined
    ) {
      console.error(`times not found`);
      return;
    }

    if (selectedDispatchType === "Room Assignment") {
      const dispatchData = {
        isRoom: true,
        room: dispatchOption,
        startTime: Number(dispatchItemStartTime.$d).toString(),
        endTime: Number(dispatchItemEndTime.$d).toString(),
      };
      console.log(dispatchData);
      await addDoc(userDispatchDateRef, dispatchData);
    } else {
      const dispatchData = {
        isRoom: false,
        primary: selectedDispatchType,
        secondary: dispatchOption,
        startTime: Number(dispatchItemStartTime.$d).toString(),
        endTime: Number(dispatchItemEndTime.$d).toString(),
      };
      console.log(dispatchData);
      await addDoc(userDispatchDateRef, dispatchData);
    }

    handleModal();
    getDispatches();
  };
  const getDispatches = async () => {
    if (selectedDate === null || selectedEmployee === "") {
      return;
    }
    console.log(`getting dispatches for: `, selectedEmployee, selectedDate);
    const formatDate = Number(selectedDate).toString(); // error control
    // setSelectedEmployeeDispatch([])

    const dispatchDateRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "dispatches",
      formatDate
    );
    const userDispatchDateRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "dispatches",
      formatDate,
      selectedEmployee
    );

    const dispatchDate = await getDoc(dispatchDateRef);
    if (!dispatchDate.exists()) {
      const init = {
        init: true,
      };
      setSelectedEmployeeDispatch([]);
      await setDoc(dispatchDateRef, init);
    }
    const q = query(userDispatchDateRef);
    const userDispatchDocs = await getDocs(q);

    if (userDispatchDocs.empty) {
      setSelectedEmployeeDispatch([]);
      return;
    } else {
      const dailyDispatch = userDispatchDocs.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSelectedEmployeeDispatch(dailyDispatch);
    }
  };

  return (
    <Box p={2}>
      {isLoading ? (
        <React.Fragment>
          <Typography>Im Loading!</Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {rooms.length > 0 && (
            <React.Fragment>
              <Box>
                <Typography>Dispatch</Typography>
              </Box>
              <Box display="flex" flexDirection="row">
                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="space-between"
                >
                  <Box minWidth="50%">
                    <Select
                      labelId="status-select-label"
                      id="status-select"
                      value={selectedEmployee}
                      onChange={(event) => {
                        setSelectedEmployee(event.target.value);
                        getDispatches();
                      }}
                      size="small"
                      sx={{ width: "100%" }}
                    >
                      {employees.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        //value={dayjs(selectedDate)}
                        onChange={(newDate) => {
                          setSelectedDate(newDate.$d.setUTCHours(6, 0, 0, 0));
                          console.log(newDate.$d.setUTCHours(6, 0, 0, 0));
                          getDispatches();
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                </Box>
              </Box>

              {/* //day planner broken down by hours where when you click on a time it opens modal */}
              {/* //modal should have options for task type (room turn, porter, lunch) */}
              {/* //room turn should display selection for room */}
              {/* //porter should display selection for area (area 1, 2, 3, 4) */}
              {/* //lunch should display selection for Lunch time  */}
              {/* //all modals should have a start time and end time selection */}
              {/* //submit button */}
              <Box>
                {selectedEmployee === "" ? (
                  <React.Fragment>
                    <Typography>Please Select An Employee</Typography>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {selectedDate !== null ? (
                      <React.Fragment>
                        <Box marginTop="5%">
                          {selectedEmployeeDispatch.length > 0 ? (
                            <React.Fragment>
                              <List
                                subheader={
                                  <ListSubheader>
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      width="100%"
                                      justifyContent="space-between"
                                    >
                                      <Box display="flex" flexDirection="row">
                                        <Box>
                                          <Typography>Start Time</Typography>
                                        </Box>
                                        <Box>
                                          {" "}
                                          <Typography>-</Typography>
                                        </Box>
                                        <Box>
                                          <Typography>End Time</Typography>
                                        </Box>
                                      </Box>
                                      <Box>
                                        <Typography>
                                          Task Description
                                        </Typography>
                                      </Box>
                                      <Box>
                                        <Typography>
                                          Remove From Dispatch
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </ListSubheader>
                                }
                              >
                                {selectedEmployeeDispatch.map((item) => {
                                  if (item.isRoom) {
                                    return (
                                      <React.Fragment>
                                        <ListItem>
                                          <Box
                                            display="flex"
                                            flexDirection="row"
                                            width="100%"
                                            justifyContent="space-between"
                                          >
                                            <Box
                                              display="flex"
                                              flexDirection="row"
                                            >
                                              <Box>
                                                <Typography>
                                                  {new Date(
                                                    Number(item.startTime)
                                                  ).toLocaleTimeString()}
                                                </Typography>
                                              </Box>
                                              <Box>
                                                {" "}
                                                <Typography>-</Typography>
                                              </Box>
                                              <Box>
                                                <Typography>
                                                  {new Date(
                                                    Number(item.endTime)
                                                  ).toLocaleTimeString()}
                                                </Typography>
                                              </Box>
                                            </Box>
                                            <Box>
                                              <Typography>
                                                {
                                                  employees.find(
                                                    (member) =>
                                                      member.id ===
                                                      selectedEmployee
                                                  ).position
                                                }{" "}
                                                ready unit: {item.room}
                                              </Typography>
                                            </Box>
                                            <Box>
                                              {/* mark for cancel */}
                                              <Button>Remove</Button>
                                            </Box>
                                          </Box>
                                        </ListItem>
                                        <Divider component="li" />
                                      </React.Fragment>
                                    );
                                  } else {
                                    if (item.primary === "Work Orders") {
                                      //return work orders
                                      return (
                                        <React.Fragment>
                                          <ListItem>
                                            <Box
                                              display="flex"
                                              flexDirection="row"
                                              width="100%"
                                              justifyContent="space-between"
                                            >
                                              <Box
                                                display="flex"
                                                flexDirection="row"
                                              >
                                                <Box>
                                                  <Typography>
                                                    {new Date(
                                                      Number(item.startTime)
                                                    ).toLocaleTimeString()}
                                                  </Typography>
                                                </Box>
                                                <Box>
                                                  {" "}
                                                  <Typography>-</Typography>
                                                </Box>
                                                <Box>
                                                  <Typography>
                                                    {new Date(
                                                      Number(item.endTime)
                                                    ).toLocaleTimeString()}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box>
                                                <Typography>
                                                  Complete Work Orders
                                                </Typography>
                                              </Box>
                                              <Box>
                                                {/* mark for cancel */}
                                                <Button>Remove</Button>
                                              </Box>
                                            </Box>
                                          </ListItem>
                                          <Divider component="li" />
                                        </React.Fragment>
                                      );
                                    }
                                    if (item.primary === "Break") {
                                      //return work orders
                                      return (
                                        <React.Fragment>
                                          <ListItem>
                                            <Box
                                              display="flex"
                                              flexDirection="row"
                                              width="100%"
                                              justifyContent="space-between"
                                            >
                                              <Box
                                                display="flex"
                                                flexDirection="row"
                                              >
                                                <Box>
                                                  <Typography>
                                                    {new Date(
                                                      Number(item.startTime)
                                                    ).toLocaleTimeString()}
                                                  </Typography>
                                                </Box>
                                                <Box>
                                                  {" "}
                                                  <Typography>-</Typography>
                                                </Box>
                                                <Box>
                                                  <Typography>
                                                    {new Date(
                                                      Number(item.endTime)
                                                    ).toLocaleTimeString()}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box>
                                                <Typography>
                                                  Break Time
                                                </Typography>
                                              </Box>
                                              <Box>
                                                {/* mark for cancel */}
                                                <Button>Remove</Button>
                                              </Box>
                                            </Box>
                                          </ListItem>
                                          <Divider component="li" />
                                        </React.Fragment>
                                      );
                                    }
                                    if (item.primary === "Lunch") {
                                      //return work orders
                                      return (
                                        <React.Fragment>
                                          <ListItem>
                                            <Box
                                              display="flex"
                                              flexDirection="row"
                                              width="100%"
                                              justifyContent="space-between"
                                            >
                                              <Box
                                                display="flex"
                                                flexDirection="row"
                                              >
                                                <Box>
                                                  <Typography>
                                                    {new Date(
                                                      Number(item.startTime)
                                                    ).toLocaleTimeString()}
                                                  </Typography>
                                                </Box>
                                                <Box>
                                                  {" "}
                                                  <Typography>-</Typography>
                                                </Box>
                                                <Box>
                                                  <Typography>
                                                    {new Date(
                                                      Number(item.endTime)
                                                    ).toLocaleTimeString()}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box>
                                                <Typography>
                                                  Lunch Time
                                                </Typography>
                                              </Box>
                                              <Box>
                                                {/* mark for cancel */}
                                                <Button>Remove</Button>
                                              </Box>
                                            </Box>
                                          </ListItem>
                                          <Divider component="li" />
                                        </React.Fragment>
                                      );
                                    }
                                    if (item.primary === "Porter") {
                                      //return work orders
                                      return (
                                        <React.Fragment>
                                          <ListItem>
                                            <Box
                                              display="flex"
                                              flexDirection="row"
                                              width="100%"
                                              justifyContent="space-between"
                                            >
                                              <Box
                                                display="flex"
                                                flexDirection="row"
                                              >
                                                <Box>
                                                  <Typography>
                                                    {new Date(
                                                      Number(item.startTime)
                                                    ).toLocaleTimeString()}
                                                  </Typography>
                                                </Box>
                                                <Box>
                                                  {" "}
                                                  <Typography>-</Typography>
                                                </Box>
                                                <Box>
                                                  <Typography>
                                                    {new Date(
                                                      Number(item.endTime)
                                                    ).toLocaleTimeString()}
                                                  </Typography>
                                                </Box>
                                              </Box>
                                              <Box>
                                                <Typography>
                                                  Porter Area #{item.secondary}
                                                </Typography>
                                              </Box>
                                              <Box>
                                                {/* mark for cancel */}
                                                <Button>Remove</Button>
                                              </Box>
                                            </Box>
                                          </ListItem>
                                          <Divider component="li" />
                                        </React.Fragment>
                                      );
                                    }
                                  }
                                })}
                              </List>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Typography>
                                No Dispatch Found for Selected date
                              </Typography>
                              <Typography>
                                You can add to it if you would like
                              </Typography>
                            </React.Fragment>
                          )}

                          <Box display="flex" justifyContent="center">
                            <Button onClick={handleModal}>
                              Add To Dispatch
                            </Button>
                          </Box>
                        </Box>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Typography>
                          Please Select A Date for Dispatch
                        </Typography>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                {/* {
                                    rooms.map((room) => {
                                        return (
                                            <Typography>{room.id}</Typography>
                                        )
                                    })
                                } */}
              </Box>
              <Modal
                open={openModal}
                onClose={handleModal}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Box
                  p={2}
                  bgcolor="background.paper"
                  sx={{
                    outline: "none",
                    borderRadius: "4px",
                    width: "50%",
                    height: "60%",
                    justifyContent: "center",
                  }}
                >
                  <Typography>Add to Dispatch</Typography>
                  <Box
                    display="flex"
                    width="100%"
                    height="40%"
                    paddingTop="20px"
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Box width="40%">
                        <Select
                          label="Select Type"
                          value=""
                          onChange={(e) =>
                            setSelectedDispatchType(e.target.value)
                          }
                        >
                          {dispatchItemType.map((i) => (
                            <MenuItem value={i}>{i}</MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box display="flex" flexDirection="row" width="60%">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label="Start Time"
                            onAccept={(value) =>
                              setDispatchItemStartTime(value)
                            }
                          />
                          <Typography>-</Typography>
                          <TimePicker
                            label="End Time"
                            onAccept={(value) => setDispatchItemEndTime(value)}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Box>
                    {selectedDispatchType === "Porter" && (
                      <Select
                        label="Please Select Area"
                        onChange={(e) => setDispatchOption(e.target.value)}
                      >
                        {dispatchPorterAreas.map((i) => (
                          <MenuItem value={i}>
                            <Typography>Area {i}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                    {selectedDispatchType === "Room Assignment" && (
                      <Select
                        label="Please Select Room"
                        value={dispatchOption}
                        onChange={(e) => setDispatchOption(e.target.value)}
                      >
                        {rooms.map((i) => (
                          <MenuItem value={i.id}>
                            <Typography>
                              {i.displayName} -{" "}
                              {i.selectedMaintenanceRequirements}
                            </Typography>
                          </MenuItem>
                        ))}
                        {/* {
                                                employees.find(member => member.id === selectedEmployee).position === 'maintenance' ? (
                                                    <React.Fragment>
                                                    {
                                                        rooms.map((i)=> (
                                                            <MenuItem value={i.id}>
                                                                <Typography>{i.displayName}  - {i.selectedMaintenanceRequirements}</Typography>
                                                            </MenuItem>
                                                        ))

                                                    }
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                    {
                                                        rooms.map((i)=> (
                                                            <MenuItem value={i.id}>
                                                                <Typography>{i.displayName}  {i.selectedHousekeepingRequirements}</Typography>
                                                            </MenuItem>
                                                        ))

                                                    }
                                                    </React.Fragment>
                                                )
                                            } */}
                      </Select>
                    )}
                  </Box>
                  <Box height="40%"></Box>
                  <Box
                    sx={{
                      bottom: 16,
                      left: 16,
                      right: 16,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button onClick={handleModal} sx={{ mt: 2 }}>
                      Close
                    </Button>
                    <Button
                      onClick={handleAddToDispatch}
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};

export default Dispatch;
