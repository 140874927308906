import React, { useState, useEffect, useContext } from "react";
import {
  collection,
  onSnapshot,
  query,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  addDoc,
  where,
} from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { auth, firestore } from "../firebase";
import {
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Modal,
  Divider,
} from "@mui/material";
import ControlledPORModal from "./ControlledPORModal";
import PropertyContext from "../contexts/PropertyContext";

const CurrentPORs = ({ userInfo }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [requests, setRequests] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const { selectedProperty } = useContext(PropertyContext);

  const onModalClose = () => {
    console.log("modal closed");
    setModalOpen(false);
    setModalData(null);
  };

  useEffect(() => {
    setIsLoading(true);
    const currentPORsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "PORs"
    );

    const q = query(currentPORsRef, where("isOpen", "==", true));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const requestsArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRequests(requestsArray);
      console.log(".....");
      console.log(requestsArray);
      setIsLoading(false);
    });

    return unsubscribe; // Unsubscribe from the listener when the component unmounts
  }, []);

  const markAsCompleted = async (orderId, uuid) => {
    console.log(`marked as completed: `, orderId, uuid);
  };

  const newDateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const columns = [
    {
      field: "date",
      headerName: "Updated Date",
      width: 70,
      editable: false,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      width: 70,
      editable: false,
      flex: 1,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      width: 130,
      editable: false,
      flex: 1,
    },
    {
      field: "items",
      headerName: "Items",
      width: 70,
      editable: false,
      flex: 1,
      renderCell: (params) => (
        <Typography>{params.value ? params.value.length : 0}</Typography>
      ),
    },
    {
      field: "expectedTotal",
      headerName: "Total",
      width: 70,
      editable: false,
      flex: 1,
      renderCell: (params) => (
        <Typography>${Number(params.value).toFixed(2)}</Typography>
      ),
    },

    {
      field: "completed",
      headerName: "Mark Complete",
      width: 130,
      editable: true,
      renderCell: (params) => (
        <Button
          onClick={() =>
            markAsCompleted(params.id, params.row.markedCompleteBy)
          }
        >
          Approve
        </Button>
      ),
    },
  ];

  return (
    <Box p={2}>
      {isLoading ? (
        <React.Fragment>
          <Typography>Im Loading!</Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {requests.length > 0 ? (
            <React.Fragment>
              <Typography>PORs and CCURs</Typography>
              <DataGrid
                rows={requests}
                // getRowId={(row) => row.roomId}
                columns={columns}
                // onCellEditCommit={}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                onRowClick={(row) => {
                  console.log(row);
                  setModalData(row.row);
                  setModalOpen(true);
                }}
              />
              <ControlledPORModal
                isOpen={modalOpen}
                onClose={onModalClose}
                data={modalData}
                userInfo={userInfo}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography>No Past PORs</Typography>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};

export default CurrentPORs;
