import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  QuerySnapshot,
  addDoc,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import { firestore } from "../firebase";
import { DataGrid } from "@mui/x-data-grid";
import { AddCircle } from "@mui/icons-material";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import PropertyContext from "../contexts/PropertyContext";

const SupportGLCodeModal = ({
  isOpen,
  onClose,
  orderTotals,
  propertySalesTax,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const bottomBar = {
    width: "100%",
    mt: "2rem",
    display: "flex", // Ensure it's a flex container
    flexDirection: "row-reverse",
  };

  const handleClose = () => onClose();

  const columns = [
    { field: "id", headerName: "GL Code", flex: 1, editable: false },
    {
      field: "totalCost",
      headerName: "Order Subtotal",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return Number(params.value).toFixed(2);
      },
    },
    {
      field: "tax",
      headerName: "Taxes on Order",
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return Number(
          Number(params.row.totalCost) * Number(propertySalesTax)
        ).toFixed(2);
      },
    },
    {
      field: "total",
      headerName: "Total",
      flex: 1, // Increased width for better visibility
      editable: false,
      renderCell: (params) => {
        // Ensure the values are numbers
        let totalCost = Number(params.row.totalCost);
        let salesTax = Number(propertySalesTax); // Adjust based on where propertySalesTax comes from

        // Calculate the total including sales tax
        let totalWithTax = totalCost * salesTax + totalCost;

        return totalWithTax.toFixed(2);
      },
    },
  ];

  console.log(isOpen);
  console.log(onClose);
  console.log(orderTotals);
  console.log(propertySalesTax);

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box>
          <Typography>Please add below to your declining budget</Typography>
        </Box>
        <DataGrid
          rows={orderTotals}
          columns={columns}
          disableRowSelectionOnClick
          hideFooter
        />
        <Box sx={bottomBar}>
          <Button onClick={handleClose}>Complete</Button>
        </Box>
      </Box>
    </Modal>
  );
};

const ControlledPORModal = ({ isOpen, onClose, data, userInfo }) => {
  const [vendor, setVendor] = useState("");
  const [vendors, setVendors] = useState([]);
  const [vendorItems, setVendorItems] = useState([]);
  const [addItem, setAddItem] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [activeItem, setActiveItem] = useState(null);
  const [orderItems, setOrderItems] = useState([]);

  const { selectedProperty } = useContext(PropertyContext);

  const handleClose = () => {
    //cleanup
    setVendor("");
    setVendorItems([]);
    setAddItem(false);
    setInputValue("");
    setOrderItems([]);
    setActiveItem(null);
    onClose(false);
  };

  // Style for the modal
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const styleActive = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const bottomBar = {
    width: "100%",
    mt: "2rem",
    display: "flex", // Ensure it's a flex container
    flexDirection: "row",
    justifyContent: "space-between",
  };

  //set support modal info
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [supportModalTotals, setSupportModalTotals] = useState([]);

  const supportModalOnClose = () => {
    setSupportModalOpen(false);
  };
  console.log(userInfo);
  const supportModalSalesTax = userInfo.activeProperty.propertySalesTax;

  useEffect(() => {
    //get list of vendors
    const vendorsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "Vendors"
    );
    const vendorsQuery = query(vendorsRef);

    const unsubscribeToVendors = onSnapshot(vendorsQuery, (snapshot) => {
      const vendorsArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVendors(vendorsArray);
    });
  }, []);

  useEffect(() => {
    if (vendor === "") return;

    const itemsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "items"
    );
    const itemsQuery = query(itemsRef, where("vendor", "==", vendor.name));

    const unsubscribeToItems = onSnapshot(itemsQuery, (snapshot) => {
      const itemsArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(itemsArray);
      setVendorItems(itemsArray);
    });
  }, [vendor]);

  useEffect(() => {
    if (!data || data == null) return;
    console.log("DATA DETECTED");
    console.log(data);
    setOrderItems(data.items);
    // Find the vendor whose name matches `data.vendor`
    const foundVendor = vendors.find((v) => v.name === data.vendor);
    if (foundVendor) {
      setVendor(foundVendor);
      console.log("found vendor");
    } else {
      setVendor({ name: data.vendor, porType: data.porType });
      console.log("didnt find vendor");
    }
  }, [data]);

  const getLocalDateString = () => {
    const today = new Date();
    const localDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );
    return localDate.toISOString().split("T")[0];
  };

  const processRequestQuote = () => {
    const totalSum = orderItems.reduce((sum, item) => {
      return sum + item.itemCost * item.count;
    }, 0);

    const data = {
      isOpen: true,
      status: "Quote Requested",
      vendor: vendor.name,
      porType: vendor.porType,
      items: orderItems,
      expectedTotal: totalSum,
      date: getLocalDateString(),
    };

    const porsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "PORs"
    );
    // addDoc(porsRef, data); ##### DEBUG

    generateRequestQuote();
  };

  const generateRequestQuote = () => {
    if (orderItems.length == 0) return alert("please add at least one item");
    const emailTo = vendor.contactEmail;
    const subjectLine = encodeURIComponent("Requesting Quote");
    let body = "";
    if (vendor.contactName && vendor.contactName !== "") {
      body = body + `Hello ${vendor.contactName},\n\n\n\n`;
    }

    body = body + `Please provide a quote for the following items: \n\n`;

    orderItems.forEach((item) => {
      body = body + `${item.count}   ${item.itemSKU}   ${item.itemName}`;
      if (item.count > 1) {
        body = body + `s\n`;
      } else {
        body = body + `\n`;
      }
    });

    body = encodeURIComponent(body);

    const mailto = `mailto:${emailTo}?subject=${subjectLine}&body=${body}`;
    console.log(`mailto: `, mailto);
    window.location.href = mailto;
  };

  const handleEditCommit = (newRow, oldRow) => {
    // Create a new array with the updated row
    const updatedRows = orderItems.map((row) => {
      if (row.id === newRow.id) {
        return newRow; // Use the updated row data
      }
      return row; // Keep the existing row data for other rows
    });

    setOrderItems(updatedRows); // Update the state with the new array
    return newRow; // Return the updated row as required by processRowUpdate
  };

  const generatePOR = async () => {
    let results = {};

    orderItems.forEach((item) => {
      // Ensure count and itemCost are numbers
      let count = parseInt(item.count, 10);
      let itemCost = parseFloat(item.itemCost);

      // Calculate total cost for this item
      let totalCost = count * itemCost;

      // Check if item GL Code exists in results
      if (results[item.itemGLCode]) {
        // If yes, update the total cost
        results[item.itemGLCode] += totalCost;
      } else {
        // If no, add the GL code with the total cost
        results[item.itemGLCode] = totalCost;
      }
    });

    // If you need the results in an array format
    let finalArray = Object.keys(results).map((glCode) => ({
      id: glCode,
      totalCost: results[glCode],
    }));

    setSupportModalTotals(finalArray);
    setSupportModalOpen(true);
    // return null; /////################### null out
    //helper functions
    function applyFillToRange(worksheet, startCell, endCell, fillColor) {
      // Split the start and end cells into their components
      const [startColumnLetter, startRow] = startCell.match(/[a-zA-Z]+|\d+/g);
      const [endColumnLetter, endRow] = endCell.match(/[a-zA-Z]+|\d+/g);

      // Convert column letters to numbers
      const startColumn = columnLetterToNumber(startColumnLetter);
      const endColumn = columnLetterToNumber(endColumnLetter);

      // Apply fill color to each cell in the range
      for (let row = parseInt(startRow); row <= parseInt(endRow); row++) {
        for (let col = startColumn; col <= endColumn; col++) {
          let cell = worksheet.getRow(row).getCell(col);
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: fillColor },
          };
        }
      }
    }

    //convert column letters to numbers for easy adjustments
    function columnLetterToNumber(columnLetter) {
      let columnNumber = 0;
      let length = columnLetter.length;
      for (let i = 0; i < length; i++) {
        columnNumber = columnNumber * 26 + (columnLetter.charCodeAt(i) - 64);
      }
      return columnNumber;
    }

    // Function to add a thin border around a specified range
    function splitLettersAndNumbers(str) {
      return str.match(/[a-zA-Z]+|\d+/g);
    }
    function addBorderLine(
      worksheet,
      startCell,
      endCell,
      borderSide,
      borderStyle
    ) {
      // Split the start and end cells into their components
      const [startColumnLetter, startRow] = startCell.match(/[a-zA-Z]+|\d+/g);
      const [endColumnLetter, endRow] = endCell.match(/[a-zA-Z]+|\d+/g);

      // Convert column letters to numbers
      const startColumn = columnLetterToNumber(startColumnLetter);
      const endColumn = columnLetterToNumber(endColumnLetter);

      // Define the border based on the style and side
      const border = {};
      border[borderSide] = { style: borderStyle };

      // Apply the border to each cell in the range
      for (let row = parseInt(startRow); row <= parseInt(endRow); row++) {
        for (let col = startColumn; col <= endColumn; col++) {
          let cell = worksheet.getRow(row).getCell(col);
          cell.border = {
            ...cell.border, // Preserve existing borders
            ...border,
          };
        }
      }
    }
    function applyRangeBorder(worksheet, startCell, endCell, borderStyle) {
      // Function to convert a column letter to a number (e.g., 'A' -> 1, 'B' -> 2)

      // Split the start and end cells into their components
      const [startColumnLetter, startRow] = splitLettersAndNumbers(startCell);
      const [endColumnLetter, endRow] = splitLettersAndNumbers(endCell);

      // Convert column letters to numbers
      const startColumn = columnLetterToNumber(startColumnLetter);
      const endColumn = columnLetterToNumber(endColumnLetter);

      // Define the border styles based on borderStyle parameter
      const border = {
        top: { style: borderStyle },
        bottom: { style: borderStyle },
        left: { style: borderStyle },
        right: { style: borderStyle },
      };

      // Apply borders to the corners and edges of the range
      for (let row = parseInt(startRow); row <= parseInt(endRow); row++) {
        for (let col = startColumn; col <= endColumn; col++) {
          let cell = worksheet.getRow(row).getCell(col);

          // Apply the borders based on the position of the cell
          let cellBorder = {};
          if (row === parseInt(startRow)) cellBorder.top = border.top;
          if (row === parseInt(endRow)) cellBorder.bottom = border.bottom;
          if (col === startColumn) cellBorder.left = border.left;
          if (col === endColumn) cellBorder.right = border.right;

          // Check if cellBorder is empty
          //   if (Object.keys(cellBorder).length === 0) {
          //     console.log("empty cell Skipped");
          //     return;
          //   }

          //apply border
          cell.border = cellBorder;
        }
      }
    }
    const today = new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(`${vendor.name}`);

    //change the zoom setting to be default for TSG
    worksheet.views = [
      { zoomScale: 60 }, // Zoom set to 60%
    ];
    workbook.creator = "Greg Gleason";

    if (vendor.porType === "CCUR") {
      if (String(vendor.name).toLocaleLowerCase() == "best buy") {
        //change column widths, cell heights, and merges to default ccur widths
        const columnWidths = {
          A: 1.43, //a
          B: 8.29, //b
          C: 13.86, //c
          D: 8.29, //d
          E: 8.29, //e
          F: 1.86, //f
          G: 8.29, //g
          H: 13.14, //h
          I: 1.86, //i
          J: 15.43, //j
          K: 11, //k
          L: 11.86, //l
          M: 10.43, //m
          N: 10, //n
          O: 20.71, //o
          P: 8.29, //p
          Q: 21.43, //q
          R: 1.86, //r
          S: 8.29, //s
          T: 1.86, //t
          U: 8.29, //u
          V: 5.71, //v
          W: 14.57, //w
          X: 3.57, //x
          Y: 16.43, //y
          Z: 7.43, //z
          AA: 8.29, //aa
        };
        const rowHeights = {
          1: 16.5,
          2: 17.25,
          3: 28.5,
          4: 17.25,
          5: 17.25,
          6: 17.25,
          7: 17.25,
          8: 17.25,
          9: 36.0,
          10: 26.25,
          11: 76.5,
          12: 39.0,
          13: 62.25,
          14: 54.0,
          15: 37.5,
          16: 57.75,
          17: 41.25,
          18: 20.25,
          19: 20.25,
          20: 20.25,
          21: 20.25,
          22: 20.25,
          23: 11.25,
          24: 21.0,
          25: 3.75,
          26: 22.5,
          27: 9.75,
          28: 21.0,
          29: 21.0,
          30: 21.0,
          31: 21.0,
          32: 21.0,
          33: 21.0,
          34: 21.0,
          35: 21.0,
          36: 21.0,
          37: 21.0,
          38: 21.0,
          39: 21.0,
          40: 21.0,
          41: 16.5,
        };
        const mergeRanges = [
          //for corperate use area
          { start: "B2", end: "C2" },
          { start: "B3", end: "C3" },
          { start: "B4", end: "C4" },
          { start: "B5", end: "C5" },
          { start: "B6", end: "C6" },
          { start: "B7", end: "C7" },
          { start: "I4", end: "I8" },
          //header area
          { start: "J3", end: "T3" },
          { start: "L5", end: "Q5" },
          { start: "L6", end: "Q6" },
          { start: "L7", end: "Q7" },
          { start: "L8", end: "Q8" },
          //body Area
          //GL DESCRIPTION
          { start: "G10", end: "H10" },
          { start: "G11", end: "H11" },
          { start: "G12", end: "H12" },
          { start: "G13", end: "H13" },
          { start: "G14", end: "H14" },
          { start: "G15", end: "H15" },
          //DESCIRPTION
          { start: "L10", end: "O10" },
          { start: "L11", end: "O11" },
          { start: "L12", end: "O12" },
          { start: "L13", end: "O13" },
          { start: "L14", end: "O14" },
          { start: "L15", end: "O15" },

          //footer area
          { start: "C16", end: "H16" },
          { start: "C18", end: "D18" },
          { start: "C20", end: "D20" },
          { start: "C22", end: "D22" },

          { start: "N16", end: "U16" },
          { start: "N17", end: "Q17" },
          { start: "N18", end: "Q18" },
          { start: "N19", end: "Q19" },

          { start: "G18", end: "J18" },
          { start: "G20", end: "J20" },

          { start: "Q22", end: "Y22" },
          { start: "H22", end: "O22" },
          { start: "J24", end: "K24" },

          { start: "U24", end: "V24" },
          { start: "S26", end: "V26" },

          //approvals
          { start: "Q29", end: "S29" },
          { start: "Q31", end: "S31" },
          { start: "Q33", end: "S33" },
          { start: "T29", end: "Y29" },
          { start: "T30", end: "Y30" },
          { start: "T31", end: "Y31" },
          { start: "T32", end: "Y32" },
          { start: "T33", end: "Y33" },
          { start: "T34", end: "Y34" },

          //notes
          { start: "R37", end: "Y37" },
          { start: "R38", end: "Y38" },
          { start: "R39", end: "Y39" },
          { start: "R40", end: "Y40" },

          //warranty shit
          { start: "D28", end: "O28" },
          { start: "D29", end: "O29" },
          { start: "D30", end: "O30" },
          { start: "D31", end: "O31" },
          { start: "D32", end: "N32" },
          { start: "D33", end: "N33" },
          { start: "D35", end: "N35" },
          { start: "D37", end: "O37" },
          { start: "D38", end: "O38" },
          { start: "D39", end: "O39" },
          { start: "D40", end: "O40" },
        ];
        //apply page border
        applyRangeBorder(worksheet, "B2", "Z41", "thick");
        //thin border areas
        applyRangeBorder(worksheet, "B2", "I8", "thin");

        applyRangeBorder(worksheet, "N16", "U21", "thin");
        applyRangeBorder(worksheet, "H22", "O26", "thin");
        applyRangeBorder(worksheet, "Q22", "Y26", "thin");
        //warranty info
        applyRangeBorder(worksheet, "D39", "O39", "thin");

        //thick border areas
        //sheet
        //sums & taxes
        applyRangeBorder(worksheet, "W14", "Y14", "thick");
        applyRangeBorder(worksheet, "W15", "Y15", "thick");
        applyRangeBorder(worksheet, "W16", "Y16", "thick");
        applyRangeBorder(worksheet, "W17", "Y17", "thick");
        //warranty info
        applyRangeBorder(worksheet, "D28", "O30", "thick");
        applyRangeBorder(worksheet, "D31", "O31", "thick");
        applyRangeBorder(worksheet, "D32", "O32", "thick");
        applyRangeBorder(worksheet, "D33", "O34", "thick");
        applyRangeBorder(worksheet, "D35", "O36", "thick");
        applyRangeBorder(worksheet, "D37", "O40", "thick");

        //apply column widths
        for (let colLetter in columnWidths) {
          let colNumber = columnLetterToNumber(colLetter);
          worksheet.getColumn(colNumber).width = columnWidths[colLetter];
        }
        //apply cell heights
        for (let rowNum in rowHeights) {
          worksheet.getRow(parseInt(rowNum)).height = rowHeights[rowNum];
        }
        //apply merges
        mergeRanges.forEach((range) => {
          try {
            worksheet.mergeCells(range.start, range.end);
          } catch (error) {
            console.log(error);
          }
        });
        //patch damage
        addBorderLine(worksheet, "B2", "I2", "top", "thick");
        addBorderLine(worksheet, "A2", "A8", "right", "thick");
        addBorderLine(worksheet, "U16", "U16", "right", "thin");
        addBorderLine(worksheet, "O22", "O22", "right", "thin");
        addBorderLine(worksheet, "Y22", "Y22", "right", "thin");
        addBorderLine(worksheet, "O28", "O40", "right", "thick");

        //fill in corperate area
        applyFillToRange(worksheet, "B2", "I8", "FFD3D3D3"); // Light gray fill for range B2:D4

        //fill in warranty area
        applyFillToRange(worksheet, "D28", "O30", "FFD9D9D9"); // White, Background 1, Darker 15%
        applyFillToRange(worksheet, "D31", "O40", "FFCCCCCC"); // Light Gray, Background 2

        //add corperate box text
        worksheet.getCell("B2").value = "Purchase Date:";
        worksheet.getCell("B3").value = "Total:";
        worksheet.getCell("B4").value = "Delivery/P-U Date:";
        worksheet.getCell("B5").value = "Order Number:";
        worksheet.getCell("B6").value = "GL Codes: ";
        worksheet.getCell("I4").value = "For Corporate Use";

        //styling for coperate area
        worksheet.getCell("I4").alignment = {
          textRotation: 90,
          vertical: "middle",
          horizontal: "middle",
        };
        worksheet.getCell("I4").font = {
          name: "Calibri",
          size: 10,
          italic: true,
        };

        for (let row = 2; row <= 6; row++) {
          for (let col = 2; col <= 2; col++) {
            let cell = worksheet.getRow(row).getCell(col);
            cell.font = {
              name: "Calibri",
              size: 10,
              italic: true,
            };
            cell.alignment = {
              vertical: "bottom",
              horizontal: "right",
            };
          }
        }

        //add lines to corperate box area
        addBorderLine(worksheet, "D3", "H3", "top", "thin");
        addBorderLine(worksheet, "D4", "H4", "top", "thin");
        addBorderLine(worksheet, "D5", "H5", "top", "thin");
        addBorderLine(worksheet, "D6", "H6", "top", "thin");
        addBorderLine(worksheet, "D7", "H7", "top", "thin");

        //header setup
        worksheet.getCell("J3").value = `${String(
          vendor.name
        ).toUpperCase()} CREDIT CARD USAGE REQUEST FORM`;
        worksheet.getCell("K5").value = `Request Date:`;
        worksheet.getCell("K6").value = `Vendor:`;
        worksheet.getCell("K7").value = `Date Needed:`;
        worksheet.getCell("K8").value = `Property:`;

        //values

        worksheet.getCell("L5").value = `${today}`;
        worksheet.getCell("L6").value = `${vendor.name}`;
        worksheet.getCell("L7").value = `ASAP`;
        worksheet.getCell(
          "L8"
        ).value = `${userInfo.activeProperty.propertyLLC}`;

        //header styling
        for (let row = 5; row <= 8; row++) {
          for (let col = 11; col <= 11; col++) {
            let cell = worksheet.getRow(row).getCell(col);
            cell.font = {
              name: "Calibri",
              size: 10,
              bold: true,
            };
            cell.alignment = {
              vertical: "bottom",
              horizontal: "right",
            };
          }
        }
        for (let row = 3; row <= 8; row++) {
          for (let col = 12; col <= 12; col++) {
            let cell = worksheet.getRow(row).getCell(col);
            cell.font = {
              name: "Calibri",
              size: 16,
              bold: true,
            };
            cell.alignment = {
              vertical: "bottom",
              horizontal: "center",
            };
          }
        }
        worksheet.getCell("L3").alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        worksheet.getCell("L3").font = {
          name: "Calibri",
          size: 22,
          bold: true,
        };

        // body headers
        worksheet.getCell("C10").value = `Amount:`;
        worksheet.getCell("E10").value = `GL Code`;
        worksheet.getCell("G10").value = `GL Description`;
        worksheet.getCell("J10").value = `Unit #s`;
        worksheet.getCell("L10").value = `Description`;
        worksheet.getCell("Q10").value = `Item or SKU #`;
        worksheet.getCell("U10").value = `Quantity`;
        worksheet.getCell("W10").value = `Price`;
        worksheet.getCell("Y10").value = `Total`;

        for (let row = 10; row <= 10; row++) {
          for (let col = 3; col <= 25; col++) {
            let cell = worksheet.getRow(row).getCell(col);
            cell.font = {
              name: "Calibri",
              size: 10,
              bold: true,
              underline: true,
            };
            cell.alignment = {
              vertical: "bottom",
              horizontal: "center",
            };
          }
        }
        let startRow = 11;

        orderItems.forEach((item) => {
          console.log(item);
          let cell = null;

          cell = worksheet.getCell(`E${startRow}`);
          cell.value = item.itemGLCode;
          cell.font = {
            name: "Calibri",
            size: 14,
          };
          cell.alignment = {
            vertical: "bottom",
            horizontal: "center",
          };
          addBorderLine(
            worksheet,
            `E${startRow}`,
            `E${startRow}`,
            "bottom",
            "thin"
          );

          cell = worksheet.getCell(`G${startRow}`);
          cell.value = item.itemGLDescription
            ? item.itemGLDescription
            : "please fix";
          cell.font = {
            name: "Calibri",
            size: 14,
          };
          cell.alignment = {
            vertical: "bottom",
            horizontal: "center",
          };
          addBorderLine(
            worksheet,
            `G${startRow}`,
            `G${startRow}`,
            "bottom",
            "thin"
          );

          cell = worksheet.getCell(`J${startRow}`);
          cell.value = item.rooms ? item.rooms : "please fix";
          cell.font = {
            name: "Calibri",
            size: 14,
          };
          cell.alignment = {
            vertical: "bottom",
            horizontal: "center",
          };
          addBorderLine(
            worksheet,
            `J${startRow}`,
            `J${startRow}`,
            "bottom",
            "thin"
          );

          cell = worksheet.getCell(`L${startRow}`);
          cell.value = item.itemName;
          cell.font = {
            name: "Calibri",
            size: 14,
          };
          cell.alignment = {
            vertical: "bottom",
            horizontal: "center",
          };
          addBorderLine(
            worksheet,
            `L${startRow}`,
            `L${startRow}`,
            "bottom",
            "thin"
          );

          cell = worksheet.getCell(`Q${startRow}`);
          cell.value = item.itemSKU;
          cell.font = {
            name: "Calibri",
            size: 14,
          };
          cell.alignment = {
            vertical: "bottom",
            horizontal: "center",
          };
          addBorderLine(
            worksheet,
            `Q${startRow}`,
            `Q${startRow}`,
            "bottom",
            "thin"
          );

          cell = worksheet.getCell(`U${startRow}`);
          cell.value = Number(item.count);
          cell.font = {
            name: "Calibri",
            size: 14,
          };
          cell.alignment = {
            vertical: "bottom",
            horizontal: "center",
          };
          addBorderLine(
            worksheet,
            `U${startRow}`,
            `U${startRow}`,
            "bottom",
            "thin"
          );

          cell = worksheet.getCell(`W${startRow}`);
          cell.value = Number(item.itemCost);
          cell.font = {
            name: "Calibri",
            size: 14,
          };
          cell.alignment = {
            vertical: "bottom",
            horizontal: "center",
          };
          addBorderLine(
            worksheet,
            `W${startRow}`,
            `W${startRow}`,
            "bottom",
            "thin"
          );
          worksheet.getCell(`W${startRow}`).numFmt = '"$"#,##0.00';

          cell = worksheet.getCell(`Y${startRow}`);
          cell.value = {
            formula: `=W${startRow} * U${startRow}`,
          };
          cell.font = {
            name: "Calibri",
            size: 14,
          };
          cell.alignment = {
            vertical: "bottom",
            horizontal: "center",
          };
          addBorderLine(
            worksheet,
            `Y${startRow}`,
            `Y${startRow}`,
            "bottom",
            "thin"
          );

          startRow = startRow + 1;
        });

        //totals text
        worksheet.getCell(`W14`).value = "SUBTOTAL PRODUCT ONLY";
        worksheet.getCell(`Y14`).value = { formula: "=SUM(Y11:Y13)" };
        worksheet.getCell(`Y14`).numFmt = '"$"#,##0.00';

        worksheet.getCell(`W15`).value = "FREIGHT";
        worksheet.getCell(`Y15`).value = Number(0.0);
        worksheet.getCell(`Y15`).numFmt = '"$"#,##0.00';

        worksheet.getCell(`W16`).value = "SALES TAX";
        worksheet.getCell(`Y16`).value = {
          formula: `=SUM(Y14:Y15) * ${userInfo.activeProperty.propertySalesTax}`,
        }; //please fix ########
        worksheet.getCell(`Y16`).numFmt = '"$"#,##0.00';

        worksheet.getCell(`W17`).value = "TOTAL";
        worksheet.getCell(`Y17`).value = { formula: "=SUM(Y14:Y16)" };
        worksheet.getCell(`Y17`).numFmt = '"$"#,##0.00';

        worksheet.getCell(`C11`).value = { formula: "=Y17" };
        worksheet.getCell(`C11`).font = {
          name: "Calibri",
          size: 14,
        };
        addBorderLine(worksheet, `C11`, `C11`, "bottom", "thin");

        //totals styling
        for (let row = 14; row <= 17; row++) {
          for (let col = 23; col <= 25; col++) {
            let cell = worksheet.getRow(row).getCell(col);
            cell.alignment = { wrapText: true };

            if (row == 14 && col == 23) {
              cell.font = {
                name: "Calibri",
                size: 12,
              };
            } else if ((row == 17 && col == 23) || (row == 17 && col == 25)) {
              cell.font = {
                name: "Calibri",
                size: 16,
                color: { argb: "00FF0000" }, // Red font color
              };
            } else {
              // Default styling for other cells
              cell.font = {
                name: "Calibri",
                size: 14,
              };
            }
          }
        }

        //CHECKBOXES SECTION
        const cellsToBorder = [
          "E18",
          "K18",
          "E20",
          "K20",
          "E22",
          "L24",
          "N24",
          "S17",
          "S18",
          "S19",
          "S24",
          "W24",
          "W26",
        ];

        cellsToBorder.forEach((cellRef) => {
          let cell = worksheet.getCell(cellRef);
          cell.border = {
            top: { style: "thick" },
            left: { style: "thick" },
            bottom: { style: "thick" },
            right: { style: "thick" },
          };
        });

        const redCellsToBorder = ["O32", "O33", "O35"];
        redCellsToBorder.forEach((cellRef) => {
          let cell = worksheet.getCell(cellRef);
          cell.border = {
            top: { style: "thick", color: { argb: "FFFF0000" } },
            left: { style: "thick", color: { argb: "FFFF0000" } },
            bottom: { style: "thick", color: { argb: "FFFF0000" } },
            right: { style: "thick", color: { argb: "FFFF0000" } },
          };
        });
        //patch damage
        addBorderLine(worksheet, "O28", "O40", "right", "thick");

        const cellValues = {
          C18: `R&M`,
          C20: `CAPITAL`,
          C22: `REHAB`,
          E20: `X`,
          G20: `TENANT IMPROVEMENTS`,
          G18: `OTHER OPERATING EXPENSE`,
          N17: `Initial/ Deposit Payment`,
          N18: `Reoccuring Payment`,
          N19: `Final/Only Payment`,
          S19: `X`,
          N24: `X`,
          Q24: `Delivery`,
          S24: `X`,
          U24: `PICK-UP`, // Last value for C22, as it overwrites previous values
        };
        const boxHeaderValues = {
          C16: `Mark an 'X' in the boxes below as indicated:`,
          N16: `Check the following box:`,
          H22: `If REHAB, is there a renovation fee? Please check one of the following:`,
          Q22: `Please check one of the following:`,
        };
        const centeredBoxValues = {
          J24: `YES`,
          M24: `NO`,
          S26: `Dispatch Box Truck`,
        };

        // Apply values and a common style
        Object.keys(cellValues).forEach((cellRef) => {
          let cell = worksheet.getCell(cellRef);
          cell.value = cellValues[cellRef];

          if (cell.value == `X`) {
            cell.font = {
              name: "Arial",
              size: 14,
              bold: true,
            };
            cell.alignment = {
              vertical: "middle",
              horizontal: "center",
            };

            return;
          }

          // Apply common style
          cell.font = {
            name: "Arial",
            size: 11,
            bold: true,
          };
          cell.alignment = {
            vertical: "bottom",
            horizontal: "right",
          };
        });
        Object.keys(boxHeaderValues).forEach((cellRef) => {
          let cell = worksheet.getCell(cellRef);
          cell.value = boxHeaderValues[cellRef];
          console.log(cell.value);

          // Apply common style
          cell.font = {
            name: "Calibri",
            size: 10,
            bold: true,
            underline: true,
          };
          cell.alignment = {
            vertical: "bottom",
            horizontal: "center",
          };
        });
        Object.keys(centeredBoxValues).forEach((cellRef) => {
          let cell = worksheet.getCell(cellRef);
          cell.value = centeredBoxValues[cellRef];
          if (cellRef == "S26") {
            cell.font = {
              name: "Arial",
              size: 11,
              bold: true,
            };
            cell.alignment = {
              vertical: "bottom",
              horizontal: "center",
            };

            return;
          }
          // Apply common style
          cell.font = {
            name: "Calibri",
            size: 12,
            bold: true,
          };
          cell.alignment = {
            vertical: "bottom",
            horizontal: "center",
          };
        });

        const warrantyInfo = [
          {
            cell: "D28",
            fontSize: 9,
            fontName: "Arial",
            isBold: true,
            isUnderLined: true,
            isItalicized: false,
            isRich: false,
            align: "center",
            value: `WARRANTY INFORMATION - READ BELOW!`,
            color: "FFFF0000",
          },
          {
            cell: "D29",
            fontSize: 10,
            fontName: "Calibri",
            isBold: true,
            isUnderLined: false,
            isItalicized: false,
            isRich: false,
            align: "center",
            value: `If Purchasing any of the following items you MUST indicate the appropriate Warranty selection below`,
            color: "FF000000",
          },
          {
            cell: "D30",
            fontSize: 10,
            fontName: "Calibri",
            isBold: true,
            isUnderLined: false,
            isItalicized: false,
            isRich: true,
            align: "center",
            value: `for the following items: Fridges, Microwaves, Stoves, A/C units, TVs, Hot Water Heaters, Chillers.`,
            color: "FF000000",
          },
          {
            cell: "D31",
            fontSize: 14,
            fontName: "Calibri",
            isBold: true,
            isUnderLined: true,
            isItalicized: true,
            isRich: false,
            align: "center",
            value: `Mark an 'X' and complete the question in the boxes below as indicated`,
            color: "FFFF0000",
          },
          {
            cell: "D32",
            fontSize: 9,
            fontName: "Arial",
            isBold: true,
            isUnderLined: false,
            isItalicized: false,
            isRich: true,
            align: "right",
            value: `1. This purchase is to replace a missing item(s) OR is a brand new item purchased under REHAB:`,
            color: "FF000000",
          },
          {
            cell: "D33",
            fontSize: 9,
            fontName: "Arial",
            isBold: true,
            isUnderLined: false,
            isItalicized: false,
            isRich: true,
            align: "right",
            value: `2. This purchase is to replace an item without warranty sticker: Manufactured Date(s) is/are as follows:________`,
            color: "FF000000",
          },
          {
            cell: "D35",
            fontSize: 9,
            fontName: "Arial",
            isBold: true,
            isUnderLined: false,
            isItalicized: false,
            isRich: true,
            align: "right",
            value: `3. This purchase is to replace an item with an expired warranty: Warranty Expiration Date(s) is/are:________`,
            color: "FF000000",
          },
          {
            cell: "D37",
            fontSize: 9,
            fontName: "Arial",
            isBold: true,
            isUnderLined: true,
            isItalicized: false,
            isRich: false,
            align: "center",
            value: `THE SERIAL NUMBER OF ITEM(S) BEING REPLACED ARE REQUIRED, THEY ARE LISTED AS FOLLOWS:`,
            color: "FFFF0000",
          },
        ];

        warrantyInfo.forEach((info) => {
          let cell = worksheet.getCell(info.cell);
          cell.value = info.value;

          // Check if rich text needs to be applied
          if (info.isRich) {
            // Apply rich text formatting
            // Additional logic will be required here to handle rich text
            if (info.cell == "D30") {
              let stringy = info.value.split(":");
              cell.font = {
                name: info.fontName,
                size: info.fontSize,
              };
              cell.value = {
                richText: [
                  {
                    text: `${stringy[0]}:`,
                    font: { bold: true, italic: false, underline: false },
                  },
                  {
                    text: `${stringy[1]}`,
                    font: { bold: true, italic: true, underline: true },
                  },
                ],
              };
              cell.alignment = {
                vertical: "bottom",
                horizontal: info.align,
              };

              return;
            }
            if (info.cell == "D32") {
              let stringy = info.value.split(".");
              cell.font = {
                name: info.fontName,
                size: info.fontSize,
              };
              cell.value = {
                richText: [
                  {
                    text: `${stringy[0]}.`,
                    font: { bold: true, italic: false, underline: false },
                  },
                  {
                    text: `${stringy[1]}`,
                    font: { bold: false, italic: false, underline: false },
                  },
                ],
              };
              cell.alignment = {
                vertical: "bottom",
                horizontal: info.align,
              };

              return;
            }
            if (info.cell == "D33" || info.cell == "D35") {
              let stringy = info.value.split(".");
              let stringyAnew = stringy[1].split(":");
              cell.font = {
                name: info.fontName,
                size: info.fontSize,
              };
              cell.value = {
                richText: [
                  {
                    text: `${stringy[0]}.`,
                    font: { bold: true, italic: false, underline: false },
                  },
                  {
                    text: `${stringyAnew[0]}: `,
                    font: { bold: false, italic: false, underline: false },
                  },
                  {
                    text: `${stringyAnew[1]}:${stringyAnew[2]}`,
                    font: { bold: true, italic: false, underline: false },
                  },
                ],
              };
              cell.alignment = {
                vertical: "bottom",
                horizontal: info.align,
              };

              return;
            }
          } else {
            // Apply normal text formatting
            cell.font = {
              name: info.fontName,
              size: info.fontSize,
              bold: info.isBold,
              underline: info.isUnderLined,
              italic: info.isItalicized,
              color: { argb: info.color },
            };
            cell.alignment = {
              vertical: "bottom",
              horizontal: info.align,
            };
          }
        });

        const requestInfo = [
          {
            cell: "Q29",
            value: "Requested By:",
            isBold: false,
            align: "center",
            size: "12",
          },
          {
            cell: "Q31",
            value: "Approved By:",
            isBold: false,
            align: "center",
            size: "12",
          },
          {
            cell: "Q33",
            value: "Approved By:",
            isBold: false,
            align: "center",
            size: "12",
          },
          {
            cell: "T29",
            value: `${userInfo.fullName}`,
            isBold: true,
            align: "center",
            size: "16",
          },
          {
            cell: "T34",
            value: "Name:",
            isBold: false,
            align: "left",
            size: "10",
          },
          {
            cell: "Q37",
            value: "NOTES:",
            isBold: true,
            align: "right",
            size: "12",
          },
        ];

        requestInfo.forEach((info) => {
          let cell = worksheet.getCell(info.cell);
          cell.value = info.value;

          cell.font = {
            name: "Calibri",
            size: info.fontSize,
            bold: info.isBold,
            color: { argb: info.color },
          };
          cell.alignment = {
            vertical: "bottom",
            horizontal: info.align,
          };
        });

        addBorderLine(worksheet, "T29", "T29", "bottom", "thin");
        addBorderLine(worksheet, "T31", "T31", "bottom", "thin");
        addBorderLine(worksheet, "T33", "T33", "bottom", "thin");

        addBorderLine(worksheet, "T37", "T37", "bottom", "thin");
        addBorderLine(worksheet, "T38", "T38", "bottom", "thin");
        addBorderLine(worksheet, "T39", "T39", "bottom", "thin");
        addBorderLine(worksheet, "T40", "T40", "bottom", "thin");

        //add support information to sheet and finish

        const supportInfo = [
          {
            cell: "L9",
            value: `Operations Support will place orders with vendor-  Contact Name : Tracey Siler Tracey.siler@bestbuy.com 612-292-0408`,
            color: "FFFF0000",
            align: "left",
            fontSize: 12,
            isBold: true,
            isWrapped: false,
          },
          {
            cell: "L15",
            value: `NO FREIGHT CHARGE FOR CONSUMER MODELS/WALL MOUNTS`,
            color: "FFFF0000",
            align: "left",
            fontSize: 14,
            isBold: true,
            isWrapped: true,
          },
          {
            cell: "Y2",
            value: `updated 12/29/2020`,
            color: "FF000000",
            align: "left",
            fontSize: 10,
            isBold: false,
            isWrapped: false,
          },
        ];
        supportInfo.forEach((info) => {
          let cell = worksheet.getCell(info.cell);

          // Set cell value
          cell.value = info.value;

          // Apply font styling
          cell.font = {
            name: "Calibri", // or another font name if you want
            size: info.fontSize,
            bold: info.isBold,
            color: { argb: info.color },
          };

          // Apply text alignment
          cell.alignment = {
            horizontal: info.align,
            wrapText: info.isWrapped,
          };
        });
      } else {
        return alert(
          "This vendor has not been added yet, please contact Greg to have vendor added"
        );
      }
    } else {
      if (String(vendor.name).toLocaleLowerCase() == "hd supply") {
        //format columns
        const columnWidths = {
          A: "27.86",
          B: "5.86",
          C: "7.71",
          D: "86.14",
          E: "5.86",
          F: "14.00",
          G: "5.86",
          H: "14.00",
        };
        const rowHeights = {
          1: 33.75,
          2: 23.25,
          3: 15.0,
          4: 12.75,
          5: 15.0,
          6: 20.25,
          7: 15.0,
          8: 15.0,
          9: 15.0,
          10: 15.0,
          11: 22.5,
          12: 15.0,
          13: 12.75,
          14: 13.5,
          15: 12.75, //368
          16: 15.75,
          17: 15.75,
          18: 15.75,
          19: 15.75,
          20: 15.75,
          21: 15.75,
          22: 15.75,
          23: 15.75,
          24: 15.75,
          25: 15.75,
          26: 15.75,
          27: 15.75,
          28: 15.75,
        };
        const mergeRanges = [
          //headers
          { start: "A1", end: "H1" },
          { start: "A2", end: "H2" },
          { start: "A3", end: "H3" },
          { start: "A4", end: "H4" },
          { start: "G6", end: "H6" },
          { start: "G7", end: "H8" },
          { start: "D10", end: "H10" },

          //warranty shit
          { start: "D15", end: "E15" },
          { start: "D16", end: "E16" },
          { start: "D17", end: "E17" },
          { start: "D18", end: "E18" },
          { start: "D19", end: "E19" },
          { start: "D24", end: "E24" },
          { start: "D25", end: "E25" },
          { start: "D26", end: "E26" },
          { start: "D27", end: "E27" },

          //checkbox info
          { start: "F18", end: "H18" },
          { start: "F22", end: "H22" },

          //approvals
          { start: "B29", end: "C29" },
          { start: "B30", end: "C30" },
          { start: "B31", end: "C31" },
          { start: "B32", end: "C32" },

          { start: "D29", end: "F29" },
          { start: "D30", end: "F30" },
          { start: "D31", end: "F31" },
          { start: "D32", end: "F32" },
        ];
        const headerData = [
          {
            cell: "A1",
            value: String(vendor.name).toUpperCase(),
            align: "center",
            fontSize: 26,
            fontName: "Calibri",
          },
          {
            cell: "A2",
            value: "PURCHASE ORDER REQUEST",
            align: "center",
            fontSize: 18,
            fontName: "Calibri",
          },
          {
            cell: "A3",
            value:
              "Order online at www.hdsupplysolutions.com or use call center 1-800-431-3000.",
            align: "center",
            fontSize: 11,
            fontName: "Calibri",
            color: "FF558ED5",
          },
          {
            cell: "A4",
            value:
              "Each property or person, can create a username/password using the account number associated with that property.   One Log In per Property. The Siegel pricing will show up as they are looking at products.",
            align: "center",
            fontSize: 10,
            isWrapped: true,
            fontName: "Calibri",
          },
          {
            cell: "B5",
            value: "SIEGEL SUITES and SELECTS",
            align: "center",
            fontSize: 10,
            fontName: "Bookman Old Style",
          },
          {
            cell: "C6",
            value: "Property:",
            align: "right",
            fontSize: 8,
            fontName: "Arial",
          },
          {
            cell: "C7",
            value: "Vendor:",
            align: "right",
            fontSize: 8,
            fontName: "Arial",
          },
          {
            cell: "C8",
            value: "Date:",
            align: "right",
            fontSize: 8,
            fontName: "Arial",
          },
          {
            cell: "C9",
            value: "Ship To:",
            align: "right",
            fontSize: 8,
            fontName: "Arial",
          },

          {
            cell: "D6",
            value: userInfo.activeProperty.DisplayName,
            align: "center",
            fontSize: 16,
            fontName: "Arial",
            bottomBar: true,
          },
          {
            cell: "D7",
            value: String(vendor.name).toUpperCase(),
            align: "center",
            fontSize: 8,
            fontName: "Arial",
            bottomBar: true,
          },
          {
            cell: "D8",
            value: today,
            align: "center",
            fontSize: 8,
            fontName: "Arial",
            bottomBar: true,
          },
          {
            cell: "D9",
            value: userInfo.activeProperty.propertyAddress,
            align: "center",
            fontSize: 8,
            fontName: "Arial",
            bottomBar: true,
          },

          {
            cell: "F6",
            value: "PO:",
            align: "right",
            fontSize: 8,
            fontName: "Arial",
          },
          {
            cell: "F8",
            value: "REQUESTED BY:",
            align: "right",
            fontSize: 8,
            fontName: "Arial",
          },
          {
            cell: "G6",
            value: "",
            align: "center",
            fontSize: 8,
            fontName: "Arial",
            bottomBar: true,
          },
          {
            cell: "G7",
            value: userInfo.fullName,
            align: "center",
            fontSize: 8,
            fontName: "Arial",
            bottomBar: true,
          },

          {
            cell: "D10",
            value: "Pricing/Discount Updated 03.23.2022",
            align: "right",
            fontSize: 10,
            fontName: "Calibri",
          },

          //13 start
          // `formula: =SUM(H13:INDIRECT(ADDRESS(ROW()-1, COLUMN(), 4)))`
          {
            cell: "G13",
            value: "SUBTOTAL",
            align: "right",
            fontSize: 8,
            fontName: "Arial",
          },
          {
            cell: "G14",
            value: `TAX ${(
              userInfo.activeProperty.propertySalesTax * 100
            ).toFixed(2)}%`,
            align: "right",
            fontSize: 8,
            fontName: "Arial",
          },
          {
            cell: "G15",
            value: "TOTAL",
            align: "right",
            fontSize: 8,
            fontName: "Arial",
          },

          {
            cell: "H13",
            value: {
              formula: `=SUM(H13:INDIRECT(ADDRESS(ROW()-1, COLUMN(), 4)))`,
            },
            align: "right",
            fontSize: 10,
            fontName: "Calibri",
            border: true,
          },
          {
            cell: "H14",
            value: {
              formula: `=INDIRECT(ADDRESS(ROW()-1, COLUMN(), 4)) * ${userInfo.activeProperty.propertySalesTax}`,
            },
            align: "right",
            fontSize: 10,
            fontName: "Calibri",
            border: true,
          },
          {
            cell: "H15",
            value: {
              formula: `=SUM(INDIRECT(ADDRESS(ROW()-2, COLUMN(), 4)):INDIRECT(ADDRESS(ROW()-1, COLUMN(), 4)))`,
            },
            align: "right",
            fontSize: 10,
            fontName: "Calibri",
            border: true,
          },
          {
            cell: "A11",
            value: "Sub Category",
            align: "center",
            fontSize: 8,
            fontName: "Arial",
            border: true,
          },
          {
            cell: "B11",
            value: "GL",
            align: "left",
            fontSize: 8,
            fontName: "Arial",
            border: true,
          },
          {
            cell: "C11",
            value: "Part #",
            align: "left",
            fontSize: 8,
            fontName: "Arial",
            border: true,
          },
          {
            cell: "D11",
            value: "Description",
            align: "center",
            fontSize: 8,
            fontName: "Arial",
            border: true,
          },
          {
            cell: "E11",
            value: "Pkg Qty",
            align: "center",
            fontSize: 8,
            fontName: "Arial",
            border: true,
          },
          {
            cell: "F11",
            value: "Price with 3.5% Discount",
            align: "center",
            fontSize: 8,
            fontName: "Arial",
            isWrapped: true,
            border: true,
          },
          {
            cell: "G11",
            value: "QTY",
            align: "left",
            fontSize: 8,
            fontName: "Arial",
            border: true,
          },
          {
            cell: "H11",
            value: "TOTAL",
            align: "center",
            fontSize: 8,
            fontName: "Arial",
            border: true,
          },
          /////////////checkbox info f22
          {
            cell: "F18",
            value: "If Rehab, is there a Renovation fee?",
            align: "center",
            fontSize: 10,
            fontName: "Arial",
            isItalicized: true,
            isUnderLined: true,
          },
          {
            cell: "F22",
            value: "Mark an 'X' in the boxes below as indicated:",
            align: "center",
            fontSize: 10,
            fontName: "Arial",
            isItalicized: true,
            isUnderLined: true,
          },
          {
            cell: "F26",
            value: "Tenant Improvements",
            align: "right",
            fontSize: 9,
            fontName: "Arial",
          },

          /////////approvals
          {
            cell: "B29",
            value: "MANAGER:",
            align: "center",
            fontSize: 9,
            fontName: "Arial",
          },
          {
            cell: "D29",
            value: userInfo.fullName,
            align: "center",
            fontSize: 9,
            fontName: "Arial",
            bottomBar: true,
          },
          {
            cell: "D30",
            value: "",
            align: "center",
            fontSize: 9,
            fontName: "Arial",
            bottomBar: true,
          },
          {
            cell: "D31",
            value: "",
            align: "center",
            fontSize: 9,
            fontName: "Arial",
            bottomBar: true,
          },
          {
            cell: "D32",
            value: "",
            align: "center",
            fontSize: 9,
            fontName: "Arial",
            bottomBar: true,
          },
          {
            cell: "B30",
            value: "Print Name:",
            align: "center",
            fontSize: 9,
            fontName: "Arial",
            isWrapped: true,
          },
          {
            cell: "B31",
            value: "APPROVED BY: ",
            align: "center",
            fontSize: 9,
            fontName: "Arial",
          },
          {
            cell: "B32",
            value: "Print Name:",
            align: "center",
            fontSize: 9,
            fontName: "Arial",
          },
        ];
        const startArrayDataTable = 12;

        //apply column widths
        for (let colLetter in columnWidths) {
          let colNumber = columnLetterToNumber(colLetter);
          worksheet.getColumn(colNumber).width = columnWidths[colLetter];
        }

        //apply cell heights
        for (let rowNum in rowHeights) {
          worksheet.getRow(parseInt(rowNum)).height = rowHeights[rowNum];
        }

        //apply info to cells
        headerData.forEach((item) => {
          let cell = worksheet.getCell(item.cell);

          // Set the value
          cell.value = item.value;

          // Set the font styles
          cell.font = {
            name: item.fontName,
            size: item.fontSize,
            bold: true,
            color: { argb: item.color || "FF000000" }, // Default to black if color is not specified
            underline: item.isUnderLined ? item.isUnderLined : false,
            italic: item.isItalicized ? item.isItalicized : false,
          };

          // Set the horizontal alignment
          cell.alignment = {
            horizontal: item.align,
            vertical: "bottom", // Setting vertical alignment to bottom
          };

          // Set text wrapping if specified
          if (item.isWrapped) {
            cell.alignment = { ...cell.alignment, wrapText: true };
          }

          if (item.border) {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
          if (item.bottomBar) {
            addBorderLine(worksheet, item.cell, item.cell, "bottom", "thin");
          }
        });

        //test sample

        //CHECKBOXES SECTION
        const cellsToBorder = ["G19", "G20", "G23", "G24", "G25", "G26"];

        cellsToBorder.forEach((cellRef) => {
          let cell = worksheet.getCell(cellRef);
          cell.border = {
            top: { style: "thick" },
            left: { style: "thick" },
            bottom: { style: "thick" },
            right: { style: "thick" },
          };
        });

        //add warranty shit
        const warrantyInfo = [
          {
            cell: "D15", //d28 - d15
            fontSize: 9,
            fontName: "Arial",
            isBold: true,
            isUnderLined: true,
            isItalicized: false,
            isRich: false,
            align: "center",
            value: `WARRANTY INFORMATION - READ BELOW!`,
            color: "FFFF0000",
          },
          {
            cell: "D16", //d29 - d16
            fontSize: 10,
            fontName: "Calibri",
            isBold: true,
            isUnderLined: false,
            isItalicized: false,
            isRich: false,
            align: "center",
            value: `If Purchasing any of the following items you MUST indicate the appropriate Warranty selection below`,
            color: "FF000000",
          },
          {
            cell: "D17", //d30 - d17
            fontSize: 10,
            fontName: "Calibri",
            isBold: true,
            isUnderLined: false,
            isItalicized: false,
            isRich: true,
            align: "center",
            value: `for the following items: Fridges, Microwaves, Stoves, A/C units, TVs, Hot Water Heaters, Chillers.`,
            color: "FF000000",
          },
          {
            cell: "D19", //d31 - d18
            fontSize: 14,
            fontName: "Calibri",
            isBold: true,
            isUnderLined: true,
            isItalicized: true,
            isRich: false,
            align: "center",
            value: `Mark an 'X' and complete the question in the boxes below as indicated`,
            color: "FFFF0000",
          },
          {
            cell: "D20", //d32 - d19
            fontSize: 9,
            fontName: "Arial",
            isBold: true,
            isUnderLined: false,
            isItalicized: false,
            isRich: true,
            align: "right",
            value: `1. This purchase is to replace a missing item(s) OR is a brand new item purchased under REHAB:`,
            color: "FF000000",
          },
          {
            cell: "D21", //d33 - d20
            fontSize: 9,
            fontName: "Arial",
            isBold: true,
            isUnderLined: false,
            isItalicized: false,
            isRich: true,
            align: "right",
            value: `2. This purchase is to replace an item without warranty sticker: Manufactured Date(s) is/are as follows:________`,
            color: "FF000000",
          },
          {
            cell: "D23", //d35 - d22
            fontSize: 9,
            fontName: "Arial",
            isBold: true,
            isUnderLined: false,
            isItalicized: false,
            isRich: true,
            align: "right",
            value: `3. This purchase is to replace an item with an expired warranty: Warranty Expiration Date(s) is/are:________`,
            color: "FF000000",
          },
          {
            cell: "D25", //d37 - d24
            fontSize: 9,
            fontName: "Arial",
            isBold: true,
            isUnderLined: true,
            isItalicized: false,
            isRich: false,
            align: "center",
            value: `THE SERIAL NUMBER OF ITEM(S) BEING REPLACED ARE REQUIRED, THEY ARE LISTED AS FOLLOWS:`,
            color: "FFFF0000",
          },
        ];

        warrantyInfo.forEach((info) => {
          let cell = worksheet.getCell(info.cell);
          cell.value = info.value;

          // Check if rich text needs to be applied
          if (info.isRich) {
            // Apply rich text formatting
            // Additional logic will be required here to handle rich text
            if (info.cell == "D17") {
              let stringy = info.value.split(":");
              cell.font = {
                name: info.fontName,
                size: info.fontSize,
              };
              cell.value = {
                richText: [
                  {
                    text: `${stringy[0]}:`,
                    font: { bold: true, italic: false, underline: false },
                  },
                  {
                    text: `${stringy[1]}`,
                    font: { bold: true, italic: true, underline: true },
                  },
                ],
              };
              cell.alignment = {
                vertical: "bottom",
                horizontal: info.align,
              };

              return;
            }
            if (info.cell == "D20") {
              let stringy = info.value.split(".");
              cell.font = {
                name: info.fontName,
                size: info.fontSize,
              };
              cell.value = {
                richText: [
                  {
                    text: `${stringy[0]}.`,
                    font: { bold: true, italic: false, underline: false },
                  },
                  {
                    text: `${stringy[1]}`,
                    font: { bold: false, italic: false, underline: false },
                  },
                ],
              };
              cell.alignment = {
                vertical: "bottom",
                horizontal: info.align,
              };

              return;
            }
            if (info.cell == "D21" || info.cell == "D23") {
              let stringy = info.value.split(".");
              let stringyAnew = stringy[1].split(":");
              cell.font = {
                name: info.fontName,
                size: info.fontSize,
              };
              cell.value = {
                richText: [
                  {
                    text: `${stringy[0]}.`,
                    font: { bold: true, italic: false, underline: false },
                  },
                  {
                    text: `${stringyAnew[0]}: `,
                    font: { bold: false, italic: false, underline: false },
                  },
                  {
                    text: `${stringyAnew[1]}:${stringyAnew[2]}`,
                    font: { bold: true, italic: false, underline: false },
                  },
                ],
              };
              cell.alignment = {
                vertical: "bottom",
                horizontal: info.align,
              };

              return;
            }
          } else {
            // Apply normal text formatting
            cell.font = {
              name: info.fontName,
              size: info.fontSize,
              bold: info.isBold,
              underline: info.isUnderLined,
              italic: info.isItalicized,
              color: { argb: info.color },
            };
            cell.alignment = {
              vertical: "bottom",
              horizontal: info.align,
            };
          }
        });
        applyFillToRange(worksheet, "A11", "H11", "FF92CDDC");
        applyFillToRange(worksheet, "D15", "E18", "FFD9D9D9"); // White, Background 1, Darker 15%
        applyFillToRange(worksheet, "D19", "E27", "FFCCCCCC");
        applyRangeBorder(worksheet, "D15", "D18", "thick");
        applyRangeBorder(worksheet, "D19", "D19", "thick");
        applyRangeBorder(worksheet, "D20", "D20", "thick");
        applyRangeBorder(worksheet, "D21", "E22", "thick");
        applyRangeBorder(worksheet, "D23", "D27", "thick");

        //add items

        orderItems.forEach((item) => {
          let newRow = worksheet.insertRow(startArrayDataTable);

          // Set font and values for each cell individually
          ["A", "B", "C", "D", "E", "F", "G", "H"].forEach((column) => {
            newRow.getCell(column).font = {
              name: "Arial",
              size: 8, // Size should be a number, not a string
            };
            newRow.getCell(column).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });

          const A = newRow.getCell("A");
          const B = newRow.getCell("B");
          const C = newRow.getCell("C");
          const D = newRow.getCell("D");
          const E = newRow.getCell("E");
          const F = newRow.getCell("F");
          const G = newRow.getCell("G");
          const H = newRow.getCell("H");

          A.value = "##########";
          B.value = item.itemGLCode;
          C.value = item.itemSKU;
          D.value = item.itemName;
          E.value = "1";
          F.value = item.itemCost;
          G.value = item.count;
          H.value = {
            formula: `=INDIRECT(ADDRESS(ROW(), COLUMN()-2, 4)) * INDIRECT(ADDRESS(ROW(), COLUMN()-1, 4))`,
          };
        });

        //apply merges
        mergeRanges.forEach((range) => {
          try {
            const rangeStartSplit = splitLettersAndNumbers(range.start);
            const startRow = parseInt(rangeStartSplit[1], 10);

            if (startArrayDataTable > startRow) {
              worksheet.mergeCells(range.start, range.end);
            } else {
              const rangeEndSplit = splitLettersAndNumbers(range.end);
              const newStart = `${rangeStartSplit[0]}${
                startRow + orderItems.length
              }`;
              const newEnd = `${rangeEndSplit[0]}${
                parseInt(rangeEndSplit[1], 10) + orderItems.length
              }`;
              worksheet.mergeCells(`${newStart}:${newEnd}`);
            }
          } catch (error) {
            console.log("Merge error:", error);
          }
        });

        //add red cells
        const redCellsToBorder = ["E20", "E21", "E23"];
        redCellsToBorder.forEach((cellRef) => {
          const splitCell = splitLettersAndNumbers(cellRef);
          const col = splitCell[0];
          const row = String(Number(splitCell[1]) + orderItems.length);
          let cell = worksheet.getCell(`${col}${row}`);
          cell.border = {
            top: { style: "thick", color: { argb: "FFFF0000" } },
            left: { style: "thick", color: { argb: "FFFF0000" } },
            bottom: { style: "thick", color: { argb: "FFFF0000" } },
            right: { style: "thick", color: { argb: "FFFF0000" } },
          };
          //patch damage
          addBorderLine(worksheet, `F${row}`, `F${row}`, "left", "thick");

          worksheet.views = [
            { zoomScale: 100 }, // Zoom set to 100
          ];
        });
      } else {
        return alert(
          `${String(
            vendor.name
          ).toLocaleLowerCase()} has not been added yet, please contact Greg to have vendor added`
        );
      }
    }

    //
    //
    //
    //
    //
    //
    //
    //

    //end of manipulation
    const buffer = await workbook.xlsx.writeBuffer();

    // Use FileSaver to save the file
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const dashedToday = new Date()
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })
      .replace(/\//g, "-");

    saveAs(blob, `${vendor.name} ${vendor.porType} ${dashedToday}.xlsx`);
  };

  const orderColumns = [
    {
      field: "count",
      headerName: "Quantity",
      editable: true,
    },
    { field: "rooms", headerName: "For Rooms", editable: true, flex: 1 },
    { field: "itemName", headerName: "Item", editable: true, flex: 1 },
    {
      field: "itemCost",
      headerName: "Amount",
      editable: true,
      //   renderCell: (params) => (
      //     <TextField
      //       value={params.value}
      //       onChange={(event) => {
      //         console.log("Change Detected: ", event.target.value);
      //         commitChanges(params.id, event.target.value);
      //       }}
      //       onSubmit={() => {
      //         console.log("submit detected");
      //       }}
      //       onBlur={() => console.log("blurred")}
      //     />
      //   ),
    },
    {
      field: "x",
      headerName: "Total",
      renderCell: (params) => {
        return Number(
          Number(params.row.count) * Number(params.row.itemCost)
        ).toFixed(2);
      },
    },
    // { field: "itemGLCode", headerName: "GL Code", editable: false },
  ];

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {vendors.length > 0 ? (
          <Box sx={vendor === "" ? style : styleActive}>
            {vendor === "" ? (
              <>
                <h2 id="modal-modal-title">Please Select Vendor</h2>
                <Select
                  value={vendor}
                  label="Vendor"
                  onChange={(e) => {
                    setVendor(e.target.value);
                    console.log(e.target.value);
                  }}
                >
                  {vendors.map((vendor) => (
                    <MenuItem key={vendor.id} value={vendor}>
                      {vendor.name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : (
              <>
                <h2 id="modal-modal-title">
                  Create {vendor.porType} for {vendor.name}
                </h2>
                {vendorItems.length === 0 ? (
                  <p id="modal-modal-description">
                    No items have been added to this vendor
                  </p>
                ) : (
                  <>
                    {orderItems.length > 0 && (
                      <DataGrid
                        rows={orderItems}
                        columns={orderColumns}
                        processRowUpdate={handleEditCommit}
                        disableRowSelectionOnClick
                      />
                    )}
                    {/* <DataGrid></DataGrid> */}
                    {addItem ? (
                      <Autocomplete
                        options={vendorItems}
                        getOptionLabel={(item) => item.itemName}
                        style={{ width: 300 }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        onChange={(event, newValue) => {
                          setActiveItem(newValue);
                          //   setInputValue(newValue ? newValue.itemName : '');
                          newValue.count = Number(1);
                          newValue.rooms = "insert rooms:";
                          setOrderItems([...orderItems, newValue]);
                          setAddItem(false);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select an item"
                            variant="outlined"
                          />
                        )}
                      />
                    ) : (
                      <IconButton
                        onClick={() => {
                          setAddItem(true);
                        }}
                      >
                        <AddCircle />
                      </IconButton>
                    )}

                    {/* {vendorItems.map((item) => <p>{item.itemName}</p>)} */}
                  </>
                )}
              </>
            )}
            <Box sx={bottomBar}>
              <Box>
                <Button variant="outlined" onClick={() => handleClose()}>
                  Cancel
                </Button>
              </Box>
              {orderItems.length > 0 && (
                <>
                  <Box>
                    <Button onClick={() => console.log(orderItems)}>
                      LogMe
                    </Button>
                    <Button onClick={() => processRequestQuote()}>
                      Request Quote
                    </Button>
                    <Button onClick={() => generatePOR()}>Save POR</Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        ) : (
          <Box sx={style}>
            <h2 id="modal-modal-title">Loading...</h2>
          </Box>
        )}
      </Modal>
      {supportModalOpen && (
        <SupportGLCodeModal
          isOpen={supportModalOpen}
          onClose={supportModalOnClose}
          orderTotals={supportModalTotals}
          propertySalesTax={supportModalSalesTax}
        />
      )}
    </div>
  );
};

export default ControlledPORModal;
