import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Modal,
  Typography,
  CircularProgress,
} from "@mui/material";
import PropertyContext from "../contexts/PropertyContext";
import GenerateDRList from "./GenerateDRList"; // excel
import GenerateDRListPDF from "./GenerateDRListPDF";
import GenerateRoomsChart from "./GenerateRoomsChart";
import generatePropertyIssuesPDF from "./generatePropertyIssuesPDF";

const Reports = ({ user }) => {
  const [selectedReport, setSelectedReport] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const { selectedProperty, rooms } = useContext(PropertyContext);

  const handleGeneratePropertyIssuesPDF = async () => {
    setStatusMessage("Starting Property Issues Report generation...");
    setIsGenerating(true);
    await generatePropertyIssuesPDF(selectedProperty, setStatusMessage);
    setIsGenerating(false);
  };
  const handleGenerateDRListPDF = async () => {
    setStatusMessage("Generating DRL PDF...");
    setIsGenerating(true);
    await GenerateDRListPDF(selectedProperty, rooms, setStatusMessage);
    setIsGenerating(false);
  };

  const generateWorkOrdersByUserReport = async () => {
    setStatusMessage("Generating Work Orders Completed by User Report...");
    console.log(`Generating Work Orders Completed by User Report`);
    setStatusMessage(
      "Work Orders Completed by User Report generated successfully."
    );
    setIsGenerating(false);
  };

  const generateManagerChecklistReport = async () => {
    setStatusMessage("Generating Manager Checklist Report...");
    console.log(`Generating Manager Checklist Report`);
    setStatusMessage("Manager Checklist Report generated successfully.");
    setIsGenerating(false);
  };

  const generateVendorNeededReport = async () => {
    setStatusMessage("Generating Vendor Needed Report...");
    console.log(`Generating Vendor Needed Report`);
    setStatusMessage("Vendor Needed Report generated successfully.");
    setIsGenerating(false);
  };

  const generateEasiestRoomsReport = async () => {
    setStatusMessage("Generating Easiest Rooms Report...");
    console.log(`Generating Easiest Rooms Report`);
    setStatusMessage("Easiest Rooms Report generated successfully.");
    setIsGenerating(false);
  };

  const generateDispatch = async () => {
    setStatusMessage("Generating Dispatch...");
    console.log(`Generating Dispatch`);
    setStatusMessage("Dispatch generated successfully.");
    setIsGenerating(false);
  };

  const handleGenerate = () => {
    setIsGenerating(true);
    switch (selectedReport) {
      case "work orders completed by user":
        generateWorkOrdersByUserReport();
        break;
      case "manager checklist report":
        generateManagerChecklistReport();
        break;
      case "vendor needed report":
        generateVendorNeededReport();
        break;
      case "easiest rooms report":
        generateEasiestRoomsReport();
        break;
      case "generate dispatch":
        generateDispatch();
        break;
      case "Property Issues Report":
        handleGeneratePropertyIssuesPDF();
        break;
      case "Generate DRL PDF With Images":
        handleGenerateDRListPDF();
        break;
      default:
        console.log("No report selected: ", selectedReport);
        setIsGenerating(false);
        break;
    }
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box marginBottom="4rem">
        <Typography>Reports</Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {[
          "work orders completed by user",
          "manager checklist report",
          "vendor needed report",
          "easiest rooms report",
          "generate dispatch",
          "Property Issues Report",
          "Generate DRL PDF With Images",
        ].map((item) => (
          <Box key={item}>
            <Button onClick={() => setSelectedReport(item)}>{item}</Button>
          </Box>
        ))}
        {/* generate DRL */}
        <div style={{ margin: "20px 0" }}>
          <GenerateDRList />
        </div>

        <div>
          <GenerateRoomsChart user={user} />
        </div>

        {selectedReport !== null && (
          <Button onClick={handleGenerate}>Generate Report</Button>
        )}
      </Box>

      <Modal open={isGenerating}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100vh"
          bgcolor="background.paper"
          padding="2rem"
          borderRadius="8px"
        >
          <Typography variant="h6" gutterBottom>
            Generating Report
          </Typography>
          <CircularProgress />
          <Typography variant="body1" marginTop="1rem">
            {statusMessage}
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default Reports;
