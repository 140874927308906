import React, { useState } from 'react';
import { Box, Button, CircularProgress, Container, TextField, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';


export default function DashboardWidget({ name, loading, taskCount, desc, linkTo }) {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(linkTo);
    };

    return (
      <Box onClick={handleClick} sx={{ mt: 8, height: "100px", width: "300px", borderRadius: '16px', border: 1, borderColor: 'blue' }}>
        <Box sx={{ height: '20%' }}>
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            {name}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', height: "80%" }}>
          {loading ? (
            <Box sx={{ display: 'flex', alignContent: 'center', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            <Box sx={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', alignContent:'space-between'}}>
                <Box sx={{mr: 3 }}>
                    <Typography variant="h3" sx={{ textAlign: 'center' }}>
                        {taskCount}
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="h6" sx={{ textAlign: 'center' }}>
                        {desc}
                    </Typography>
                </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  }