import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import DashboardWidget from "./DashboardWidget";
import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
} from "firebase/firestore";
import { firestore } from "../firebase";
import { CheckBox } from "@mui/icons-material";
import { MuiTelInput } from "mui-tel-input";
import { DataGrid } from "@mui/x-data-grid";
import PropertyContext from "../contexts/PropertyContext";

const VendorModal = ({ open, onClose, property, selectedVendor }) => {
  const [selection, setSelection] = useState("");
  const [orderSelection, setOrderSelection] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [vendorName, setVendorName] = useState();
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState(null);

  useEffect(() => {
    if (selectedVendor !== null) {
      console.log(selectedVendor);
      setSelection(selectedVendor.type);
      setOrderSelection(selectedVendor.porType);
      setSelectedDays(selectedVendor.days);
      setVendorName(selectedVendor.name);
      //if values are null or undefined set them to empty strings
      setContactName(
        selectedVendor.contactName ? selectedVendor.contactName : ""
      );
      setContactEmail(
        selectedVendor.contactEmail ? selectedVendor.contactEmail : ""
      );
      setContactPhone(
        selectedVendor.contactPhone ? selectedVendor.contactPhone : null
      );
    }
  }, [selectedVendor]);

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedDays([...selectedDays, event.target.name]);
    } else {
      setSelectedDays(selectedDays.filter((day) => day !== event.target.name));
    }
  };

  const handleChange = (event) => {
    setSelection(event.target.value);
  };

  const handleTypeChange = (event) => {
    setOrderSelection(event.target.value);
  };

  const handleAddVendor = async () => {
    const testInfo = {
      name: vendorName,
      type: selection,
      porType: orderSelection,
      days: selectedDays,
      contactName: contactName,
      contactEmail: contactEmail,
      contactPhone: contactPhone,
    };
    //console.log(testInfo)
    //add to profile
    if (selectedVendor !== null) {
      const vendorRef = doc(
        firestore,
        "properties",
        property,
        "Vendors",
        selectedVendor.id
      );
      await updateDoc(vendorRef, testInfo);
    } else {
      const vendorRef = collection(
        firestore,
        "properties",
        property,
        "Vendors"
      );
      await addDoc(vendorRef, testInfo);
    }

    //clean up and close
    setSelectedDays([]);
    setVendorName(null);
    setSelection("");
    onClose();
  };
  const handleMonthlySelection = (event) => {
    if (event.target.value.includes(",")) {
      const daysArray = event.target.value.split(",").map((day) => day.trim());

      setSelectedDays(daysArray);
    } else {
      // Handle the case for a single day or empty input
      setSelectedDays(event.target.value ? [event.target.value.trim()] : []);
    }
  };
  const handlePhoneChange = (newValue, optionTwo) => {
    setContactPhone(newValue);
    console.log(newValue);
    console.log(optionTwo);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        {/* Modal Content */}
        <Box sx={{ mb: "2rem", width: "100%", display: "flex" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h6">Add a New Vendor</Typography>
          </Box>
          {/* if selectedVendor is not null display delete vendor button */}
          {selectedVendor !== null && (
            <Box sx={{ float: "right", ml: "2rem" }}>
              <Button
                variant="outlined"
                color="error"
                onClick={() => console.log("delete vendor")}
              >
                Delete Vendor
              </Button>
            </Box>
          )}
        </Box>

        {/* Rest of the modal content goes here */}
        {/* For example, form fields to add a new vendor */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <TextField
            id="outlined-basic"
            label="Vendor Name"
            variant="outlined"
            value={vendorName}
            onChange={(val) => setVendorName(val.target.value)}
          />
          <Box sx={{ float: "right" }}>
            <FormControl sx={{ mb: "2rem" }}>
              <InputLabel id="vendor-schedule-label">
                Vendor Schedule
              </InputLabel>
              <Select
                labelId="vendor-schedule-label"
                value={selection}
                label="Vendor Schedule"
                onChange={handleChange}
                sx={{ minWidth: "4rem", width: "100%" }}
              >
                {["weekly", "monthly", "as needed", "orders only"].map(
                  (option) => (
                    // <MenuItem key={option} onClick={() => setSelection(option)}>
                    <MenuItem value={option}>{option}</MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <FormControl sx={{ mb: "2rem" }}>
            <InputLabel id="vendor-schedule-label">Order Type:</InputLabel>
            <Select
              labelId="vendor-schedule-label"
              value={orderSelection}
              label="Order Type:"
              onChange={handleTypeChange}
              sx={{ minWidth: "4rem", width: "100%" }}
            >
              {["POR", "CCUR"].map((option) => (
                // <MenuItem key={option} onClick={() => setSelection(option)}>
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          {selection == "weekly" && (
            <Box>
              {[
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ].map((day) => (
                <FormControlLabel
                  key={day}
                  control={
                    <Checkbox name={day} onChange={handleCheckboxChange} />
                  }
                  label={day}
                />
              ))}
            </Box>
          )}
          {selection === "monthly" && (
            <React.Fragment>
              {/* <TextField id="outlined-basic" label="Dates of service (seperated by comma)" variant="outlined" onChange={(val) => setSelectedDays(val.target.value)}/> */}
              <TextField
                id="outlined-basic"
                label="Dates of service (seperated by comma)"
                variant="outlined"
                onChange={handleMonthlySelection}
              />
            </React.Fragment>
          )}
          {selection !== null && (
            <React.Fragment>
              <Box
                sx={{
                  mt: "2rem",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Contact Name"
                    variant="outlined"
                    value={contactName}
                    onChange={(val) => setContactName(val.target.value)}
                  />

                  <MuiTelInput
                    size="small"
                    label="Contact #"
                    defaultCountry="US"
                    value={contactPhone}
                    onChange={handlePhoneChange}
                  />
                </Box>
                <Box sx={{ mt: "2rem", mb: "2rem" }}>
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Contact Email"
                    variant="outlined"
                    value={contactEmail}
                    onChange={(val) => setContactEmail(val.target.value)}
                  />
                </Box>
              </Box>
            </React.Fragment>
          )}
        </Box>

        {/* Action Buttons */}
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          {selectedVendor !== null ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddVendor}
            >
              Update Vendor
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddVendor}
            >
              Add Vendor
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default function Vendors() {
  const [isLoading, setIsLoading] = useState(true);
  const [vendorList, setVendorList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);

  const { selectedProperty } = useContext(PropertyContext);

  useEffect(() => {
    const vendorsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "Vendors"
    );
    const q = query(vendorsRef);

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const vendorArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVendorList(vendorArray);
      console.log(vendorArray);
    });

    setIsLoading(false);

    return unsubscribe;
  }, []);

  const handleModalOpen = () => {
    //if modal is currently open currenVendor is not null then close and set currentVendor to null, else, open modal
    if (modalOpen && selectedVendor !== null) {
      setSelectedVendor(null);
    }
    setModalOpen(!modalOpen);
  };
  const renderVendorPhoneCell = (params) => {
    return (
      <Box>
        <MuiTelInput
          size="small"
          label="Contact #"
          defaultCountry="US"
          value={params.value}
          disabled
        />
      </Box>
    );
  };
  const renderVendorEmailCell = (params) => {
    //returns mailto link with the vendor's email for easy communication
    //remove generated style from the link

    return (
      <Box>
        <a href={`mailto:${params.value}`}>{params.value}</a>
      </Box>
    );
  };

  const columns = [
    { field: "name", headerName: "Vendor Name", width: 200 },
    { field: "type", headerName: "Type", width: 100 },
    { field: "porType", headerName: "PO Type", width: 75 },
    { field: "days", headerName: "Days", width: 200 },
    { field: "contactName", headerName: "Contact Name", width: 200 },
    {
      field: "contactPhone",
      headerName: "Contact Phone",
      width: 220,
      renderCell: renderVendorPhoneCell,
    },
    {
      field: "contactEmail",
      headerName: "Contact Email",
      width: 200,
      renderCell: renderVendorEmailCell,
    },
  ];

  return (
    <Container sx={{ mt: 8 }}>
      {isLoading ? (
        <Box>
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Box>
          <Typography variant="h4" sx={{ mb: 4, textAlign: "center" }}>
            Vendors for {selectedProperty}
          </Typography>
          <Box>
            <Box>
              <Button onClick={handleModalOpen}>New Vendor</Button>
            </Box>
            <Box>
              {modalOpen && (
                <VendorModal
                  open={modalOpen}
                  onClose={handleModalOpen}
                  property={selectedProperty}
                  selectedVendor={selectedVendor}
                />
              )}
            </Box>
            <Box>
              {vendorList.length > 0 && (
                <DataGrid
                  rows={vendorList}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  disableSelectionOnClick
                  onRowClick={(row) => {
                    setSelectedVendor(row.row);
                    handleModalOpen();
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Container>
  );
}
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
