import React, { useEffect, useRef, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { Avatar, Box, Button, Chip, Input, Paper, Switch, ToggleButton, Typography } from '@mui/material';
import MarkAsReadIcon from '@mui/icons-material/Done'; // Import the icon you want for the "Mark as Read" button
import { collection, doc, onSnapshot, query, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { MuiTelInput } from 'mui-tel-input'
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';






const Profile = ({userInfo}) => {
    console.log(userInfo)
    const [phoneNumber, setPhoneNumber] = useState(userInfo.phone)
    const [selected, setSelected] = useState(true)

    const [textNotificationsOnRequests, setTextNotificationsOnRequests] = useState(userInfo.textNotiRequests || false)
    const [textNotificationsOnInbox, setTextNotificationsOnInbox] = useState(userInfo.textNotiInbox || false)

    const [isUpdated, setIsUpdated] = useState(false)


    const updateProfile = async () => {
        const profileData = {
            phoneNumber: phoneNumber,
            textNotiRequests: textNotificationsOnRequests,
            textNotiInbox: textNotificationsOnInbox
        }
        console.log(profileData)
        setIsUpdated(false)
    }

    const handleChangeRequests = (event) => {
        setTextNotificationsOnRequests(event.target.checked);
        setIsUpdated(true)
    };
    const handleChangeInbox = (event) => {
        setTextNotificationsOnInbox(event.target.checked);
        setIsUpdated(true)
    };

    const handleChange = (newValue) => {
        setPhoneNumber(newValue)
        setIsUpdated(true)
        //console.log(newValue)
    }


  useEffect(() => {
  }, []);


  return (
    <Box sx={{
        width: '100%',
        height: '85vh', // Full height
        display: 'flex',
        flexDirection: 'column',
        //justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'background.paper',
        p: 2
      }}>

      <Avatar
        sx={{ width: 100, height: 100, mb: 2 }}
        alt={userInfo.name}
        src="/broken-image.jpg"
        />
        <Box sx={{ textAlign: 'center', mb: 2 }}>
            <Typography variant="h6">
                {(userInfo.fullName).split(' ')[0]}
            </Typography>
            <Typography variant="h6">
                {(userInfo.fullName).split(' ')[1]}
            </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', justifyContent: 'center', mb: 3 }}>
            {userInfo.properties ? (
                userInfo.properties.map((property)=>(
                    <Chip size="large" label={property.displayName} />
                ))
            ):(
                <Chip size="large" label={userInfo.property} />
            )
            }

        </Box>
        <Box>
            <MuiTelInput size="small" defaultCountry="US" value={phoneNumber} onChange={handleChange} disabled={selected}/>
            <ToggleButton
                value="check"
                size="small"
                selected={selected}
                onChange={() => {
                    setSelected(!selected);
                }}
                > 
                {selected ? (
                    <EditIcon />
                ): (
                    <CheckIcon />
                )}
            </ToggleButton>
        </Box>
        <Box>
        <Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
                    <Switch checked={textNotificationsOnRequests} onChange={handleChangeRequests} inputProps={{ 'aria-label': 'controlled' }} />
                    <Typography sx={{ ml: 1 }}>Text notifications for Parts requests - WIP</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Switch checked={textNotificationsOnInbox} onChange={handleChangeInbox} inputProps={{ 'aria-label': 'controlled' }} />
                    <Typography sx={{ ml: 1 }}>Text notifications for inbox items - WIP</Typography>
                </Box>
            </Box>
        </Box>
        {isUpdated && (
            <Button onClick={() => updateProfile()}>Update Profile</Button>
        )}
    </Box>
  );
};

export default Profile;
