///  M.U.S.T.   ///

import React, { useState } from "react";
import {
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import StarIcon from "@mui/icons-material/Star";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";

const drawerWidth = 155;

const Sidebar = ({ user, messages }) => {
  const [openPropertySettings, setOpenPropertySettings] = useState(false);

  const handleClick = () => {
    setOpenPropertySettings(!openPropertySettings);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
        paddingTop: 20,
      }}
      variant="permanent"
      anchor="left"
    >
      {user.userType === "manager" && (
        <React.Fragment>
          <List sx={{ paddingTop: 7 }}>
            {[
              { text: "Dashboard", icon: <InboxIcon />, path: "/" },
              { text: "Inbox", icon: <InboxIcon />, path: "/inbox" },
              { text: "Rooms", icon: <StarIcon />, path: "/rooms" },
              { text: "Orders", icon: <StarIcon />, path: "/orders" },
              // { text: "Work Order", icon: <StarIcon />, path: "/workOrders" },
              // { text: "A-additem", icon: <StarIcon />, path: "/addItem" },
              { text: "A-CreateUser", icon: <StarIcon />, path: "/addUser" },
              // { text: 'A-Dispatch', icon: <StarIcon />, path: '/dispatch' },
              { text: "Call List", icon: <StarIcon />, path: "/callList" },
              // { text: 'Report', icon: <StarIcon />, path: '/generate-pdf' },
              // { text: 'Properties', icon: <StarIcon />, path: '/properties' },
              // {
              //   text: "Create Property",
              //   icon: <StarIcon />,
              //   path: "/create-property",
              // },
              {
                text: "Employees",
                icon: <StarIcon />,
                path: "/EmployeeSchedule",
              },
              {
                text: "Dispatch Assistant",
                icon: <StarIcon />,
                path: "/dispatchAssistant",
              },
              { text: "Reports", icon: <StarIcon />, path: "/reports" },

              // ...other items
            ].map((item, index) => (
              <ListItem button key={item.text} component={Link} to={item.path}>
                {item.text === "Inbox" ? (
                  <ListItemIcon>
                    <Badge badgeContent={messages.length} color="primary">
                      {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
                      {item.icon}
                    </Badge>
                  </ListItemIcon>
                ) : (
                  <ListItemIcon>{item.icon}</ListItemIcon>
                )}
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
            {/* Property Settings Dropdown */}
            <ListItem button onClick={handleClick}>
              <ListItemIcon>
                {/* Icon for Property Settings, replace with your choice */}
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Property Settings" />
              {openPropertySettings ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openPropertySettings} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="/employees">
                  <ListItemIcon>
                    {/* Icon for Employees, replace with your choice */}
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Employees" />
                </ListItem>
                <ListItem button component={Link} to="/addItem">
                  <ListItemIcon>
                    {/* Icon for Add Item, replace with your choice */}
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Add Item" />
                </ListItem>
              </List>
            </Collapse>
          </List>
        </React.Fragment>
      )}
      {user.userType === "maintenance" && (
        <React.Fragment>
          {/* maintenance needs access to the ability to request items, access their own dispatch, search for items  */}
          <List sx={{ paddingTop: 7 }}>
            {[
              { text: "Dashboard", icon: <InboxIcon />, path: "/" },
              { text: "Orders", icon: <StarIcon />, path: "/orders" },
              { text: "Work Order", icon: <StarIcon />, path: "/workOrders" },
            ].map((item, index) => (
              <ListItem button key={item.text} component={Link} to={item.path}>
                {item.text === "Inbox" ? (
                  <ListItemIcon>
                    <Badge badgeContent={messages.length} color="primary">
                      {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
                      {item.icon}
                    </Badge>
                  </ListItemIcon>
                ) : (
                  <ListItemIcon>{item.icon}</ListItemIcon>
                )}
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </React.Fragment>
      )}
      {user.userType === "housekeeping" && (
        <React.Fragment>
          {/* housekeeping needs access to the ability to request items, access their own dispatch */}
          <List sx={{ paddingTop: 7 }}>
            {[
              { text: "Dashboard", icon: <InboxIcon />, path: "/" },
              { text: "Orders", icon: <StarIcon />, path: "/orders" },
            ].map((item, index) => (
              <ListItem button key={item.text} component={Link} to={item.path}>
                {item.text === "Inbox" ? (
                  <ListItemIcon>
                    <Badge badgeContent={messages.length} color="primary">
                      {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
                      {item.icon}
                    </Badge>
                  </ListItemIcon>
                ) : (
                  <ListItemIcon>{item.icon}</ListItemIcon>
                )}
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </React.Fragment>
      )}
      {user.userType === "frontDesk" && (
        <React.Fragment>
          <List sx={{ paddingTop: 7 }}>
            {[
              { text: "Dashboard", icon: <InboxIcon />, path: "/" },
              { text: "Orders", icon: <StarIcon />, path: "/orders" },
              { text: "Work Order", icon: <StarIcon />, path: "/workOrders" },
              { text: "Rooms", icon: <StarIcon />, path: "/rooms" },
              { text: "Call List", icon: <StarIcon />, path: "/callList" },
            ].map((item, index) => (
              <ListItem button key={item.text} component={Link} to={item.path}>
                {item.text === "Inbox" ? (
                  <ListItemIcon>
                    <Badge badgeContent={messages.length} color="primary">
                      {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
                      {item.icon}
                    </Badge>
                  </ListItemIcon>
                ) : (
                  <ListItemIcon>{item.icon}</ListItemIcon>
                )}
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </React.Fragment>
      )}
    </Drawer>
  );
};

export default Sidebar;
