import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { Box, Button, Typography } from '@mui/material';
import MarkAsReadIcon from '@mui/icons-material/Done'; // Import the icon you want for the "Mark as Read" button
import { collection, doc, onSnapshot, query, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { useNavigate } from 'react-router-dom';


const WorkOrderMessage = ({data, completedBy, workOrderFor, time, property}) => {
  const navigate = useNavigate()
  //console.log(`work order message called`, data)
  const messagesRef = doc(firestore, 'properties', property, 'messages', data.id)
  const handleMarkAsRead = async () => {
    const updateData = {
      isRead: true
    }
    await updateDoc(messagesRef, updateData)
  }

  return (
    <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
      <Box width="10%">
        <Typography>
          {time}
        </Typography>
      </Box>
      <Box>
        <Typography>{completedBy} has completed work order for room {workOrderFor}</Typography>
      </Box>
      <Box>
        <Button onClick={() => navigate('/workOrders')}>View</Button>
        <IconButton edge="end" aria-label="mark as read" onClick={() => handleMarkAsRead(data.id)}>
          <MarkAsReadIcon />
        </IconButton>
      </Box>
    </Box>
  )
}


const Inbox = ({messages, staff, property}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [requests, setRequests] = useState([])
  const [workOrders, setWorkOrders] = useState([])
  const employees = staff

  useEffect(() => {
      setIsLoading(true)
      const workOrdersRef = collection(firestore, 'properties', property, 'workOrders')
      const q2 = query(workOrdersRef)

      const unsub = onSnapshot(q2, (snap)=>{
        const workOrdersList = snap.docs.map(doc =>({
            id: doc.id,
            ...doc.data()
        }))
        .filter(request => request.markedCompleted === true)
        .filter(request => request.Completed !== true);

        setWorkOrders(workOrdersList)

        setIsLoading(false)
      })

      return unsub; // Unsubscribe from the listener when the component unmounts
  }, []);


  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {isLoading ? (
        <React.Fragment>
          <Typography variant="h6" component="div" sx={{ p: 2 }}>
            Loading...
          </Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box>
            <Typography variant="h6" component="div" sx={{ p: 2 }}>
              Inbox
            </Typography>
          </Box>
          <Box>
            <List>
              {messages.map((message) => {
                console.log(`1 `, message)
                switch (message.type) {
                  case "workOrderCompleted":
                    return (
                      <React.Fragment>
                        <ListItem>
                          <WorkOrderMessage 
                            data={message} 
                            completedBy={employees.find(member => member.id === message.completedBy).name}
                            workOrderFor={workOrders.find(order => order.id === message.orderRef).room}
                            workOrderDetails={workOrders.find(order => order.id === message.orderRef).orderDetails}
                            time={new Date(workOrders.find(order => order.id === message.orderRef).markedCompletedTime).toLocaleTimeString()}
                            property={property}
                          />
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>
                    )
                    break;
                  case "roomMarkedHKReady":
                      return (
                        <React.Fragment>
                          <ListItem>
                            <Typography>Room marked as HK Ready has not been added yet</Typography>
                          </ListItem>
                          <Divider component="li" />
                        </React.Fragment>
                      )

                      break;
                  case "roomMarkedMaintReady":
                    return (
                      <React.Fragment>
                        <ListItem>
                          <Typography>Room marked as maintenance Ready has not been added yet</Typography>
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>
                    )

                      break;
                  case "orderRequest":
                    return (
                      <React.Fragment>
                        <ListItem>
                          <Typography>order Requests has not been added yet</Typography>
                        </ListItem>
                        <Divider component="li" />
                      </React.Fragment>
                    )

                      break;
                    
                  default:
                    console.log(`couldnt find type: `, message.type)
                    break;
                }
              })}
            </List>
          </Box>
        </React.Fragment>
      )}
      {/* <List>
        {messages.map(({ id, primary, secondary, time, read }) => (
          <React.Fragment key={id}>
            <ListItem 
              alignItems="flex-start"
              secondaryAction={
                read ? null : (
                  <IconButton edge="end" aria-label="mark as read" onClick={() => handleMarkAsRead(id)}>
                    <MarkAsReadIcon />
                  </IconButton>
                )
              }
            >
              <ListItemText
                primary={primary}
                secondary={
                  <>
                    {time + ' - '}
                    <Typography component="span" variant="body2" color="textPrimary">
                      {secondary}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <Divider component="li" />
          </React.Fragment>
        ))}
      </List> */}
    </Box>
  );
};

export default Inbox;
