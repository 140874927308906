// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCW2si5CUmY8dtLgw9NU7wEXGIFT1kBWeM",
  authDomain: "siegel-room-status.firebaseapp.com",
  projectId: "siegel-room-status",
  storageBucket: "siegel-room-status.appspot.com",
  messagingSenderId: "1085127109809",
  appId: "1:1085127109809:web:465b2be38739cb61080690",
  measurementId: "G-JSQ9H5D42G",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { auth, firestore, app, storage };
