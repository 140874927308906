import React, { useState, useEffect, useContext } from "react";
import {
  collection,
  onSnapshot,
  query,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { auth, firestore, storage } from "../firebase";
import {
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Modal,
  Chip,
  Divider,
} from "@mui/material";
import Dropzone from "react-dropzone";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import PropertyContext from "../contexts/PropertyContext";

const WOModal = ({ open, onClose, vendors }) => {
  const [selectedVendor, setSelectedVendor] = useState(0);
  const [desc, setDesc] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [files, setFiles] = useState([]);
  const [reportTo, setReportTo] = useState([]);
  const { selectedProperty } = useContext(PropertyContext);

  const propertyIssuesRef = collection(
    firestore,
    "properties",
    selectedProperty,
    "propertyIssues"
  );

  const handleFileDrop = async (acceptedFiles) => {
    const date = new Date().toISOString().split("T")[0];
    const promises = acceptedFiles.map(async (file) => {
      const timestamp = Date.now();
      const storageRef = ref(
        storage,
        `property-issues/${selectedProperty}/${date}/${timestamp}-${file.name}`
      );
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      return { name: file.name, url };
    });

    const uploadedFiles = await Promise.all(promises);
    setFiles((prev) => [...prev, ...uploadedFiles]);
  };

  const handleAddChip = (event) => {
    if (event.key === "Enter" && event.target.value) {
      setReportTo((prev) => [...prev, event.target.value]);
      event.target.value = "";
    }
  };

  const handleDeleteChip = (chipToDelete) => () => {
    setReportTo((chips) => chips.filter((chip) => chip !== chipToDelete));
  };

  const handleClose = async () => {
    if (selectedVendor === 0) {
      clearForClose();
    } else {
      const issueData = {
        completed: false,
        vendor: selectedVendor,
        status: selectedStatus,
        issueDetails: desc,
        files: files,
        reportTo,
        submittedAt: serverTimestamp(),
      };
      await addDoc(propertyIssuesRef, issueData);

      clearForClose();
    }
  };

  const clearForClose = () => {
    setSelectedVendor(0);
    setSelectedStatus("");
    setDesc("");
    setFiles([]);
    setReportTo([]);

    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Box width="100%">
          <Box display="flex" sx={{ p: 0.5 }} justifyContent="center">
            <Typography>New Issue</Typography>
            <Divider />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            justifyContent="space-around"
            padding="5px"
          >
            <Box sx={{ p: 0.5 }}>
              <InputLabel id="vendor-select-label">Select Vendor</InputLabel>
              <Select
                labelId="vendor-select-label"
                id="vendor-select"
                label="Select Vendor"
                value={selectedVendor}
                onChange={(event) => setSelectedVendor(event.target.value)}
              >
                {vendors.map((option) => (
                  <MenuItem key={option.id} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
                <MenuItem key={"unknown"} value={"unknown"}>
                  unknown
                </MenuItem>
                <MenuItem key={"TBD"} value={"TBD"}>
                  TBD
                </MenuItem>
              </Select>
            </Box>
            <Box sx={{ p: 0.5 }}>
              <InputLabel id="status-select-label">Select Status</InputLabel>
              <Select
                labelId="status-select-label"
                id="status-select"
                label="Select Status"
                value={selectedStatus}
                onChange={(event) => setSelectedStatus(event.target.value)}
              >
                {[
                  "not reported",
                  "reported",
                  "pending estimate",
                  "pending approval",
                  "pending vendor arrival",
                  "work in progress",
                  "completed",
                ].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Box>
        <Box sx={{ p: 0.5 }}>
          <TextField
            id="outlined-multiline-static"
            label="Description"
            fullWidth
            multiline
            rows={4}
            value={desc}
            onChange={(event) => setDesc(event.target.value)}
          />
        </Box>
        <Box sx={{ p: 0.5 }}>
          <Dropzone onDrop={handleFileDrop}>
            {({ getRootProps, getInputProps }) => (
              <Box
                {...getRootProps()}
                sx={{
                  border: "2px dashed gray",
                  padding: "10px",
                  textAlign: "center",
                }}
              >
                <input {...getInputProps()} />
                <Typography>
                  Drag & drop files here, or click to select files
                </Typography>
              </Box>
            )}
          </Dropzone>
          <Box mt={2}>
            {files.map((file, index) => (
              <Chip key={index} label={file.name} />
            ))}
          </Box>
        </Box>
        <Box sx={{ p: 0.5 }}>
          <FormControl fullWidth>
            <InputLabel htmlFor="report-to-input">Reported To</InputLabel>
            <TextField
              id="report-to-input"
              placeholder="Type and press enter to add"
              onKeyDown={handleAddChip}
            />
          </FormControl>
          <Box mt={2}>
            {reportTo.map((chip, index) => (
              <Chip
                key={index}
                label={chip}
                onDelete={handleDeleteChip(chip)}
                sx={{ margin: "2px" }}
              />
            ))}
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Button onClick={onClose} sx={{ mt: 2 }}>
              Cancel
            </Button>
          </Box>
          <Box>
            <Button onClick={handleClose} sx={{ mt: 2 }}>
              Generate Work Order
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const PropertyProblems = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [openIssues, setOpenIssues] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const { selectedProperty } = useContext(PropertyContext);

  useEffect(() => {
    setIsLoading(true);
    const issuesRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "propertyIssues"
    );
    const VendorsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "Vendors"
    );

    const queryIssues = query(issuesRef);
    const queryVendors = query(VendorsRef);

    const unsub = onSnapshot(queryIssues, (snap) => {
      const openIssues = snap.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((request) => request.completed === false);

      setOpenIssues(openIssues);

      const uns = onSnapshot(queryVendors, (sna) => {
        const VendorsList = sna.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setVendors(VendorsList);
        setIsLoading(false);
      });
    });
  }, []);

  const markAsCompleted = async (issueId) => {
    const orderRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "propertyIssues",
      issueId
    );
    //close out order
    const updatedWODetails = {
      completed: true,
    };
    await updateDoc(orderRef, updatedWODetails);
  };

  const handleModalOpen = () => setModalOpen(!modalOpen);

  // const issueData = {
  //     completed: false,
  //     vendor: selectedVendor,
  //     status: selectedStatus,
  //     issueDetails: desc,
  // }

  const columns = [
    { field: "status", headerName: "status", width: 250, editable: false },
    {
      field: "issueDetails",
      headerName: "Details",
      width: 250,
      editable: false,
      flex: 1,
    },
    { field: "vendor", headerName: "vendor", width: 250, editable: true },

    {
      field: "completed",
      headerName: "Mark Complete",
      width: 130,
      editable: true,
      renderCell: (params) => (
        <Button
          onClick={() =>
            markAsCompleted(params.id, params.row.markedCompleteBy)
          }
        >
          Approve
        </Button>
      ),
    },
  ];

  return (
    <Box p={2}>
      {isLoading ? (
        <React.Fragment>
          <Typography>Im Loading!</Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box display="flex" width="100%" justifyContent="flex-end">
            <Box>
              <Button variant="contained" onClick={handleModalOpen}>
                New Issue
              </Button>
            </Box>
          </Box>
          <Box>
            <WOModal
              open={modalOpen}
              onClose={handleModalOpen}
              vendors={vendors}
            />
          </Box>
          {openIssues.length > 0 && (
            <React.Fragment>
              <Typography>Property Issues and Statuses</Typography>
              <DataGrid
                rows={openIssues}
                // getRowId={(row) => row.roomId}
                columns={columns}
                // onCellEditCommit={}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default PropertyProblems;
