import React from "react";
import OrderRequests from "./OrderRequests";
import NewPurchaseOrderModal from "./NewPurchaseOrderModal";
import CurrentPORs from "./CurrentPORs";

function PurchaseOrdersPage({ userInfo }) {
  return (
    <div>
      <NewPurchaseOrderModal />
      <CurrentPORs userInfo={userInfo} />
      <OrderRequests />
    </div>
  );
}

export default PurchaseOrdersPage;
