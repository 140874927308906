import React from "react";
import { AppBar, Toolbar, Typography, IconButton, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ThemeToggler from "./ThemeToggler";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";

const Navbar = ({ handleDrawerToggle, themeMode, toggleTheme, isLoggedIn }) => {
  return (
    <AppBar position="fixed">
      {/* If user is not logged in, do not show the toolbar */}
      {isLoggedIn ? (
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, paddingLeft: 15 }}
          >
            RoomRoster
          </Typography>
          <Box>
            <ThemeToggler toggleTheme={toggleTheme} themeMode={themeMode} />
            <IconButton color="inherit" component={Link} to={"/Profile"}>
              <AccountCircleIcon />
            </IconButton>
          </Box>
        </Toolbar>
      ) : (
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, paddingLeft: 15 }}
          >
            RoomRoster
          </Typography>
        </Toolbar>
      )}
    </AppBar>
  );
};

export default Navbar;
