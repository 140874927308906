// Desc: Modal for displaying items in a category
import React, { useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import PropertyContext from "../contexts/PropertyContext";

const CategoryModal = ({ open, onClose, category, room, itemsByCategory }) => {
  const { selectedProperty } = useContext(PropertyContext);

  const [itemCounts, setItemCounts] = useState(() => {
    const initialCounts = {};
    itemsByCategory[category].forEach((item) => {
      const itemCount = parseInt(room[item.itemID]);
      initialCounts[item.itemID] = isNaN(itemCount) ? 0 : itemCount;
    });
    return initialCounts;
  });

  const incrementItemCount = (itemID) => {
    setItemCounts((prevCounts) => ({
      ...prevCounts,
      [itemID]: prevCounts[itemID] + 1,
    }));
  };

  const decrementItemCount = (itemID) => {
    setItemCounts((prevCounts) => ({
      ...prevCounts,
      [itemID]: Math.max(prevCounts[itemID] - 1, 0),
    }));
  };

  const updateRoom = async () => {
    const roomRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "units",
      room.displayName
    );
    await updateDoc(roomRef, { ...itemCounts });

    onClose();
  };

  // Function to split items into balanced columns
  const splitItemsIntoBalancedColumns = (items, columnCount) => {
    const columns = Array.from({ length: columnCount }, () => []);
    items.forEach((item, index) => {
      columns[index % columnCount].push(item);
    });
    return columns;
  };

  // Determine the number of columns needed
  const items = itemsByCategory[category];
  const columnCount = Math.ceil(items.length / 10);
  const columns = splitItemsIntoBalancedColumns(items, columnCount);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "auto",
          minWidth: "30rem",
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 2,
          outline: "none",
        }}
      >
        <Typography variant="h6">
          {category} Needs for {room.displayName}
        </Typography>
        <Box sx={{ display: "flex" }}>
          {columns.map((columnItems, columnIndex) => (
            <List key={columnIndex} sx={{ flex: 1 }}>
              {columnItems.map((item, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={item.itemName}
                    secondary={`Count: ${itemCounts[item.itemID]}`}
                  />
                  <IconButton onClick={() => decrementItemCount(item.itemID)}>
                    <RemoveIcon />
                  </IconButton>
                  <IconButton onClick={() => incrementItemCount(item.itemID)}>
                    <AddIcon />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          ))}
        </Box>
        <Button onClick={updateRoom} sx={{ mt: 2 }}>
          Update
        </Button>
        <Button onClick={onClose} sx={{ mt: 2, ml: 2 }}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default CategoryModal;

//original code
// // Desc: Modal for displaying items in a category
// import React, { useContext, useState } from "react";
// import Modal from "@mui/material/Modal";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import Button from "@mui/material/Button";
// import IconButton from "@mui/material/IconButton";
// import AddIcon from "@mui/icons-material/Add";
// import RemoveIcon from "@mui/icons-material/Remove";
// import { doc, updateDoc } from "firebase/firestore";
// import { firestore } from "../firebase";
// import PropertyContext from "../contexts/PropertyContext";

// const CategoryModal = ({ open, onClose, category, room, itemsByCategory }) => {
//   // Initialize state to track item counts
//   const { selectedProperty } = useContext(PropertyContext);

//   const [itemCounts, setItemCounts] = useState(() => {
//     const initialCounts = {};
//     itemsByCategory[category].forEach((item) => {
//       const itemCount = parseInt(room[item.itemID]);
//       initialCounts[item.itemID] = isNaN(itemCount) ? 0 : itemCount;
//     });
//     return initialCounts;
//   });

//   // Increment function
//   const incrementItemCount = (itemID) => {
//     setItemCounts((prevCounts) => ({
//       ...prevCounts,
//       [itemID]: prevCounts[itemID] + 1,
//     }));
//   };

//   // Decrement function
//   const decrementItemCount = (itemID) => {
//     setItemCounts((prevCounts) => ({
//       ...prevCounts,
//       [itemID]: Math.max(prevCounts[itemID] - 1, 0),
//     }));
//   };

//   // Update room in Firestore
//   const updateRoom = async () => {
//     const roomRef = doc(
//       firestore,
//       "properties",
//       selectedProperty,
//       "units",
//       room.displayName
//     );
//     //update firestore doc
//     await updateDoc(roomRef, { ...itemCounts });

//     onClose(); // Close the modal after updating
//   };

//   return (
//     <Modal open={open} onClose={onClose}>
//       <Box
//         sx={{
//           position: "absolute",
//           top: "50%",
//           left: "50%",
//           transform: "translate(-50%, -50%)",
//           width: "auto",
//           minWidth: "30rem",
//           bgcolor: "background.paper",
//           boxShadow: 24,
//           p: 2,
//           outline: "none",
//         }}
//       >
//         <Typography variant="h6">
//           {category} Needs for {room.displayName}
//         </Typography>
//         <List>
//           {itemsByCategory[category].map((item, index) => (
//             <ListItem key={index}>
//               <ListItemText
//                 primary={item.itemName}
//                 secondary={`Count: ${itemCounts[item.itemID]}`}
//               />
//               <IconButton onClick={() => decrementItemCount(item.itemID)}>
//                 <RemoveIcon />
//               </IconButton>
//               <IconButton onClick={() => incrementItemCount(item.itemID)}>
//                 <AddIcon />
//               </IconButton>
//             </ListItem>
//           ))}
//         </List>
//         <Button onClick={updateRoom}>Update</Button>
//         <Button onClick={onClose}>Close</Button>
//       </Box>
//     </Modal>
//   );
// };

// export default CategoryModal;
