import React, { useEffect, useState } from "react";
import PropertyContext from "../contexts/PropertyContext";
import { collection, doc, onSnapshot, query } from "firebase/firestore";
import { firestore } from "../firebase";

const PropertyProvider = ({ children }) => {
  const [selectedProperty, setSelectedProperty] = useState(null); // Initially null, will be set during login
  const [status, setStatus] = useState("loading..."); // ["loading", "error", "success"]
  const [rooms, setRooms] = useState([]);
  const [itemCategories, setItemCategories] = useState([]);
  const [items, setItems] = useState([]);
  const [propertyInformation, setPropertyInformation] = useState({});

  useEffect(() => {
    if (!selectedProperty) return;

    const fetchRooms = () => {
      setStatus("loading Rooms...");
      const roomsRef = collection(
        firestore,
        "properties",
        selectedProperty,
        "units"
      );
      const roomsQuery = query(roomsRef);
      const unsubscribeRooms = onSnapshot(
        roomsQuery,
        (querySnapshot) => {
          const roomsArray = [];
          querySnapshot.forEach((doc) => {
            roomsArray.push({ ...doc.data(), id: doc.id });
          });
          setRooms(roomsArray);
          setStatus("success");
        },
        (error) => {
          console.error("Error loading rooms: ", error);
          setStatus("error");
        }
      );

      return unsubscribeRooms;
    };

    const fetchItemCategories = () => {
      setStatus("loading Item Categories...");
      const itemCategoriesRef = collection(
        firestore,
        "properties",
        selectedProperty,
        "itemCategories"
      );
      const itemCategoriesQuery = query(itemCategoriesRef);
      const unsubscribeItemCategories = onSnapshot(
        itemCategoriesQuery,
        (querySnapshot) => {
          const itemCategoriesArray = [];
          querySnapshot.forEach((doc) => {
            itemCategoriesArray.push(doc.id);
          });
          setItemCategories(itemCategoriesArray);
          setStatus("success");
        },
        (error) => {
          console.error("Error loading item categories: ", error);
          setStatus("error");
        }
      );

      return unsubscribeItemCategories;
    };

    const fetchItems = () => {
      setStatus("loading Items...");
      const itemsRef = collection(
        firestore,
        "properties",
        selectedProperty,
        "items"
      );
      const itemsQuery = query(itemsRef);
      const unsubscribeItems = onSnapshot(
        itemsQuery,
        (querySnapshot) => {
          const itemsArray = [];
          querySnapshot.forEach((doc) => {
            itemsArray.push(doc.data());
          });
          setItems(itemsArray);
          setStatus("success");
        },
        (error) => {
          console.error("Error loading items: ", error);
          setStatus("error");
        }
      );

      return unsubscribeItems;
    };
    const fetchPropertyInformation = () => {
      const propertyRef = doc(firestore, "properties", selectedProperty);
      const unsubscribeProperty = onSnapshot(
        propertyRef,
        (doc) => {
          setStatus("success");
          setPropertyInformation(doc.data());
        },
        (error) => {
          console.error("Error loading property information: ", error);
          setStatus("error");
        }
      );

      return unsubscribeProperty;
    };

    const unsubscribeRooms = fetchRooms();
    const unsubscribeItemCategories = fetchItemCategories();
    const unsubscribeItems = fetchItems();
    const unsubscribeProperty = fetchPropertyInformation();

    setStatus("complete");
    return () => {
      unsubscribeRooms();
      unsubscribeItemCategories();
      unsubscribeItems();
      unsubscribeProperty();
    };
  }, [selectedProperty]);

  return (
    <PropertyContext.Provider
      value={{
        selectedProperty,
        setSelectedProperty,
        status,
        propertyInformation,
        rooms,
        itemCategories,
        items,
      }}
    >
      {children}
    </PropertyContext.Provider>
  );
};

export default PropertyProvider;
