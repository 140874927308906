// src/Login.js
import React, { useState } from "react";
import { useAuth } from "./AuthContext";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import BetaPopup from "./components/BetaPopup";

export default function Login({ setIsLoggedIn, setUserID }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();

  const [openPopup, setOpenPopup] = useState(true);

  const handleClose = () => {
    setOpenPopup(false);
  };

  async function handleLogin(event) {
    event.preventDefault();
    try {
      //if email does not have @, then it is a username and apply logic
      if (!email.includes("@")) {
        const generateEmail = `gkgleason320+${email}@gmail.com`;
        const generatePassword = `${password}GG2023!`;
        await login(generateEmail, generatePassword).then((res) => {
          setUserID(res.user.uid);
          setIsLoggedIn(true);
        });
      } else {
        await login(email, password).then((res) => {
          setUserID(res.user.uid);
          setIsLoggedIn(true);
        });
        console.log("logged in");
      }
      // Redirect to the dashboard or home page after login
    } catch (e) {
      console.log(e);
      // Handle errors like showing an alert to the user
    }
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 8 }}>
      <Typography variant="h4" sx={{ mb: 4, textAlign: "center" }}>
        Login
      </Typography>
      <form onSubmit={handleLogin}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <TextField
            label="Username or Email"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            required
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Login
          </Button>
        </Box>
      </form>
      <BetaPopup open={openPopup} handleClose={handleClose} />
    </Container>
  );
}
