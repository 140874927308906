import React, { useContext } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { collection, getDocs, query } from "firebase/firestore";
import { firestore } from "../firebase";
import PropertyContext from "../contexts/PropertyContext";

const PDFReport = () => {
  const { selectedProperty } = useContext(PropertyContext);

  const createPdfDocument = async () => {
    const doc = new jsPDF();

    // Sample data for the table
    const tableColumnHeaders = ["MOD Signoff", "Time Completed", "Task"];
    // const tableRows = [
    //   ["Row 1 Col 1", "Row 1 Col 2", "Row 1 Col 3"],
    //   ["Row 2 Col 1", "check building and proeprty to check for any maint and trash issues that need immediate attention", "Row 2 Col 3"],
    //   // ... more rows
    // ];
    // const tableRows = []

    //const today = new Date(Date.now()).setUTCHours(0,0,0,0)
    const getLocalDateString = () => {
      const today = new Date();
      const localDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      return localDate.toISOString().split("T")[0];
    };

    const todaysTasksRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "modTasks",
      "dailyReports",
      getLocalDateString()
    );

    const queryTodaysTasks = query(todaysTasksRef);

    const todaysTasksSnapshot = await getDocs(queryTodaysTasks);

    // const tableRows = todaysTasksSnapshot.docs.map((doc) => {
    //     const task = doc.data();
    //     count++
    //     return [
    //       task.markedCompletedBy,
    //       task.markedCompletedTime,
    //       task.taskDetails,
    //       count
    //     ];
    //   })
    const tableRows = todaysTasksSnapshot.docs
      .map((doc) => {
        const task = doc.data();
        return {
          completedTime: convertTo24HourFormat(task.markedCompletedTime),
          rowData: [
            task.markedCompletedBy,
            task.markedCompletedTime,
            task.taskDetails,
          ],
        };
      })
      .sort((a, b) => {
        // Comparing the 24-hour format times for sorting in ascending order
        if (a.completedTime < b.completedTime) return -1;
        if (a.completedTime > b.completedTime) return 1;
        return 0;
      })
      .map((item) => item.rowData);

    // Function to convert 12-hour format time to 24-hour format
    function convertTo24HourFormat(time12h) {
      const [time, modifier] = time12h.split(" ");
      let [hours, minutes] = time.split(":");
      if (hours === "12") {
        hours = "00";
      }
      if (modifier === "PM") {
        hours = parseInt(hours, 10) + 12;
      }
      return `${hours}:${minutes}`;
    }

    // Adding a title to your document
    doc.setFontSize(16);

    doc.text(
      `Manager Checklist for: ${
        new Date(Date.now()).toLocaleString().split(",")[0]
      }`,
      10,
      10
    );

    // Adding a table
    const table = doc.autoTable({
      head: [tableColumnHeaders],
      body: tableRows,
      startY: 20,
      theme: "grid", //'grid'
    });
    // const table2 = doc.autoTable({
    //     head: [[{ content: [tableColumnHeaders], styles: { valign: 'middle', halign: 'center' }}]],
    //     body: tableRows,
    //     startY: doc.lastAutoTable.finalY + 12,
    //     theme: 'grid',
    //   });

    // if (typeof table.finalY === 'number') {
    doc.setFontSize(12);
    const signatureYPosition = doc.lastAutoTable.finalY + 12; // 10 units below the table
    doc.text("Signature:  ____________________", 10, signatureYPosition);
    //   } else {
    //     console.error('Error: finalY is not defined.');
    //   }
    //   doc.text(table.finalY, 20,20)

    // Save the PDF
    //doc.save("example_with_table.pdf");
    doc.output("dataurlnewwindow");
  };

  return (
    <div>
      <button onClick={createPdfDocument}>Generate PDF</button>
    </div>
  );
};

export default PDFReport;
