import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { CssBaseline, Box, CircularProgress } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AuthProvider, useAuth } from "./AuthContext";
import { firestore } from "./firebase";
import { collection, doc, getDoc, onSnapshot, query } from "firebase/firestore";

import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Inbox from "./components/Inbox";
import AddUser from "./components/addUser";
import Login from "./Login";
import WorkOrders from "./components/WorkOrders";
import Dispatch from "./components/Dispatch";
import PdfGeneratorPage from "./components/PdfGeneratorTest";
import Profile from "./components/Profile";
import CreateProperty from "./components/CreateProperty";
import Properties from "./components/Properties";
import Dashboard from "./components/Dashboard";
import Vendors from "./components/Vendors";
import PropertyProblems from "./components/PropertyProblems";
import MODTasks from "./components/MODTasks";
import PDFReport from "./components/PDFReport";
import CallList from "./components/CallList";
import Reports from "./components/Reports";
import TestComponent from "./components/TestComponent";
import Rooms2 from "./components/Rooms2";
import RoomsSingle from "./components/RoomsSingle";
import RoomsSingleTimeline from "./components/RoomsSingleTimeline";
import AddItems from "./components/AddItems";
import PurchaseOrdersPage from "./components/PurchaseOrdersPage";
import PropertyProvider from "./providers/PropertyProvider";
import PropertyContext from "./contexts/PropertyContext";
import SelectProperty from "./components/SelectProperty";
import PublicWorkOrders from "./components/PublicWorkOrders";
import EmployeeSchedule from "./components/EmployeeSchedule";
import DispatchAssistant from "./components/DispatchAssistant";
import PublicPropertyReviews from "./components/PublicPropertyReviews";

const App = () => {
  // ... theme handling and other states
  const [themeMode, setThemeMode] = useState("dark");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState();
  const [userID, setUserID] = useState(null);
  // // const [selectedProperty, setSelectedProperty] = useContext(PropertyContext);
  // const [propertyMessages, setPropertyMessages] = useState([]);
  // const [propertyStaff, setPropertyStaff] = useState([]);

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
        },
      }),
    [themeMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        <Navbar isLoggedIn={isLoggedIn} />
        <Box display="flex">
          {isLoggedIn ? (
            <React.Fragment>
              <PropertyProvider>
                {" "}
                {/* Place PropertyProvider here */}
                <PropertyContent user={userID} /> {/* New component */}
              </PropertyProvider>
            </React.Fragment>
          ) : (
            //PUBLIC Router

            <Box component="main" sx={{ flexGrow: 1, p: 3, paddingTop: 9 }}>
              {/* router for public facing routes */}
              <Routes>
                <Route
                  path="/"
                  element={
                    <Login
                      setIsLoggedIn={setIsLoggedIn}
                      setUserID={setUserID}
                    />
                  }
                />
                <Route
                  path="/login"
                  element={
                    <Login
                      setIsLoggedIn={setIsLoggedIn}
                      setUserID={setUserID}
                    />
                  }
                />
                <Route path="/workorder" element={<PublicWorkOrders />} />
                <Route path="/reviews" element={<PublicPropertyReviews />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
              {/* <Login setIsLoggedIn={setIsLoggedIn} setUserID={setUserID} /> */}
            </Box>
          )}
        </Box>
      </Router>
    </ThemeProvider>
  );
};

const PropertyContent = ({ user }) => {
  console.log(user);
  const { selectedProperty, setSelectedProperty } = useContext(PropertyContext);
  // const [user, setUser] = useState();
  const [userID, setUserID] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [propertyMessages, setPropertyMessages] = useState([]);
  const [propertyStaff, setPropertyStaff] = useState([]);
  const [loading, setLoading] = useState(true);
  const [properties, setProperties] = useState([]);

  console.log("GOT HERE");
  //user info
  useEffect(() => {
    const getUser = async () => {
      const userProfileRef = doc(firestore, "users", user);
      const userDoc = await getDoc(userProfileRef);

      if (userDoc.exists()) {
        const userDocData = userDoc.data();

        userDocData.id = user;

        //if userDocData.properties exists then check for length and if only one then set selected property to that property
        if (userDocData.properties) {
          if (userDocData.properties.length === 1) {
            setSelectedProperty(userDocData.properties[0].id);
            //add property info to user data
            const currentPropertyRef = doc(
              firestore,
              "properties",
              userDocData.properties[0].id
            );
            const currentPropertyDoc = await getDoc(currentPropertyRef);
            if (currentPropertyDoc.exists()) {
              userDocData.activeProperty = currentPropertyDoc.data();
            } else {
              console.error("COULD NOT FIND PROPERTY");
            }
          } else {
            setProperties(userDocData.properties);
          }
        }
        setUserInfo(userDocData);
        setLoading(false);
      } else {
        //should force a page to load
      }
    };
    getUser();
  }, []);

  // //get property information is this a good idea?
  useEffect(() => {
    if (selectedProperty !== null) {
      console.log("GOT HEREEEEEE");
      console.log(selectedProperty);
      //get rooms

      //get staff
      const staffRef = collection(
        firestore,
        "properties",
        selectedProperty,
        "Staff"
      );
      const staffQuerry = query(staffRef);

      const unsubscribeStaff = onSnapshot(staffQuerry, (snapshot) => {
        const staffArray = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPropertyStaff(staffArray);
      });

      //get messages
      const messagesRef = collection(
        firestore,
        "properties",
        selectedProperty,
        "messages"
      );
      const messagesQuerry = query(messagesRef);

      const unsubscribeMessages = onSnapshot(messagesQuerry, (snapshot) => {
        const messagesArray = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((message) => message.isRead === false);
        setPropertyMessages(messagesArray);
      });

      return () => {
        unsubscribeMessages();
        unsubscribeStaff();
      };
    }

    console.log(selectedProperty);
  }, [selectedProperty]);

  return (
    <React.Fragment>
      {loading ? (
        <React.Fragment>
          <CircularProgress />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {selectedProperty ? (
            <React.Fragment>
              <Sidebar user={userInfo} messages={propertyMessages} />
              <Box component="main" sx={{ flexGrow: 1, p: 3, paddingTop: 9 }}>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Dashboard
                        property={selectedProperty}
                        userInfo={userInfo}
                      />
                    }
                  />
                  {/* <Route path="/login" element={<Login />} /> */}
                  <Route path="/PDFTest" element={<PDFReport />} />
                  <Route
                    path="/inbox"
                    element={
                      <Inbox
                        messages={propertyMessages}
                        staff={propertyStaff}
                        property={selectedProperty}
                      />
                    }
                  />
                  <Route path="/rooms" element={<Rooms2 user={userInfo} />} />
                  <Route path="/rooms/:displayName" element={<RoomsSingle />} />
                  <Route
                    path="/rooms/:displayName/timeline"
                    element={<Rooms2 />}
                  />
                  <Route path="/workOrders" element={<WorkOrders />} />
                  <Route path="/dispatch" element={<Dispatch />} />
                  <Route path="/generate-pdf" element={<PdfGeneratorPage />} />
                  <Route
                    path="/reports"
                    element={<Reports user={userInfo} />}
                  />
                  <Route
                    path="/callList"
                    element={<CallList userInfo={userInfo} />}
                  />
                  <Route path="/addUser" element={<AddUser />} />
                  <Route
                    path="/orders"
                    element={<PurchaseOrdersPage userInfo={userInfo} />}
                  />
                  <Route
                    path="/vendors"
                    element={<Vendors property={selectedProperty} />}
                  />
                  <Route
                    path="/propertyIssues"
                    element={<PropertyProblems />}
                  />
                  <Route path="/mod-tasks" element={<MODTasks />} />
                  <Route path="/addItem" element={<AddItems />} />
                  <Route
                    path="/properties"
                    element={<Properties userInfo={userInfo} />}
                  />
                  <Route
                    path="/create-property"
                    element={<CreateProperty userInfo={userInfo} />}
                  />
                  <Route
                    path="/Profile"
                    element={<Profile userInfo={userInfo} userId={user} />}
                  />
                  <Route
                    path="/EmployeeSchedule"
                    element={<EmployeeSchedule />}
                  />
                  <Route
                    path="/dispatchAssistant"
                    element={<DispatchAssistant />}
                  />

                  <Route path="/test" element={<TestComponent />} />
                  <Route path="*" element={<Navigate to="/" />} />
                  {/* <Route path="*" element={<Navigate to="/login" />} /> */}
                </Routes>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {/* add page to have user select the active property on login if multiple properties are on account */}
              <Box component="main" sx={{ flexGrow: 1, p: 3, paddingTop: 9 }}>
                <SelectProperty properties={properties} />
              </Box>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default App;
