import React, { useContext, useRef, useState } from "react";
import { firestore, storage } from "./../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import PropertyContext from "../contexts/PropertyContext";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import "primereact/resources/themes/lara-dark-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

export default function ImageUpload({ room }) {
  const toast = useRef(null);
  const { selectedProperty } = useContext(PropertyContext);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="flex align-items-center gap-3 ml-auto">
          {chooseButton}
          {uploadButton}
          {cancelButton}
          {/* <span>{formatedValue} / 1 MB</span>
          <ProgressBar
            value={value}
            showValue={false}
            style={{ width: "10rem", height: "12px" }}
          ></ProgressBar> */}
        </div>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}
          className="my-5"
        >
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-images",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };
  const uploadOptions = {
    icon: "pi pi-fw pi-cloud-upload",
    iconOnly: true,
    className:
      "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  const generateUniqueFileName = (originalFileName) => {
    const uniqueId = Date.now();
    const fileExtension = originalFileName.split(".").pop();
    const date = new Date().toISOString().split("T")[0];
    const newFileName = `${date}-${uniqueId}.${fileExtension}`;
    return newFileName;
  };

  const removeImage = (fileName) => {
    const image = document.querySelector(`img[src="${fileName}"]`);
    if (image) {
      const parentElement = image.closest(".p-fileupload-row");
      if (parentElement) {
        const deleteButton = parentElement.querySelector(".p-button-danger");
        if (deleteButton) {
          deleteButton.click();
        }
      }
    }
  };

  const customUploader = async (event, props) => {
    const images = event.files;
    for (let index = 0; index < images.length; index++) {
      const image = images[index];
      const imageRef = ref(
        storage,
        `room-images/${room.displayName}/${generateUniqueFileName(image.name)}`
      );
      await uploadBytes(imageRef, image)
        .then(() => {
          getDownloadURL(imageRef).then((url) => {
            addDoc(
              collection(
                firestore,
                "properties",
                selectedProperty,
                "units",
                room.displayName,
                "images"
              ),
              {
                name: image.name,
                url: url,
                uploadedAt: serverTimestamp(),
              }
            )
              .then(() => {
                toast.current.show({
                  severity: "info",
                  summary: "Success",
                  detail: "File Uploaded.",
                });
                removeImage(image.objectURL);
              })
              .catch((error) => {
                toast.current.show({
                  severity: "error",
                  summary: "Something went wrong!",
                  detail: "Please try again later.",
                });
              });
          });
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  };

  return (
    <div>
      <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
      <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
      <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

      <FileUpload
        style={{ marginTop: "50px" }}
        ref={fileUploadRef}
        multiple
        accept="image/*"
        // maxFileSize={1000000}
        onUpload={onTemplateUpload}
        onSelect={onTemplateSelect}
        onError={onTemplateClear}
        onClear={onTemplateClear}
        headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
        chooseOptions={chooseOptions}
        uploadOptions={uploadOptions}
        cancelOptions={cancelOptions}
        customUpload
        uploadHandler={customUploader}
      />
      <Toast ref={toast} position="bottom-right" />
    </div>
  );
}
