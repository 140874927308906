import React, { useContext } from "react";
import PropertyContext from "../contexts/PropertyContext";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";

function SelectProperty({ properties }) {
  const { selectProperty, setSelectedProperty } = useContext(PropertyContext);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: "20px",
          maxWidth: 400,
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Choose a Property
        </Typography>
        <FormControl fullWidth margin="normal">
          <InputLabel id="select-property-label">
            Please Select A Property
          </InputLabel>
          <Select
            labelId="select-property-label"
            value={selectProperty}
            label="Please Select A Property"
            onChange={(e) => setSelectedProperty(e.target.value)}
          >
            {properties.map((property, index) => (
              <MenuItem key={index} value={property.id}>
                {property.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
    </Box>
  );
}

export default SelectProperty;
