import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, TextField, Button, Grid } from "@mui/material";

import { doc, setDoc, writeBatch } from "firebase/firestore";
import { firestore } from "../firebase";

const CreateProperty = () => {
  const [propertyName, setPropertyName] = useState(null);
  const [propertyID, setPropertyID] = useState(null);
  const [propertyAddress, setPropertyAddress] = useState(null);
  const [propertyLLC, setPropertyLLC] = useState(null);
  const [propertySalesTax, setPropertySalesTax] = useState(null);
  const [roomSets, setRoomSets] = useState([
    { prefix: "", startUnit: "", endUnit: "" },
  ]);

  useEffect(() => {}, []);

  const createProperty = () => {
    // Validation checks
    if (!propertyName) return alert("Please Set A Property Name");
    if (!propertyID) return alert("Please Set a Property ID");
    if (!propertyAddress) return alert("Please Set a Property Address");
    if (!propertyLLC) return alert(`Please Set Property's LLC`);
    if (!propertySalesTax) return alert("Please Set the Sales Tax Rate");

    //create property with ID under /properties/${id}
    const newPropertyRef = doc(firestore, "properties", propertyID);
    const newPropertyData = {
      displayName: propertyName,
      id: propertyID,
      propertyAddress: propertyAddress,
      propertyLLC: propertyLLC,
      propertySalesTax: propertySalesTax,
    };

    setDoc(newPropertyRef, newPropertyData);

    let roomsArray = [];
    //loop through rooms and create rooms
    roomSets.forEach((roomSet) => {
      // Convert startUnit and endUnit to numbers
      let currentRoom = Number(roomSet.startUnit);
      const endUnit = Number(roomSet.endUnit);

      while (endUnit >= currentRoom) {
        // Construct displayName and ensure it's a string
        const displayName = roomSet.prefix
          ? `${roomSet.prefix}-${currentRoom}`
          : currentRoom.toString();

        roomsArray.push({
          displayName: displayName,
          Building: roomSet.prefix || "1",
          roomId: currentRoom,
        });

        currentRoom += 1;
      }
    });

    const batch = writeBatch(firestore); //create batch write for all units

    roomsArray.forEach((room) => {
      const roomRef = doc(
        firestore,
        "properties",
        propertyID,
        "units",
        room.displayName
      );
      const roomData = {
        roomId: room.roomId,
        Building: room.Building ? room.Building : 1,
        displayName: room.displayName,
        status: "Down",
        selectedHousekeepingRequirements: "",
        selectedMaintenanceRequirements: "",
      };
      batch.set(roomRef, roomData); //set data for batch write
    });
    batch
      .commit()
      .then(() => {
        console.log("Rooms created successfully");
        // Additional success handling, e.g., redirection or state reset
        alert(`${propertyName} successfully created`);
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        // Error handling logic
        alert("looks like there was an error, please check log");
      });
  };
  const addNewRoomSet = () => {
    setRoomSets([...roomSets, { prefix: "", startUnit: "", endUnit: "" }]);
  };
  const handleRoomSetChange = (index, field, value) => {
    const newRoomSets = [...roomSets];
    newRoomSets[index][field] = value;
    setRoomSets(newRoomSets);
  };
  const calculateTotalRooms = (roomSets) => {
    return roomSets.reduce((total, roomSet) => {
      const startUnit = parseInt(roomSet.startUnit, 10);
      const endUnit = parseInt(roomSet.endUnit, 10);
      // Ensure startUnit and endUnit are numbers and startUnit is less than endUnit
      if (!isNaN(startUnit) && !isNaN(endUnit) && startUnit <= endUnit) {
        return total + (endUnit - startUnit + 1);
      }
      return total;
    }, 0);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "85vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        bgcolor: "background.paper",
        p: 2,
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Create A New Property
      </Typography>

      <Grid
        container
        spacing={2}
        sx={{ width: "80%", maxWidth: 500, marginBottom: 2 }}
      >
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Property ID"
            variant="outlined"
            onChange={(e) => setPropertyID(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Property Display Name"
            variant="outlined"
            onChange={(e) => setPropertyName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Property LLC"
            variant="outlined"
            onChange={(e) => setPropertyLLC(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Property Sales Tax Rate"
            variant="outlined"
            onChange={(e) => setPropertySalesTax(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Property Address"
            variant="outlined"
            onChange={(e) => setPropertyAddress(e.target.value)}
          />
        </Grid>
        {/* Room Sets Inputs */}
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ marginBottom: 1, marginTop: 2 }}>
            Create Rooms ( {calculateTotalRooms(roomSets)} Rooms )
          </Typography>
        </Grid>

        {roomSets.map((roomSet, index) => (
          <React.Fragment key={index}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Prefix / Building"
                value={roomSet.prefix}
                variant="outlined"
                onChange={(e) =>
                  handleRoomSetChange(index, "prefix", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Start Unit"
                value={roomSet.startUnit}
                variant="outlined"
                onChange={(e) =>
                  handleRoomSetChange(index, "startUnit", e.target.value)
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="End Unit"
                value={roomSet.endUnit}
                variant="outlined"
                onChange={(e) =>
                  handleRoomSetChange(index, "endUnit", e.target.value)
                }
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>

      <Button
        variant="contained"
        color="primary"
        onClick={addNewRoomSet}
        sx={{ marginRight: 1 }}
      >
        Add New Room Set
      </Button>
      <Box
        sx={{
          mt: "2rem",
          width: "80%",
          maxWidth: 500,
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <Button variant="contained" color="secondary" onClick={createProperty}>
          Create Property
        </Button>
      </Box>
    </Box>
  );
};

export default CreateProperty;
