import React, { useState, useEffect, useContext } from "react";
import {
  collection,
  onSnapshot,
  query,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { auth, firestore } from "../firebase";
import {
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Modal,
  Divider,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import PropertyContext from "../contexts/PropertyContext";

// const WOModal = ({ open, onClose, vendors }) =>{
//     const [selectedVendor, setSelectedVendor] = useState(0)
//     const [desc, setDesc] = useState()
//     const [selectedStatus, setSelectedStatus] = useState()
// const { selectedProperty } = useContext(PropertyContext);

//     const propertyIssuesRef = collection(firestore, 'properties', selectedProperty, 'propertyIssues')

//     const handleClose = async () => {
//         if (selectedVendor === 0 ) {
//             //should give toaster notification or something to verify but for now we will just close
//             onClose()
//         } else {
//             const issueData = {
//                 completed: false,
//                 vendor: selectedVendor,
//                 status: selectedStatus,
//                 issueDetails: desc,
//             }
//             await addDoc(propertyIssuesRef, issueData)

//             onClose()
//         }
//     }

//     return (
//         <Modal open={open} onClose={onClose}>
//             <Box sx={modalStyle}>
//                 <Box width="100%">
//                     <Box display="flex" sx={{p: 0.5}} justifyContent='center'>
//                         <Typography>New Task</Typography>
//                         <Divider/>
//                     </Box>
//                     <Box display="flex" flexDirection="row" width="100%" justifyContent="space-around" padding='5px'>
//                         <Box sx={{p: 0.5}}>
//                             <InputLabel id='vendor-select-label'>Select Vendor</InputLabel>
//                             <Select
//                                 labelId="vendor-select-label"
//                                 id="vendor-select"
//                                 label="Select Vendor"
//                                 value={selectedVendor}
//                                 onChange={(event) => {
//                                     setSelectedVendor(event.target.value)
//                                 }}
//                             >
//                                 {vendors.map((option) => (
//                                     <MenuItem key={option.id} value={option.name}>
//                                     {option.name}
//                                     </MenuItem>
//                                 ))}
//                                 <MenuItem key={'unknown'} value={'unknown'}>
//                                     unknown
//                                 </MenuItem>
//                                 <MenuItem key={'TBD'} value={'TBD'}>
//                                     TBD
//                                 </MenuItem>
//                             </Select>
//                         </Box>
//                         <Box sx={{p: 0.5}}>
//                             <InputLabel id='status-select-label'>Select Status</InputLabel>

//                             <Select
//                                 labelId="status-select-label"
//                                 id="status-select"
//                                 label="Select Status"
//                                 value={selectedStatus}
//                                 onChange={(event) => {
//                                     setSelectedStatus(event.target.value)
//                                 }}
//                             >
//                                 {['not reported','reported', 'pending estimate', 'pending approval', 'pending vendor arrival', 'work in progress', 'completed'].map((option) => (
//                                     <MenuItem key={option} value={option}>
//                                     {option}
//                                     </MenuItem>
//                                 ))}

//                             </Select>
//                         </Box>

//                     </Box>
//                 </Box>
//                 <Box sx={{ p: 0.5}}>
//                     <TextField
//                         id="outlined-multiline-static"
//                         label="Description"
//                         fullWidth
//                         multiline
//                         rows={4}
//                         defaultValue=""
//                         onChange={(event) => {
//                             setDesc(event.target.value)
//                         }}
//                         />
//                 </Box>
//                 <Box display="flex" justifyContent="space-between">
//                     <Box>
//                         <Button onClick={() => onClose()} sx={{mt: 2}}>Cancel</Button>
//                     </Box>
//                     <Box>
//                         <Button onClick={handleClose} sx={{ mt: 2 }}>Generate Work Order</Button>
//                     </Box>
//                 </Box>
//             </Box>

//         </Modal>
//     )
// }
const WOModal = ({ open, onClose }) => {
  const [selection, setSelection] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [taskDetails, setTaskDetails] = useState();
  const [taskTimeFrame, setTaskTimeFrame] = useState();
  const { selectedProperty } = useContext(PropertyContext);

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedDays([...selectedDays, event.target.name]);
    } else {
      setSelectedDays(selectedDays.filter((day) => day !== event.target.name));
    }
  };

  const handleChange = (event) => {
    setSelection(event.target.value);
  };
  const handleTimeframeChange = (event) => {
    setTaskTimeFrame(event.target.value);
  };

  const handleAddVendor = async () => {
    const testInfo = {
      taskDetails: taskDetails,
      type: selection,
      days: selectedDays,
      time: taskTimeFrame,
    };
    //console.log(testInfo)
    const tasksRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "modTasks",
      "base",
      "allTasks"
    );
    await addDoc(tasksRef, testInfo);

    //clean up and close
    setSelectedDays([]);
    setTaskDetails(null);
    setSelection("");
    onClose();
  };
  const handleMonthlySelection = (event) => {
    if (event.target.value.includes(",")) {
      const daysArray = event.target.value.split(",").map((day) => day.trim());

      setSelectedDays(daysArray);
    } else {
      // Handle the case for a single day or empty input
      setSelectedDays(event.target.value ? [event.target.value.trim()] : []);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        {/* Modal Content */}
        <Box
          sx={{
            mb: "2rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6">Add a New Task</Typography>
        </Box>

        {/* Rest of the modal content goes here */}
        {/* For example, form fields to add a New Task */}

        <TextField
          id="outlined-basic"
          label="Task"
          variant="outlined"
          fullWidth
          onChange={(val) => setTaskDetails(val.target.value)}
          sx={{ mb: "2rem" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ float: "right" }}>
            <FormControl sx={{ mb: "2rem" }}>
              <InputLabel id="task-schedule-label">Task Schedule</InputLabel>
              <Select
                labelId="task-schedule-label"
                value={selection}
                label="Task Schedule"
                onChange={handleChange}
                sx={{ minWidth: "12rem", width: "100%" }}
              >
                {["daily", "weekly", "monthly"].map((option) => (
                  // <MenuItem key={option} onClick={() => setSelection(option)}>
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ float: "right" }}>
            <FormControl sx={{ mb: "2rem" }}>
              <InputLabel id="task-timefram-label">Task Timeframe</InputLabel>
              <Select
                labelId="task-timefram-label"
                value={taskTimeFrame}
                label="Task Timeframe"
                onChange={handleTimeframeChange}
                sx={{ minWidth: "12rem", width: "100%" }}
              >
                {[
                  "first thing",
                  "morning",
                  "noon",
                  "afternoon",
                  "evening",
                  "EOD",
                ].map((option) => (
                  // <MenuItem key={option} onClick={() => setSelection(option)}>
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box>
          {selection == "weekly" && (
            <Box>
              {[
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ].map((day) => (
                <FormControlLabel
                  key={day}
                  control={
                    <Checkbox name={day} onChange={handleCheckboxChange} />
                  }
                  label={day}
                />
              ))}
            </Box>
          )}
          {selection === "monthly" && (
            <React.Fragment>
              {/* <TextField id="outlined-basic" label="Dates of service (seperated by comma)" variant="outlined" onChange={(val) => setSelectedDays(val.target.value)}/> */}
              <TextField
                id="outlined-basic"
                label="Dates of service (seperated by comma)"
                variant="outlined"
                onChange={handleMonthlySelection}
              />
            </React.Fragment>
          )}
        </Box>

        {/* Action Buttons */}
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleAddVendor}>
            Add Task
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const MODTasks = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentTasks, setCurrentTasks] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const { selectedProperty } = useContext(PropertyContext);

  useEffect(() => {
    setIsLoading(true);
    const today = new Date(Date.now()).setUTCHours(0, 0, 0, 0);

    const tasksRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "modTasks",
      "base",
      "allTasks"
    );
    const queryTasks = query(tasksRef);

    const unsub = onSnapshot(queryTasks, (snap) => {
      const currentTasks = snap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCurrentTasks(currentTasks);
      setIsLoading(false);
    });
    //if collection exists where the collection id === today then create array of documents in collection
    //else create collection and add tasks that follow logic to documents from collection(firestore, 'properties', selectedProperty, 'modTasks', 'dailyReports', 'base', allTasks')

    // const unsub = onSnapshot(queryIssues, (snap)=>{
    //     const currentTasks = snap.docs.map(doc =>({
    //         id: doc.id,
    //         ...doc.data()
    //     }))
    //     .filter(request => request.completed === false)

    //     setCurrentTasks(currentTasks)
    //         setIsLoading(false)

    // })
  }, []);

  const markAsCompleted = async (issueId) => {
    const orderRef = doc(
      firestore,
      "properties",
      selectedProperty,
      "propertyIssues",
      issueId
    );
    //close out order
    const updatedWODetails = {
      completed: true,
    };
    await updateDoc(orderRef, updatedWODetails);
  };

  const handleModalOpen = () => setModalOpen(!modalOpen);

  // const issueData = {
  //     completed: false,
  //     vendor: selectedVendor,
  //     status: selectedStatus,
  //     issueDetails: desc,
  // }

  const columns = [
    { field: "type", headerName: "Type", width: 100, editable: false },
    {
      field: "time",
      headerName: "Time frame to complete",
      width: 250,
      editable: false,
    },
    {
      field: "taskDetails",
      headerName: "Details",
      width: 250,
      editable: false,
      flex: 1,
    },
    { field: "days", headerName: "Days To Do", width: 250, editable: true },

    // { field: 'completed', headerName: 'Mark Complete', width: 130, editable: true, renderCell: (params) => (
    //     <Button
    //     onClick={()=>markAsCompleted(params.id, params.row.markedCompleteBy)}>Approve</Button>
    //   ),},
  ];

  return (
    <Box p={2}>
      {isLoading ? (
        <React.Fragment>
          <Typography>Im Loading!</Typography>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box display="flex" width="100%" justifyContent="flex-end">
            <Box>
              <Button variant="contained" onClick={handleModalOpen}>
                New Task
              </Button>
            </Box>
          </Box>
          <Box>
            <WOModal open={modalOpen} onClose={handleModalOpen} />
          </Box>
          {currentTasks.length > 0 && (
            <React.Fragment>
              <Typography>All tasks for property</Typography>
              <DataGrid
                rows={currentTasks}
                // getRowId={(row) => row.roomId}
                columns={columns}
                // onCellEditCommit={}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default MODTasks;
