import React, { useEffect, useRef, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { Avatar, Box, Button, Chip, Input, Paper, Select, Switch, ToggleButton, Typography } from '@mui/material';
import MarkAsReadIcon from '@mui/icons-material/Done'; // Import the icon you want for the "Mark as Read" button
import { collection, doc, onSnapshot, query, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { MuiTelInput } from 'mui-tel-input'
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';






const Properties = ({userInfo}) => {

  useEffect(() => {
    //get list of all properties
  }, []);


  return (
    <Box sx={{
        width: '100%',
        height: '85vh', // Full height
        display: 'flex',
        flexDirection: 'column',
        //justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'background.paper',
        p: 2
      }}>
        {

        }
    </Box>
  );
};

export default Properties;
