import {
  Button,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import {
  collection,
  query,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { format, parseISO } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { firestore } from "../firebase";
import PropertyContext from "../contexts/PropertyContext";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

function RoomsSingleTimeline({ singleRoom, setSingleRoomTimeline }) {
  const [loading, setLoading] = useState(true);
  const [groupedImages, setGroupedImages] = useState({});
  const { selectedProperty } = useContext(PropertyContext);

  const fetchLatestImages = async () => {
    // Reference to your collection
    const imagesRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "units",
      singleRoom.displayName,
      "images"
    );

    // Create a query against the collection, ordering by upload timestamp descending
    const q = query(imagesRef, orderBy("uploadedAt", "desc"));

    try {
      const querySnapshot = await getDocs(q);
      const images = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        // Convert Firestore Timestamp to JavaScript Date object for easier handling
        uploadedAt: doc.data().uploadedAt.toDate(),
      }));

      // Assuming you have a state to hold these images
      const groupedImages = images.reduce((groups, image) => {
        const date = format(image.uploadedAt, "yyyy-MM-dd");
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(image);
        return groups;
      }, {});

      // Set the grouped images in state
      setGroupedImages(groupedImages);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching images: ", error);
      // Handle any errors, such as showing a message to the user
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLatestImages();
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div>
      <Card sx={{ minWidth: 275 }}>
        <CardHeader
          style={{ background: "#282828" }}
          title={singleRoom.displayName}
          action={
            <Button
              variant="contained"
              onClick={() => setSingleRoomTimeline(false)}
            >
              Back
            </Button>
          }
        />
        <CardContent>
          <div>
            {Object.keys(groupedImages)
              .sort()
              .reverse()
              .map((date) => (
                <div key={date}>
                  <h2>
                    {format(parseISO(date), "MMMM dd, yyyy")} (
                    {groupedImages[date].length})
                  </h2>
                  {/* Display the date in a human-readable format and the count of images */}
                  <ImageList cols={5}>
                    {groupedImages[date].map((item) => (
                      <ImageListItem key={item.id}>
                        <img
                          srcSet={`${item.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                          src={`${item.url}?w=164&h=164&fit=crop&auto=format`}
                          alt={item.name}
                          loading="lazy"
                        />
                        <ImageListItemBar
                          title={item.name}
                          actionIcon={
                            <IconButton
                              sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                              aria-label={`info about ${item.name}`}
                              // onClick={() => }
                              target="_blank"
                              href={item.url}
                              download={true}
                            >
                              <DownloadForOfflineIcon />
                            </IconButton>
                          }
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                  <div></div>
                </div>
              ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default RoomsSingleTimeline;
